/* eslint-disable no-unused-vars */
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import Ajv from "ajv";
import { HttpSchemaValidationError } from "./errors";
import delay from "../common/utils/delay";

const ajv = new Ajv();
const cache = setupCache({
  maxAge: 0,
});

const api = axios.create({
  adapter: cache.adapter,
});

class HttpMethod {
  constructor() {
    this.baseURL = "";

    this.store = () => ({
      state: {},
      dispatch: (action) => {},
    });
    this.method = "GET";
    this.url = "";
    this.headers = {};
    this.cacheTTL = 0;
    this.options = {};
    this.data = null;
    this.params = null;
    this.requestTransformer = null;
    this.responseTransformer = null;
    this.fullResponse = null;
    this.beforeHook = (options, store) => {
      return options;
    };
    this.schema = null;
    this.mock = null;
    this.onClientError = () => null;
  }

  withOptions(options) {
    this.options = options;
    return this;
  }

  withURL(url) {
    this.url = url;
    return this;
  }

  withStore(store) {
    this.store = store;
    return this;
  }

  withBaseURL(baseUrl) {
    this.baseURL = baseUrl;
    return this;
  }

  withMethod(method) {
    this.method = method;
    return this;
  }

  withMock(callback) {
    this.mock = callback;
    return this;
  }

  withHeaders(headers) {
    this.headers = headers;
    return this;
  }

  withData(data) {
    this.data = data;
    return this;
  }

  withRequestTransformer(requestTransformer) {
    this.requestTransformer = requestTransformer;
    return this;
  }

  withResponseTransformer(responseTransformer) {
    this.responseTransformer = responseTransformer;
    return this;
  }

  withParams(params) {
    this.params = params;
    return this;
  }

  withSchemaValidation(schema) {
    this.schema = schema;
    return this;
  }

  withCache(cacheTTL) {
    this.cacheTTL = cacheTTL;
    return this;
  }

  withFullResponse(fullResponse) {
    this.fullResponse = fullResponse;
    return this;
  }

  _getRequestOptions() {
    const options = {
      url: this.url,
      method: this.method,
      baseURL: this.baseURL,
      headers: this.headers,
      params: this.params,
      requestTransformer: this.requestTransformer,
      responseTransformer: this.responseTransformer,
      data: this.data,
      ...this.options,
    };
    if (this.cacheTTL !== 0) {
      options.cache = {
        maxAge: this.cacheTTL,
      };
    }
    return this.beforeHook(options, this.store);
  }

  withBeforeHook(hook) {
    this.beforeHook = hook;
    return this;
  }

  withClientErrorHandler(callback) {
    this.onClientError = callback;
    return this;
  }

  _validateSchema(data) {
    if (this.schema) {
      const valid = ajv.validate(this.schema, data);
      if (!valid) {
        throw new HttpSchemaValidationError(ajv.errors);
      }
    }
  }

  async send() {
    if (typeof this.mock === "function") {
      // Call mocked data
      await delay(Math.floor((Math.random() + Math.random()) * 1000)); // Random number between 0 - 2 seconds!
      return this.mock();
    }

    // const start = Date.now();
    let response;
    try {
      response = await api(this._getRequestOptions());
      const { data } = response;
      this._validateSchema(data);
      if (this.fullResponse) return response;

      if (this.responseTransformer) {
        return this.responseTransformer(data);
      }

      return data;
    } catch (e) {
      this.onClientError(e);

      throw e;
    } finally {
    }
  }
}

export default HttpMethod;
