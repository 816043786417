import moment from "moment-timezone";
import HttpClient from "./HttpService";
import { getHeaders, getLanguage } from "../common/Auth/utils";

// import sb from "@userpilot/craftkit/src/Snackbar/snackbar";

export const REDIRECT_ATTRIBUTES = {
  STATUS: 401,
  MESSAGE: "invalid_token",
  DETAILS: "token_expired",
};

export const isRedirectToLoginRequired = ({ response }) => {
  if (response.status === REDIRECT_ATTRIBUTES.STATUS) {
    const [{ message, details, error_code }] = response?.data?.errors || [{}];
    return (
      message === REDIRECT_ATTRIBUTES.MESSAGE &&
      details === REDIRECT_ATTRIBUTES.DETAILS
    );
  }

  return false;
};

export const clientErrorHandler = (e) => {
  // Check response if it's token expired.
  if (isRedirectToLoginRequired(e)) {
    // authClient.signOut();
    window.location.href = "/";
  }

  if (e.response && e.response.status >= 400 && e.response.status < 500) {
    if (typeof e.response.data === "string") {
      //   sb.error(e.response.data);
    } else {
      const { errors } = e.response.data;
      if (errors) {
        // sb.error(errors[0].details || errors[0].message);
      }
    }
  }
};

export const addHeaderToHttpClient = (newHeader) => {
  httpClient.withBeforeHook((options) => {
    const token = getHeaders();
    const authorization = !!token ? { Authorization: `Bearer ${token}` } : {};

    const headers = {
      ...authorization,
      "content-type": "application/json",
      "Accept-TimeZone": moment.tz.guess(),
      ...newHeader,
    };
    options.headers = headers;
    return options;
  });
};

export const httpClient = new HttpClient()
  .withBaseURL(
    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}` ||
      "http://salon-dev.us-east-1.elasticbeanstalk.com/api/v2"
  )
  .withBeforeHook((options) => {
    const token = getHeaders();
    const language = getLanguage();
    const authorization = !!token ? { Authorization: `Bearer ${token}` } : {};

    const headers = {
      ...authorization,
      "content-type": "application/json",
      "Accept-TimeZone": moment.tz.guess(),
      "Accept-Language": language,
    };
    options.headers = headers;
    return options;
  })
  .withClientErrorHandler(clientErrorHandler);

export default httpClient;
