import React, { Suspense, lazy } from "react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate, Outlet } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import Footer from "./Footer/Footer";

const Logo = lazy(async () => ({
  default: (await import(".././../assets/svg/logo.svg")).ReactComponent,
}));

const Welcome = lazy(async () => ({
  default: (await import(".././../assets/svg/salon.svg")).ReactComponent,
}));

const styles = {
  pageWrapper: {
    display: "flex",
  },
  contentWrapper: { flex: 1, padding: "32px", paddingBottom: "96px" },
  content: { marginTop: "50px" },
  welcomeSvg: {
    flex: 1,
    background: "#F4F4F4",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
};

function PublicRouteLayout() {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/login", { replace: true });
  };

  return (
    <>
      <Box sx={styles.pageWrapper}>
        <Box sx={styles.contentWrapper}>
          <Button onClick={handleOnClick}>
            <Suspense fallback={<div></div>}>
              <Logo />
            </Suspense>
          </Button>
          <Box sx={styles.content}>
            <Outlet />
          </Box>
        </Box>
        <Hidden mdDown>
          <Box sx={styles.welcomeSvg}>
            <Suspense fallback={<div></div>}>
              <Welcome />
            </Suspense>
          </Box>
        </Hidden>
      </Box>
      <Footer />
    </>
  );
}

export default PublicRouteLayout;
