import { logError } from "../services/LogError";

export const getUser = () => {
  return localStorage.getItem("user");
};

export const setUser = (user) => {
  localStorage.setItem("user", user);
};

export const deleteUser = () => {
  localStorage.removeItem("user");
};

export const getHeaders = () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.value;
  } catch (e) {
    logError({
      methmodName: "getHeaders",
      file: "common/Auth/utils.js",
      error: e,
    });
  }
};

export const getLanguage = () => {
  try {
    const language = localStorage.getItem("language");
    return language;
  } catch (e) {
    logError({
      methmodName: "getLanguage",
      file: "common/Auth/utils.js",
      error: e,
    });
  }
};

export const getNotificationSound = () => {
  try {
    const notificationSound = localStorage.getItem("notificationSound");
    //  null means first time open set by default true
    if (notificationSound === null) {
      localStorage.setItem("notificationSound", true);
      return { notificationSound: true };
    }
    return JSON.parse(notificationSound);
  } catch (e) {
    logError({
      methmodName: "getNotificationSound",
      file: "common/Auth/utils.js",
      error: e,
    });
  }
};
