import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";

function CheckBoxGroup(props) {
  const { options, control, errors = {}, name, label } = props;

  return (
    <FormControl component="fieldset" error={!!errors[name]}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return options.map((item) => (
              <Box key={item.value}>
                <FormControlLabel
                  {...field}
                  label={<Typography fontWeight={700}>{item.name}</Typography>}
                  control={
                    <Checkbox
                      checked={!!field.value.includes(item.value)}
                      onChange={() => {
                        if (!field.value.includes(item.value)) {
                          field.onChange([...field.value, item.value]);
                          return;
                        }
                        const newTopics = field.value.filter(
                          (topic) => topic !== item.value
                        );
                        field.onChange(newTopics);
                      }}
                    />
                  }
                />
                {!!item.helperText && (
                  <Typography paddingLeft={4} fontSize={14}>
                    {item.helperText}
                  </Typography>
                )}
              </Box>
            ));
          }}
        />
      </FormGroup>
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
}

export default CheckBoxGroup;
