import React, { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

import {
  CLIENT_DATA_REDUCER_NAME,
  CURRENCY_SYMBOL,
} from "../../../common/constants";
import useCustomSnackbar from "../../../common/components/Snackbar/useSnackbar";
import * as API from "../api";

const MODAL_SLOTS = { backdrop: Backdrop };
const SLOT_PROPS = {
  backdrop: {
    timeout: 500,
  },
};

const schema = yup
  .object({
    price: yup.number().positive().min(0).required(),
  })
  .required();

const styles = {
  root: {
    padding: "12px 5px 12px 5px",
    overflow: "hidden",
  },
  header: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#858585",
  },
  listWrapper: {
    padding: "12px 0",
    display: "flex",
    flexDirection: "column",
  },
  accordionStyle: { width: "100% !important" },
  packagePrice: {
    color: "text.secondary",
    flex: 1,
    textAlign: "end",
    marginRight: "10px",
    fontWeight: "700",
  },
  packageTitle: { width: "33%", flexShrink: 0, fontWeight: "700" },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  saveBtnStyle: { marginRight: 2 },
  inputWrapperStyle: { p: 1, width: "100%" },
};

function Offer(props) {
  const { appointmentData, canEditAppointment } = props;
  const { offer, id: appointmentId } = appointmentData || {};
  const { title, price, minPrice, maxPrice, subServices, priceType } =
    offer || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: { price },
  });

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const snackbar = useCustomSnackbar();

  const [expanded, setExpanded] = useState("panel1");
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currencySign =
    queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME])?.currency?.sign ||
    CURRENCY_SYMBOL;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleEditServices = () => {
    setIsEditModeActive(true);
  };

  const handleCancelEditService = () => {
    reset();
    setIsEditModeActive(false);
  };

  const handleSaveOfferPrice = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        externalId: offer.id,
        price: values.price,
        type: "OFFER",
      };

      await API.updateAppointmentOfferOrPackage(appointmentData.id, payload);
      queryClient.invalidateQueries([appointmentId]);

      handleCancelEditService();
      snackbar.showSuccess(t("appointment_edit_successfully"));
    } catch (error) {
      snackbar.showError(t("something_went_wrong"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={styles.root}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Typography sx={styles.header}>{t("offer_details")}</Typography>
        {!isEditModeActive && (
          <Box>
            <Button
              color="inherit"
              startIcon={<EditIcon />}
              onClick={handleEditServices}
              disabled={!canEditAppointment}
            >
              {t("edit_offer_price")}
            </Button>
          </Box>
        )}
      </Stack>
      <Box sx={styles.listWrapper}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={styles.accordionStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={styles.packageTitle}>{title}</Typography>
            {!!subServices?.length > 0 && (
              <Typography sx={styles.packagePrice}>
                {priceType === "FREE"
                  ? t("free")
                  : priceType === "FIXED"
                  ? `${currencySign}${price}`
                  : `${currencySign}${minPrice} - ${currencySign}${maxPrice}`}
              </Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {subServices?.map((service) => {
              return <Typography key={service.id}>{service.name}</Typography>;
            })}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isEditModeActive}
        onClose={handleCancelEditService}
        closeAfterTransition
        slots={MODAL_SLOTS}
        slotProps={SLOT_PROPS}
      >
        <Fade in={isEditModeActive}>
          <Box sx={styles.modalStyle}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              marginBottom={2}
            >
              {title}
            </Typography>
            <form onSubmit={handleSubmit(handleSaveOfferPrice)}>
              <FormControl sx={styles.inputWrapperStyle} variant="outlined">
                <TextField
                  id="outlined-basic"
                  label={title}
                  variant="outlined"
                  fullWidth
                  placeholder={
                    priceType === "RANGES" ? `${minPrice} - ${maxPrice}` : price
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySign}
                      </InputAdornment>
                    ),
                  }}
                  {...register("price")}
                  error={!!errors?.price?.message}
                />
                {!!errors?.price?.message && (
                  <FormHelperText>{errors?.price?.message}</FormHelperText>
                )}
              </FormControl>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                mt={2}
              >
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  sx={styles.saveBtnStyle}
                >
                  {t("save")}
                </LoadingButton>

                <Button variant="outlined" onClick={handleCancelEditService}>
                  {t("cancel")}
                </Button>
              </Stack>
            </form>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Offer;
