export const WEB_SOCKETS_RESERVED_EVENTS = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  CONNECT_ERROR: "connect_error",
  ERROR: "error",
  PING: "ping",
  RECONNECT: "reconnect",
  RECONNECT_ATTEMPT: "reconnect_attempt",
  RECONNECT_ERROR: "reconnect_error",
  RECONNECT_FAILED: "reconnect_failed",
};

export const WEB_SOCKETS = {
  REGISTER: "socket::register",
  NEW_APPOINTMENT: "socket::newAppointment",
  USER_CANCEL_APPOINTMENT: "socket::userCancelAppointment",
  USER_UPDATE_APPOINTMENT: "socket::userUpdateAppointment",
  USER_APPROVE_RESCHEDULE_APPOINTMENT:
    "socket::userApproveRescheduleAppointment",
  USER_REJECT_RESCHEDULE_APPOINTMENT: "socket::userRejectRescheduleAppointment",
  CLIENT_MEMBER_CANCEL_APPOINTMENT: "socket::memberCancelAppointment",
  CLIENT_MEMBER_UPDATE_APPOINTMENT: "socket::memberUpdateAppointment",
  CLIENT_MEMBER_UPDATE_APPOINTMENT_EMIT: "socket::clientAppointmentUpdate",
  // Payment web sockets
  PAYMENT_LINK_PAID_TRANSACTION: "socket::userPayForTransaction",
  APPOINTMENT_RESERVATION_PAID_TRANSACTION: "socket::userPayForAppointment",
  // Waha whatsapp integration
  WHATSAPP_SESSION_STATUS: "socket::wahaSessionStatus",
};
