import httpClient from "../../http/HttpClient";
import { formatAppointmentsListResponse } from "./utils";

export const fetchUpCompingAppointment = (payload) =>
  httpClient
    .get(`/client/appointments/upcoming`)
    .withParams(payload)
    .withResponseTransformer((response) =>
      formatAppointmentsListResponse(response)
    )
    .send();

export const fetchCompletedAppointments = (payload) =>
  httpClient
    .get(`/client/appointments/completed/pending`)
    .withParams(payload)
    .withResponseTransformer((response) =>
      formatAppointmentsListResponse(response)
    )
    .send();

export const fetchNotification = (payload) =>
  httpClient.get(`/client/notifications`).withParams(payload).send();

export const seenNotifications = () =>
  httpClient.post(`/client/notification/badge/seen`).send();

export const bulkEditAppointments = (payload) =>
  httpClient.post(`/client/appointment/bulk/edit`).withData(payload).send();
