import httpClient from "../../http/HttpClient";

export const getMemberDetails = (id) =>
  httpClient.get(`/client/members/${id}`).send();

export const getScopeList = () =>
  httpClient.get(`/client/members/scopes`).send();

export const updateMember = ({ id, payload }) =>
  httpClient.put(`/client/members/${id}/update`).withData(payload).send();

export const deleteMember = (memberId) =>
  httpClient.delete(`client/members/${memberId}`).send();

export const inviteMember = (payload) =>
  httpClient.post(`/client/members/invite`).withData(payload).send();
