import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { socket } from "../../../socket";
import {
  WEB_SOCKETS,
  WEB_SOCKETS_RESERVED_EVENTS,
} from "../../websockets-constants";
import { useAuthContext } from "../../Auth/useAuthContext";
import { CLIENT_DATA_REDUCER_NAME, NOTIFICATION_COUNT } from "../../constants";
import cashRegisterMoneyNotificationSound from "../../../assets/sounds/cash_register_money.mp3";
import { AGENDA_REDUCER_NAME } from "../../../containers/Calendar/constants";
import AppointmentSnackbar from "./components/AppointmentSnackbar";
import { MY_UPCOMING_APPOINTMENTS } from "../../../containers/Dashboard/constants";
import { UPCOMING_APPOINTMENTS_REDUCER_NAME } from "../../../containers/ViewAppointments/constants";
import { useSettingsContext } from "../../Settings/useSettingsContext";
import { NOTIFICATION } from "../../../containers/Notification/constants";
import { WHATS_APP_SESSION_REDUCER_NAME } from "../../../containers/WhatsappMessages/constants";

function WebSocket() {
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const { settings } = useSettingsContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [appointment, setAppointment] = useState({});

  const clientId = queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME])?.id;

  useEffect(() => {
    socket.on(WEB_SOCKETS_RESERVED_EVENTS.CONNECT, onConnect);
    socket.on(WEB_SOCKETS_RESERVED_EVENTS.DISCONNECT, onDisconnect);
    socket.on(WEB_SOCKETS.NEW_APPOINTMENT, onNewAppointment);
    socket.on(WEB_SOCKETS.USER_CANCEL_APPOINTMENT, onUserCancelAppointment);
    socket.on(WEB_SOCKETS.USER_UPDATE_APPOINTMENT, onUserUpdateAppointment);
    socket.on(
      WEB_SOCKETS.USER_APPROVE_RESCHEDULE_APPOINTMENT,
      onUserApproveRescheduleAppointment
    );
    socket.on(
      WEB_SOCKETS.USER_REJECT_RESCHEDULE_APPOINTMENT,
      onUserRejectRescheduleAppointment
    );
    socket.on(
      WEB_SOCKETS.CLIENT_MEMBER_CANCEL_APPOINTMENT,
      onClientMemberCancelAppointment
    );
    socket.on(
      WEB_SOCKETS.CLIENT_MEMBER_UPDATE_APPOINTMENT,
      onClientMemberUpdateAppointment
    );
    socket.on(
      WEB_SOCKETS.PAYMENT_LINK_PAID_TRANSACTION,
      onPaymentLinkPaidTransaction
    );
    socket.on(
      WEB_SOCKETS.APPOINTMENT_RESERVATION_PAID_TRANSACTION,
      onAppointmentReservationWithPayment
    );

    socket.on(WEB_SOCKETS.WHATSAPP_SESSION_STATUS, onWhatsappSessionStatus);

    // Register websocket
    !!clientId &&
      socket.emit(WEB_SOCKETS.REGISTER, {
        clientId,
        userId: user?.id,
        roomId: `room_${clientId}`,
      });
    return () => {
      socket.off(WEB_SOCKETS_RESERVED_EVENTS.CONNECT, onConnect);
      socket.off(WEB_SOCKETS_RESERVED_EVENTS.DISCONNECT, onDisconnect);
      socket.off(WEB_SOCKETS.NEW_APPOINTMENT, onNewAppointment);
      socket.off(WEB_SOCKETS.USER_CANCEL_APPOINTMENT, onUserCancelAppointment);
      socket.off(WEB_SOCKETS.USER_UPDATE_APPOINTMENT, onUserUpdateAppointment);
      socket.off(
        WEB_SOCKETS.USER_APPROVE_RESCHEDULE_APPOINTMENT,
        onUserApproveRescheduleAppointment
      );
      socket.off(
        WEB_SOCKETS.USER_REJECT_RESCHEDULE_APPOINTMENT,
        onUserRejectRescheduleAppointment
      );
      socket.off(
        WEB_SOCKETS.CLIENT_MEMBER_CANCEL_APPOINTMENT,
        onClientMemberCancelAppointment
      );
      socket.off(
        WEB_SOCKETS.CLIENT_MEMBER_UPDATE_APPOINTMENT,
        onClientMemberUpdateAppointment
      );
      socket.off(
        WEB_SOCKETS.PAYMENT_LINK_PAID_TRANSACTION,
        onPaymentLinkPaidTransaction
      );
      socket.off(
        WEB_SOCKETS.APPOINTMENT_RESERVATION_PAID_TRANSACTION,
        onAppointmentReservationWithPayment
      );

      socket.off(WEB_SOCKETS.WHATSAPP_SESSION_STATUS, onWhatsappSessionStatus);
    };
  }, [settings]);

  const handleClose = () => {
    setIsOpen(false);
  };

  function onConnect() {
    // this event happen on disconneting then reconnecting to server
    !!clientId &&
      socket.emit(WEB_SOCKETS.REGISTER, {
        clientId,
        userId: user?.id,
        roomId: `room_${clientId}`,
      });
    setIsConnected(true);
  }

  function onDisconnect() {
    setIsConnected(false);
  }

  function onNewAppointment(data) {
    try {
      // const AgendaReducerData = queryClient.getQueriesData([
      //   AGENDA_REDUCER_NAME,
      // ]);

      // const [reducerArray, appointmentsArray] =
      //   AgendaReducerData[AgendaReducerData?.length - 1] || [];

      // queryClient.invalidateQueries(reducerArray);
      queryClient.invalidateQueries([NOTIFICATION_COUNT]);
      queryClient.invalidateQueries([NOTIFICATION]);
      queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      queryClient.invalidateQueries([MY_UPCOMING_APPOINTMENTS]);
      queryClient.invalidateQueries([UPCOMING_APPOINTMENTS_REDUCER_NAME]);

      setAppointment({ ...data, socketType: WEB_SOCKETS.NEW_APPOINTMENT });

      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  function onUserCancelAppointment(data) {
    try {
      // const AgendaReducerData = queryClient.getQueriesData([
      //   AGENDA_REDUCER_NAME,
      // ]);
      // const [reducerArray, appointmentsArray] =
      //   AgendaReducerData[AgendaReducerData?.length - 1] || [];

      // queryClient.invalidateQueries(reducerArray);
      queryClient.invalidateQueries([NOTIFICATION_COUNT]);
      queryClient.invalidateQueries([NOTIFICATION]);
      queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      queryClient.invalidateQueries([MY_UPCOMING_APPOINTMENTS]);
      queryClient.invalidateQueries([UPCOMING_APPOINTMENTS_REDUCER_NAME]);

      setAppointment({
        ...data,
        socketType: WEB_SOCKETS.USER_CANCEL_APPOINTMENT,
      });
      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {}
  }

  function onUserUpdateAppointment(data) {
    try {
      // const AgendaReducerData = queryClient.getQueriesData([
      //   AGENDA_REDUCER_NAME,
      // ]);
      // const [reducerArray, appointmentsArray] =
      //   AgendaReducerData[AgendaReducerData?.length - 1] || [];

      // queryClient.invalidateQueries(reducerArray);
      queryClient.invalidateQueries([NOTIFICATION_COUNT]);
      queryClient.invalidateQueries([NOTIFICATION]);
      queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      queryClient.invalidateQueries([MY_UPCOMING_APPOINTMENTS]);
      queryClient.invalidateQueries([UPCOMING_APPOINTMENTS_REDUCER_NAME]);

      setAppointment({
        ...data,
        socketType: WEB_SOCKETS.USER_UPDATE_APPOINTMENT,
      });
      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {}
  }

  function onUserApproveRescheduleAppointment(data) {
    try {
      // const AgendaReducerData = queryClient.getQueriesData([
      //   AGENDA_REDUCER_NAME,
      // ]);
      // const [reducerArray, appointmentsArray] =
      //   AgendaReducerData[AgendaReducerData?.length - 1] || [];

      // queryClient.invalidateQueries(reducerArray);
      queryClient.invalidateQueries([NOTIFICATION_COUNT]);
      queryClient.invalidateQueries([NOTIFICATION]);
      queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      queryClient.invalidateQueries([MY_UPCOMING_APPOINTMENTS]);
      queryClient.invalidateQueries([UPCOMING_APPOINTMENTS_REDUCER_NAME]);

      setAppointment({
        ...data,
        socketType: WEB_SOCKETS.USER_APPROVE_RESCHEDULE_APPOINTMENT,
      });
      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {}
  }

  function onUserRejectRescheduleAppointment(data) {
    try {
      // const AgendaReducerData = queryClient.getQueriesData([
      //   AGENDA_REDUCER_NAME,
      // ]);
      // const [reducerArray, appointmentsArray] =
      //   AgendaReducerData[AgendaReducerData?.length - 1] || [];

      // queryClient.invalidateQueries(reducerArray);
      queryClient.invalidateQueries([NOTIFICATION_COUNT]);
      queryClient.invalidateQueries([NOTIFICATION]);
      queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      queryClient.invalidateQueries([MY_UPCOMING_APPOINTMENTS]);
      queryClient.invalidateQueries([UPCOMING_APPOINTMENTS_REDUCER_NAME]);

      setAppointment({
        ...data,
        socketType: WEB_SOCKETS.USER_REJECT_RESCHEDULE_APPOINTMENT,
      });
      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {}
  }

  function onClientMemberCancelAppointment(data) {
    try {
      // const AgendaReducerData = queryClient.getQueriesData([
      //   AGENDA_REDUCER_NAME,
      // ]);
      // const [reducerArray, appointmentsArray] =
      //   AgendaReducerData[AgendaReducerData?.length - 1] || [];

      // queryClient.invalidateQueries(reducerArray);
      queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      queryClient.invalidateQueries([MY_UPCOMING_APPOINTMENTS]);
      queryClient.invalidateQueries([UPCOMING_APPOINTMENTS_REDUCER_NAME]);
      setAppointment({
        ...data,
        socketType: WEB_SOCKETS.CLIENT_MEMBER_CANCEL_APPOINTMENT,
      });
      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {}
  }

  function onClientMemberUpdateAppointment(data) {
    try {
      // const AgendaReducerData = queryClient.getQueriesData([
      //   AGENDA_REDUCER_NAME,
      // ]);
      // const [reducerArray, appointmentsArray] =
      //   AgendaReducerData[AgendaReducerData?.length - 1] || [];

      if (window?.location?.pathname !== "/notification") {
        // queryClient.invalidateQueries(reducerArray);
        queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      }
      queryClient.invalidateQueries([MY_UPCOMING_APPOINTMENTS]);
      queryClient.invalidateQueries([UPCOMING_APPOINTMENTS_REDUCER_NAME]);
      setAppointment({
        ...data,
        socketType: WEB_SOCKETS.CLIENT_MEMBER_UPDATE_APPOINTMENT,
      });
      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {}
  }

  function onPaymentLinkPaidTransaction(data) {
    try {
      queryClient.invalidateQueries([NOTIFICATION]);
      queryClient.invalidateQueries([NOTIFICATION_COUNT]);

      setAppointment({
        ...data,
        socketType: WEB_SOCKETS.PAYMENT_LINK_PAID_TRANSACTION,
      });
      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {}
  }
  function onAppointmentReservationWithPayment(data) {
    try {
      // queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      // queryClient.invalidateQueries([MY_UPCOMING_APPOINTMENTS]);
      // queryClient.invalidateQueries([UPCOMING_APPOINTMENTS_REDUCER_NAME]);

      setAppointment({
        ...data,
        socketType: WEB_SOCKETS.APPOINTMENT_RESERVATION_PAID_TRANSACTION,
      });
      setIsOpen(true);
      if (!!settings?.notificationSound) {
        const audio = new Audio(cashRegisterMoneyNotificationSound);
        audio.play();
      }
    } catch (error) {}
  }

  function onWhatsappSessionStatus(data) {
    queryClient.invalidateQueries([WHATS_APP_SESSION_REDUCER_NAME]);
  }

  return (
    <div>
      <AppointmentSnackbar
        appointment={appointment}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </div>
  );
}

export default WebSocket;
