import React from "react";
import PropTypes from "prop-types";
import {
  Snackbar,
  Button,
  Typography,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";

const DEFAULT_TITLE = {
  error: "Error",
  warning: "Warning!",
  info: "Information",
  success: "Success",
};

const styles = {
  alertStyle: {
    borderLeftStyle: "solid",
    borderLeftWidth: 4,
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
};

export default function CustomSnackbar({
  message,
  snackbarTitle,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters,
}) {
  const title = React.useMemo(
    () =>
      customParameters.title || DEFAULT_TITLE[customParameters.type || "info"],
    [customParameters.type, customParameters.title]
  );

  return (
    <Snackbar autoHideDuration={3000} {...SnackbarProps}>
      <Alert
        sx={styles.alertStyle}
        severity={customParameters?.type}
        color={customParameters?.type}
        variant="standard"
        action={
          action != null && (
            <Button color="inherit" size="small" {...ButtonProps}>
              {action}
            </Button>
          )
        }
      >
        <AlertTitle variant="body2" color="textPrimary" gutterBottom={false}>
          <Box fontWeight="bold">{snackbarTitle || title}</Box>
        </AlertTitle>
        <Typography
          variant="caption"
          color="textPrimary"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Alert>
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  message: PropTypes.string,
  snackbarTitle: PropTypes.string,
  action: PropTypes.string,
  ButtonProps: PropTypes.object,
  SnackbarProps: PropTypes.object,
  customParameters: PropTypes.shape({
    type: PropTypes.oneOf(["error", "warning", "info", "success"]),
    title: PropTypes.string,
  }),
};

CustomSnackbar.defaultProps = {
  customParameters: {
    type: "info",
    title: "",
  },
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};
