export const MY_UPCOMING_APPOINTMENTS = "MY_UPCOMING_APPOINTMENTS";
export const MY_DASHBOARD_OVERVIEW = "MY_DASHBOARD_OVERVIEW";
export const INCOME_BREAKDOWN_CHART = "INCOME_BREAKDOWN_CHART";
export const TOP_SERVICES_CHART = "TOP_SERVICES_CHART";
export const MEMBER_APPOINTMENTS_CHART = "MEMBER_APPOINTMENTS_CHART";
export const REVIEWS_SUMMARY = "REVIEWS_SUMMARY";
export const REVIEWS_DATA = "REVIEWS_DATA";

export const ANIMATED_CONFIG = [
  { mass: 1, tension: 220, friction: 100 },
  { mass: 1, tension: 180, friction: 130 },
  { mass: 1, tension: 280, friction: 90 },
  { mass: 1, tension: 180, friction: 135 },
  { mass: 1, tension: 260, friction: 100 },
  { mass: 1, tension: 210, friction: 180 },
];
