import React, { useCallback, useState } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import moment from "moment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { FormProvider, useForm } from "react-hook-form";
import { IconButton, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import styled from "@emotion/styled";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { fetchAppointment } from "../../Calendar/api";
import AppointmentDetails from "../../Calendar/components/AppointmentDetails";
import Services from "../../Calendar/components/Services";
import MuiTextField from "../../../common/Form/Field/TextField";
import AppointmentActions from "./AppointmentActions";
import {
  APPOINTMENT_DETAILS_TYPES,
  APPOINTMENT_STATUSES_VALUES,
} from "../../Calendar/constants";
import * as API from "../../Calendar/api";
import Offer from "../../Calendar/components/Offer";
import Package from "../../Calendar/components/Package";
import { logError } from "../../../common/services/LogError";
import DrawerAppointmentEditServiceTab from "../../Calendar/components/DrawerTabs/DrawerAppointmentEditServiceTab";
import "./styles.css"; // You need to create this CSS file for animations
import { CLIENT_SERVICES_DATA_REDUCER_NAME } from "../../../common/constants";
import { fetchClientServices } from "../../../common/api/clientAPI";

const noteInputProps = { maxLength: 300 };

function AppointmentData(props) {
  const { appointmentId, isCompleted, onFinishCb = () => {} } = props;
  const { t } = useTranslation();
  const methods = useForm();
  const theme = useTheme();
  const { reset, handleSubmit } = methods;

  const [isCanceling, setIsCanceling] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [tabContextValue, setTabContextValue] = useState(
    APPOINTMENT_DETAILS_TYPES.APPOINTMENT
  );

  const {
    data: clientServicesWithMembers = [],
    isLoading: isClientServiceLoading,
    refetch,
  } = useQuery(
    [CLIENT_SERVICES_DATA_REDUCER_NAME],
    async () => {
      try {
        const data = await fetchClientServices();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchClientServices",
          file: "common/components/EditableServicesList.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: true,
    }
  );

  const {
    isFetching: isLFetchingAppointment,
    data: appointmentData = { id: null, user: {}, services: [] },
  } = useQuery(
    [appointmentId],
    async () => {
      try {
        const data = await fetchAppointment(appointmentId);
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchAppointment",
          file: "containers/Notification/components/AppointmentData.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: !!appointmentId,
      onSuccess: (data) =>
        reset(
          {
            status: data.status,
            from: moment(data?.startTime).format("hh:mm A"),
            to: moment(data?.endTime).format("hh:mm A"),
            selected_day: moment(data?.startTime).format("ddd, MMM DD"),
            startDate: moment(data?.startTime).format("YYYY-MM-DD"),
            appointment_color: data?.color || "#C3281C",
          },
          { keepDefaultValues: false }
        ),
    }
  );

  const { id, user, services } = appointmentData;

  const onSubmit = useCallback(
    async (values) => {
      try {
        let action;
        if (values.status === APPOINTMENT_STATUSES_VALUES.REJECTED) {
          action = "REJECT";
        } else if (values.status === APPOINTMENT_STATUSES_VALUES.APPROVED) {
          action = "APPROVE";
        }

        const payload = {
          appointmentDate: moment(
            moment(
              `${moment(values.startDate, "YYYY-MM-DD")
                .locale("en")
                .format("YYYY-MM-DD")} ${moment(values.from, "hh:mm A")
                .locale("en")
                .format("HH:mm")}`
            )
          ),
          emojis: values?.emojis || [],
          services: services.map((service) => {
            let newService = {
              id: service.id,
              discount: service?.discount,
              memberId: service?.memberId,
            };
            if (!!service.price || service.price === 0) {
              newService.price = service.price;
            } else {
              newService.minPrice = service.minPrice;
              newService.maxPrice = service.maxPrice;
            }
            return newService;
          }),
          note: values?.note,
          status: action,
        };

        let data = await API.updateAppointment(id, payload);

        if (data.status === APPOINTMENT_STATUSES_VALUES.REJECTED) {
          data = await API.rejectAppointment(appointmentId);
        } else if (
          (data.status === APPOINTMENT_STATUSES_VALUES.APPROVED ||
            (action === "APPROVE" &&
              data.status === APPOINTMENT_STATUSES_VALUES.RESCHEDULED)) &&
          moment(
            moment(
              `${moment(values.startDate, "YYYY-MM-DD")
                .locale("en")
                .format("YYYY-MM-DD")} ${moment(values.from, "hh:mm A")
                .locale("en")
                .format("HH:mm")}`
            )
          ).isBefore(moment())
        ) {
          data = await API.completeAppointment(appointmentId);
        } else if (data.status === APPOINTMENT_STATUSES_VALUES.PENDING) {
          data = await API.approveAppointment(appointmentId);
        }
        onFinishCb(data);
      } catch (error) {}
    },
    [appointmentData]
  );

  const onAppointmentDismissed = useCallback(async () => {
    try {
      setIsCanceling(true);
      const data = await API.cancelAppointment(id);
      onFinishCb(data);
    } catch (error) {
    } finally {
      setIsCanceling(false);
    }
  }, [appointmentData]);

  const handleEditServiceClicked = (service) => {
    setSelectedService(service);
    setTabContextValue(APPOINTMENT_DETAILS_TYPES.EDIT_SERVICE);
  };

  const handleBackFromEditService = () => {
    setSelectedService({});
    setTabContextValue(APPOINTMENT_DETAILS_TYPES.APPOINTMENT);
  };

  const handleAddOneServiceCb = () => {
    setTabContextValue(APPOINTMENT_DETAILS_TYPES.EDIT_SERVICE);
  };

  if (!appointmentId) {
    return <></>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isLFetchingAppointment ? (
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TabContext value={tabContextValue}>
          <TransitionGroup>
            <CSSTransition key={tabContextValue} classNames="tab" timeout={300}>
              <TabPanel
                value={APPOINTMENT_DETAILS_TYPES.APPOINTMENT}
                sx={styles.paddingZero}
              >
                <FormProvider {...methods}>
                  <form>
                    <Header
                      // color={appointmentData?.color}
                      color={theme.palette.primary.main}
                    >
                      <Box
                        maxHeight={window.innerHeight}
                        overflow="auto"
                        position="relative"
                        p={4}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <span>{t("appointment_details")}</span>
                          {/* <Box>
                    <Tooltip title={t("delete_appointment")}>
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box> */}
                        </Box>
                        <Box>
                          <Box flex={1}>
                            <AppointmentDetails
                              data={appointmentData}
                              canEditAppointment={false}
                            />
                          </Box>

                          {appointmentData?.type === "OFFER" && (
                            <Offer
                              appointmentData={appointmentData}
                              canEditAppointment={true}
                            />
                          )}
                          {appointmentData?.type === "PACKAGE" && (
                            <Package
                              appointmentData={appointmentData}
                              canEditAppointment={true}
                            />
                          )}

                          <Services
                            list={appointmentData?.services?.map((item) => ({
                              ...item,
                              serviceId: item.id,
                            }))}
                            totalPrice={appointmentData?.totalPrice}
                            totalPriceType={appointmentData?.totalPriceType}
                            couponType={appointmentData?.couponType}
                            totalMinPrice={appointmentData?.totalMinPrice}
                            totalMaxPrice={appointmentData?.totalMaxPrice}
                            originalTotalPrice={
                              appointmentData?.originalTotalPrice
                            }
                            credits={appointmentData?.credits}
                            coupon={appointmentData?.coupon}
                            appointmentId={appointmentData?.id}
                            appointmentDate={moment(appointmentData?.startTime)
                              .locale("en")
                              .format("YYYY-MM-DD")}
                            totalAttendees={appointmentData?.totalAttendees}
                            appointmentStartTime={moment(
                              appointmentData?.startTime
                            )
                              .locale("en")
                              .format("hh:mm A")}
                            sections={appointmentData?.sections}
                            handleAddOneServiceCb={handleAddOneServiceCb}
                            handleEditServiceClickedCb={
                              handleEditServiceClicked
                            }
                            isPackage={appointmentData?.type === "PACKAGE"}
                          />
                          <MuiTextField
                            name="note"
                            label={t("note")}
                            placeholder="write special notes about your customer services here"
                            multiline
                            minRows={3}
                            maxRows={3}
                            sx={styles.noteTextField}
                            inputProps={noteInputProps}
                          />
                        </Box>
                      </Box>
                      <Box mt={8}>
                        <AppointmentActions
                          isCompleted={isCompleted}
                          appointmentData={appointmentData}
                          isCanceling={isCanceling}
                          onSubmit={handleSubmit(onSubmit)}
                          onIgnore={handleSubmit(onAppointmentDismissed)}
                        />
                      </Box>
                    </Header>
                  </form>
                </FormProvider>
              </TabPanel>
            </CSSTransition>
          </TransitionGroup>
          <TransitionGroup>
            <CSSTransition key={tabContextValue} classNames="tab" timeout={300}>
              <TabPanel
                value={APPOINTMENT_DETAILS_TYPES.EDIT_SERVICE}
                sx={styles.paddingZero}
              >
                <DrawerAppointmentEditServiceTab
                  appointmentId={appointmentId}
                  service={selectedService}
                  handleBackCb={handleBackFromEditService}
                  startTime={appointmentData?.startTime}
                />
              </TabPanel>
            </CSSTransition>
          </TransitionGroup>
        </TabContext>
      )}
    </LocalizationProvider>
  );
}

const styles = {
  noteTextField: {
    flex: 1,
    width: "100%",
  },
};

const Header = styled("div")((props) => ({
  height: "100%",
  borderTop: `10px solid ${props?.color || "#C3281C"}`,
}));

export default AppointmentData;
