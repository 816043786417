import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import { ReactComponent as UkIcon } from "../../../assets/svg/gb.svg";
import { ReactComponent as PSIcon } from "../../../assets/svg/ps.svg";
import moment from "moment";
import arLocale from "moment/locale/ar";
import enLocale from "moment/locale/en-gb";
import { logError } from "../../services/LogError";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  dialogTitleStyle: { alignItems: "center", display: "flex" },
  languageIconStyle: { marginRight: 1 },
};
function LanguageDialog(props) {
  const {
    isOpen = false,
    handleClose = () => {},
    setLanguage = () => {},
  } = props;
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const handleArabicLanguageChange = async () => {
    try {
      localStorage.setItem("language", "ar");
      await i18n.changeLanguage("ar");
      const direction = i18n.dir();
      document.body.dir = direction;
      theme.direction = direction;
      setLanguage("ar");
      moment.locale("ar", arLocale);

      handleClose();
    } catch (error) {
      logError({
        error,
        file: "LanguageDialog.jsx",
        methodName: "handleArabicLanguageChange",
      });
    }
  };
  const handleEnglishLanguageChange = async () => {
    try {
      localStorage.setItem("language", "en");
      await i18n.changeLanguage("en");
      const direction = i18n.dir();
      document.body.dir = direction;
      theme.direction = direction;
      setLanguage("en");
      moment.locale("en", enLocale);

      handleClose();
    } catch (error) {
      logError({
        error,
        file: "LanguageDialog.jsx",
        methodName: "handleEnglishLanguageChange",
      });
    }
  };

  const { dialogTitleStyle, languageIconStyle } = styles;
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={dialogTitleStyle}>
        <LanguageIcon sx={languageIconStyle} />
        {t("choose_a_language")}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<PSIcon width={70} height={50} />}
              onClick={handleArabicLanguageChange}
            >
              {t("arabic")}
            </Button>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<UkIcon width={70} height={50} />}
              onClick={handleEnglishLanguageChange}
            >
              {t("english")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default LanguageDialog;
