import React, { useState, useCallback, useMemo } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Paper,
  Typography,
  Stack,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import AppointmentData from "./AppointmentData";
import { bulkEditAppointments, fetchUpCompingAppointment } from "../api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CheckBox from "../../../common/Form/Field/CheckBox";
import {
  LIST_API_LIMIT,
  UP_COMING_APPOINTMENTS_REDUCER_NAME,
} from "../constants";
import { NOTIFICATION_COUNT } from "../../../common/constants";
import { logError } from "../../../common/services/LogError";

function UpcomingAppointments() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const styles = useMemo(() => generateStyles(theme), []);

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const [page, setPage] = useState(1);

  const methods = useForm({
    defaultValues: {
      appointments: {},
    },
  });

  const { setValue, watch, getValues } = methods;

  const selectedAppointments = watch();

  const {
    isLoading: isLFetchingAppointment,
    data: upCompingAppointments = { content: [], totalElements: 0 },
  } = useQuery(
    [UP_COMING_APPOINTMENTS_REDUCER_NAME],
    async () => {
      try {
        const data = await fetchUpCompingAppointment({
          page: page,
          limit: LIST_API_LIMIT,
        });
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchUpCompingAppointment",
          file: "containers/Notification/components/UpcomingAppointments.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      onSuccess: (data) => {
        if (!data.content[0]) return;
        setSelectedAppointment(data.content[0].id);
      },
    }
  );

  const fetchMore = async () => {
    try {
      const newPage = page + 1;
      const data = await fetchUpCompingAppointment({
        page: newPage,
        limit: LIST_API_LIMIT,
      });
      queryClient.setQueryData([UP_COMING_APPOINTMENTS_REDUCER_NAME], {
        totalElements: upCompingAppointments.totalElements,
        content: [...upCompingAppointments.content, ...data.content],
      });
      setPage(newPage);
    } catch (e) {
      logError({
        methodName: "fetchMore",
        file: "UpcomingAppointments.jsx",
        error: e,
      });
    }
  };

  const { content, totalElements } = upCompingAppointments;

  const handleSelectAppointment = useCallback((id) => {
    setSelectedAppointment(id);
  }, []);

  const onSelectAll = useCallback(
    (event) => {
      let clonedCheckArray = [];
      for (let index = 0; index < content.length; index++) {
        const appointment = content[index];
        setValue(`appointments.${appointment.id}`, event.target.checked);
        clonedCheckArray = [...clonedCheckArray, event.target.checked];
      }

      if (clonedCheckArray?.filter(Boolean)?.length === 0) {
        setValue("all_checked", false);
      } else {
        setValue("all_checked", event.target.checked);
      }
    },
    [content]
  );

  const checkedItems = useMemo(
    () =>
      Object.keys(selectedAppointments?.appointments)
        .map((value) => selectedAppointments?.appointments[value])
        .filter(Boolean).length,
    [selectedAppointments]
  );

  const isIntermediate = useMemo(() => {
    return checkedItems > 0;
  }, [checkedItems]);

  const bulkApprove = useCallback(async () => {
    try {
      setIsApproving(true);
      const ids = Object.keys(selectedAppointments?.appointments).filter(
        (value) => !!selectedAppointments?.appointments[value]
      );

      await bulkEditAppointments({ ids: ids, action: "APPROVE" });
      setPage(1);
      setValue("appointments", {});
      setSelectedAppointment(null);
      queryClient.invalidateQueries([UP_COMING_APPOINTMENTS_REDUCER_NAME]);
      queryClient.invalidateQueries([NOTIFICATION_COUNT]);
      setIsApproving(false);
    } catch {}
  }, [selectedAppointments]);

  const bulkReject = useCallback(async () => {
    try {
      setIsRejecting(true);
      const ids = Object.keys(selectedAppointments?.appointments).filter(
        (value) => !!selectedAppointments?.appointments[value]
      );

      await bulkEditAppointments({ ids: ids, action: "REJECT" });
      setPage(1);
      setValue("appointments", {});
      setSelectedAppointment(null);
      queryClient.invalidateQueries([UP_COMING_APPOINTMENTS_REDUCER_NAME]);
      queryClient.invalidateQueries([NOTIFICATION_COUNT]);
      setIsRejecting(false);
    } catch {}
  }, [selectedAppointments]);

  const handleOnFinishCb = useCallback((data) => {
    setPage(1);
    queryClient.invalidateQueries([UP_COMING_APPOINTMENTS_REDUCER_NAME]);
    queryClient.invalidateQueries([NOTIFICATION_COUNT]);
  }, []);

  return (
    <FormProvider {...methods}>
      <form>
        <>
          {!isLFetchingAppointment && totalElements === 0 ? (
            <Box
              fontSize={"24px"}
              fontWeight={400}
              py={8}
              display="flex"
              justifyContent="center"
              height={window.innerHeight * 0.7}
            >
              {t("there_is_no_pending_appointments")}
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection={matches ? "column" : "row"}
              gap="16px"
            >
              <Paper sx={styles.rootPaper}>
                <Box display="flex" alignItems="center" px={1.7} gap={1} py={1}>
                  <CheckBox
                    width={18}
                    height={18}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    name="all_checked"
                    onChange={onSelectAll}
                    indeterminate={isIntermediate}
                  />
                  <Typography>{t("select_all")}</Typography>
                </Box>

                <InfiniteScroll
                  dataLength={page * LIST_API_LIMIT}
                  next={fetchMore}
                  loader={
                    <Stack direction="row" justifyContent={"center"}>
                      <CircularProgress />
                    </Stack>
                  }
                  hasMore={content.length < totalElements}
                  height={window.innerHeight * 0.8}
                  scrollableTarget="scrollableDiv"
                >
                  <Box maxHeight="100%" display="flex" flexDirection="column">
                    {content.map((appointment, index) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          py={1}
                          px={2}
                          key={appointment.id + index}
                        >
                          <CheckBox
                            width={18}
                            height={18}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            name={`appointments.${appointment.id}`}
                            key={
                              `appointments.${appointment.id}` +
                              getValues(`appointments.${appointment.id}`)
                            }
                          />
                          <ItemPaper
                            color={appointment.color}
                            selected={appointment.id === selectedAppointment}
                            onClick={() =>
                              handleSelectAppointment(appointment.id)
                            }
                          >
                            <Box display="flex" gap="16px" px={2} py={2}>
                              <Hidden mdDown>
                                <Avatar src={appointment.avatar} />
                              </Hidden>
                              <Box display="flex" flexDirection="column">
                                <Box
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  fontSize="16px"
                                  maxWidth="100%"
                                >
                                  {appointment.name}
                                </Box>
                                <Box sx={styles.timeTypography}>
                                  {appointment.time}
                                </Box>
                              </Box>
                            </Box>
                          </ItemPaper>
                        </Box>
                      );
                    })}
                  </Box>
                </InfiniteScroll>
              </Paper>
              <Paper sx={styles.appointmentPaper}>
                {isIntermediate ? (
                  <Stack
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                  >
                    <Typography fontSize="32px" fontWeight={600}>
                      {checkedItems}
                    </Typography>
                    <Typography fontSize="24px" fontWeight={600}>
                      {t("appointments")}{" "}
                      {t("selected").charAt(0).toUpperCase() +
                        t("selected").slice(1)}
                    </Typography>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      mt={2}
                      gap={2}
                    >
                      <LoadingButton
                        loading={isApproving}
                        onClick={bulkApprove}
                        sx={styles.approveBtn}
                      >
                        {t("approve_all")}
                      </LoadingButton>
                      <LoadingButton
                        loading={isRejecting}
                        onClick={bulkReject}
                        sx={styles.rejectBtn}
                      >
                        {t("reject_all")}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                ) : (
                  <AppointmentData
                    appointmentId={selectedAppointment}
                    isCompleted={false}
                    onFinishCb={handleOnFinishCb}
                  />
                )}
              </Paper>
            </Box>
          )}
        </>
      </form>
    </FormProvider>
  );
}

const generateStyles = (theme) => ({
  avatar: {
    height: "48px",
    width: "48px",
  },
  timeTypography: {
    fontSize: "16px",
    color: "#858585",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&: hover": {
      color: "#fff",
    },
  },
  appointmentPaper: {
    flex: 1,
    position: "relative",
  },
  rootPaper: {
    width: "400px",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  approveBtn: {
    width: "50%",
    height: "51px",
    background: "#5CB85C",
    borderRadius: "6px",
    color: "#fff",
    "&: hover": {
      background: "#5CB85C",
      opacity: 0.9,
    },

    "&:disabled": {
      backgroundColor: "#DDE2E5",
      color: "#ACB5BD !important",
      border: "none",
    },
    "& .MuiLoadingButton-loadingIndicator": {
      left: "30px",
    },
  },
  rejectBtn: {
    width: "50%",
    height: "51px",
    borderRadius: "6px",
    border: "1px solid #D9534F",
    color: "#D9534F",
    "& .MuiLoadingButton-loadingIndicator": {
      left: "30px",
    },
  },
});

const ItemPaper = styled("div")((props) => ({
  width: "100%",
  height: "80px",
  display: "flex",
  borderLeft: `8px solid ${props.color}`,
  borderRadius: "6px",
  cursor: "pointer",
  background: props.selected ? "#5C729B" : "#fff",
  "& *": {
    color: props.selected ? "#fff !important" : "#000 !important",
  },
  boxShadow:
    " 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
  "&: hover": {
    background: "#5C729B",
    "& *": {
      color: "#fff !important",
    },
  },
}));

export default UpcomingAppointments;
