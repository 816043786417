import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Backdrop } from "@mui/material";
import styled from "@emotion/styled";

const styles = {
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  backdrop: {
    zIndex: 2,
  },
};

function Loader() {
  return (
    <Backdrop open={true} sx={styles.backdrop}>
      <Box sx={styles.loaderWrapper}>
        <CircularLoader />
      </Box>
    </Backdrop>
  );
}

const CircularLoader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export default Loader;
