import React, { memo, useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Paper, Modal, Typography, Button } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import moment from "moment";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import styled from "@emotion/styled";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import TimeSingleDropdown from "../../../common/components/TimeSingleDropdown";
import {
  RESERVATIONS_TYPES,
  SLOT_TYPES,
  SLOT_TYPES_COLORS,
} from "../constants";
import { fetchUserCalendarOverview } from "../api";
import { CLIENT_DATA_REDUCER_NAME } from "../../../common/constants";
import { logError } from "../../../common/services/LogError";

function DateTimePopup(props) {
  const { defaultValues, onSubmit, canEditAppointment } = props;
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useMemo(() => generateStyles(theme), []);

  const methods = useForm({
    defaultValues: defaultValues,
    mode: "all",
  });

  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = methods;

  const formWatcher = watch();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmitModal = useCallback(async (values) => {
    try {
      await onSubmit(values);
      handleClose();
    } catch {}
  }, []);

  const queryClient = useQueryClient();
  const salon = queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME]);

  const [selectedMonth, setSelectedMonth] = useState(
    moment(defaultValues?.selected_day).format("YYYY-MM-DD")
  );

  const onMonthChange = (month) => {
    setSelectedMonth(moment(month).format("YYYY-MM-DD"));
    setValue("selected_day", moment(month).toDate());
  };

  const {
    isFetching: isFetchingCalendarOverview,
    isLoading: isLoadingCalendarOverview,
    data: calendarOverview,
  } = useQuery(["CALENDAR_OVERVIEW", selectedMonth], async () => {
    try {
      const data = await fetchUserCalendarOverview({
        attendees: 1,
        date: moment(selectedMonth).startOf("month").endOf("day"),
        services: [],
        clientId: salon.id,
      });
      return data;
    } catch (error) {
      logError({
        methmodName: "fetchUserCalendarOverview",
        file: "containers/Calendar/components/DateTimePopup.jsx",
        error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
      });
    }
  });

  const formattedCalendarView = useMemo(() => {
    if (!calendarOverview) {
      return {
        [RESERVATIONS_TYPES.EXPIRED]: [],
        [RESERVATIONS_TYPES.FULL]: [],
        [RESERVATIONS_TYPES.ALMOST_FULL]: [],
        [RESERVATIONS_TYPES.FREE]: [],
        [RESERVATIONS_TYPES.CLOSED]: [],
      };
    }
    return Object.keys(calendarOverview)
      .map((date) => {
        return { status: calendarOverview[date].status, date: date };
      })
      .reduce((group, product) => {
        const { status, date } = product;
        group[status] = group[status] ?? [];
        group[status].push(date);
        return group;
      }, {});
  }, [calendarOverview]);

  const renderWeekPickerDay = (props) => {
    const { day: date, outsideCurrentMonth, ...pickersDayProps } = props;

    const isExpired = formattedCalendarView[
      RESERVATIONS_TYPES.EXPIRED
    ]?.includes(moment(date).locale("en").format("YYYY-MM-DD"));

    const isFree = formattedCalendarView[RESERVATIONS_TYPES.FREE]?.includes(
      moment(date).locale("en").format("YYYY-MM-DD")
    );

    const isAlmostFull = formattedCalendarView[
      RESERVATIONS_TYPES.ALMOST_FULL
    ]?.includes(moment(date).locale("en").format("YYYY-MM-DD"));

    const isFull = formattedCalendarView[RESERVATIONS_TYPES.FULL]?.includes(
      moment(date).locale("en").format("YYYY-MM-DD")
    );

    const isClosed = formattedCalendarView[RESERVATIONS_TYPES.CLOSED]?.includes(
      moment(date).locale("en").format("YYYY-MM-DD")
    );

    const isFutureDate = moment(date).locale("en").isAfter(moment());

    return (
      <CustomPickersDay
        {...pickersDayProps}
        outsideCurrentMonth={outsideCurrentMonth}
        day={date}
        isExpired={isExpired}
        isFree={isFree}
        isAlmostFull={isAlmostFull}
        isFull={isFull}
        isClosed={isClosed}
        isFutureDate={isFutureDate}
      />
    );
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Box>
          <Button
            sx={style.changeBtn}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={handleOpen}
          >
            {t("change")}
          </Button>
          <Modal open={open} onClose={handleClose} sx={style.modalBody}>
            <>
              <Paper sx={style.paper}>
                <Box sx={style.wrapper}>
                  <Box sx={style.titleWrapper}>
                    <Typography sx={style.headerTitle}>
                      {t("edit_appointment_time")}
                    </Typography>
                  </Box>
                  <Box sx={style.body}>
                    <Box sx={style.monthPickerWrapper}>
                      <Box sx={style.monthPicker}>
                        <Controller
                          control={control}
                          name={"selected_day"}
                          render={({ field }) => {
                            return (
                              <DateCalendar
                                {...field}
                                date={field?.value}
                                onChange={(newDate) => field.onChange(newDate)}
                                sx={styles.calendarPicker}
                                slots={{
                                  day: renderWeekPickerDay,
                                }}
                                onMonthChange={onMonthChange}
                                loading={
                                  isFetchingCalendarOverview ||
                                  isLoadingCalendarOverview
                                }
                                renderLoading={() => <DayCalendarSkeleton />}
                              />
                            );
                          }}
                        />
                      </Box>
                      <TimeSingleDropdown />
                    </Box>
                  </Box>
                </Box>

                <Box sx={style.actionsWrapper}>
                  <Button sx={style.cancelBtn} onClick={handleClose}>
                    {t("cancel")}
                  </Button>
                  <Button
                    disabled={!isValid}
                    sx={style.saveBtn}
                    onClick={handleSubmit(onSubmitModal)}
                  >
                    {t("save")}
                  </Button>
                </Box>
              </Paper>
            </>
          </Modal>
        </Box>
      </form>
    </FormProvider>
  );
}

const style = {
  changeBtn: {
    color: "#4046d6",
    fontSize: "14px",
    textDecoration: "underline",
    height: "19px",
    textTransform: "none",
  },
  itemPicker: {
    width: "150px",
    "& .MuiOutlinedInput-root": {
      height: "px",
    },
  },
  saveBtn: {
    background: "#5CB85C",
    borderRadius: "6px",
    color: "#fff",
    minWidth: "75px",
    "&:hover": {
      background: "#5CB85C",
      color: "#fff",
    },
    "&:disabled": {
      backgroundColor: "#DDE2E5",
      color: "#ACB5BD !important",
      border: "none",
    },
  },
  cancelBtn: {
    border: "1px solid #D9534F",
    borderRadius: "6px",
    color: "#D9534F",
  },
  modalBody: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    height: "518px",
    width: "500px",
  },
  wrapper: {
    padding: "12px",
    borderBottom: "1px solid #F2F2F2",
  },
  headerTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  titleWrapper: {
    marginBottom: "16px",
    borderBottom: "1px solid #F2F2F2",
    height: "28px",
  },
  body: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  monthPickerWrapper: {
    maxWidth: "359px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  monthPicker: {
    border: "1px solid #6E4B87",
    borderRadius: "6px",
  },
  timeFieldsWrapper: {
    marginTop: "16px",
    display: "flex",
    gap: "12px",
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "8px 12px 0 12px",
    gap: "12px",
  },
};

const CustomPickersDay = styled(PickersDay)(
  ({
    theme,
    isFull,
    isClosed,
    isAlmostFull,
    isExpired,
    isFree,
    isFutureDate,
  }) => {
    return {
      ...(isClosed && {
        backgroundColor: "#EA7777",
        color: "#fff",
      }),
      ...(isAlmostFull && {
        color: "#F1B095",
        fontSize: "0.85rem",
        fontWeight: "700",
      }),
      ...(isFull && {
        textDecoration: "line-through",
        color: "#c2c2c2",
      }),
      ...(isExpired && {
        color: "#c2c2c2",
      }),
    };
  }
);

const generateStyles = (theme) => {
  return {
    bodyWrapper: {
      "& .MuiDateCalendar-root": {
        width: "500px",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
      "& .MuiDayCalendarSkeleton-root": {
        width: "500px",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
      "& .MuiDateCalendar-viewTransitionContainer": {
        "& > div > div": {
          justifyContent: "space-between !important",
          paddingLeft: "0px",
          paddingRight: "0px",
        },
        "& div[role=row]": {
          paddingLeft: "0px",
          paddingRight: "0px",
          justifyContent: "space-between !important",
        },
      },
      "& .MuiDayCalendar-loadingContainer": {
        "& > div > div": {
          justifyContent: "space-between !important",
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },
    },
    closedStyle: {
      width: "8px",
      height: "8px",
      background: SLOT_TYPES_COLORS[SLOT_TYPES.CLOSED],
      borderRadius: "50px",
      fontSize: "12px",
    },
    almostFullStyle: {
      width: "8px",
      height: "8px",
      background: SLOT_TYPES_COLORS[SLOT_TYPES.ALMOST_FULL],
      borderRadius: "50px",
      fontSize: "12px",
    },
    fullStyle: {
      textDecoration: "line-through",
      color: "#c2c2c2",
      fontSize: "12px",
    },
    calendarPicker: {
      width: "100%",
    },
    infoSection: {
      [theme.breakpoints.down("md")]: {
        gap: 3,
      },
    },
  };
};

export default memo(DateTimePopup);
