import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useQuery } from "@tanstack/react-query";
import FormHelperText from "@mui/material/FormHelperText";

import EditableServicesList from "../../EditableServicesList/EditableServicesList";
import { SYSTEM_DATA_SERVICES_REDUCER_NAME } from "../../../constants";
import { logError } from "../../../services/LogError";
import { fetchAllServices } from "../../../api/systemAPI";

function WizardSalonServices(props) {
  const { errors } = props;
  const {
    isFetching: isFetchingServices,
    isLoading: isLoadingServices,
    data: services,
  } = useQuery(
    [SYSTEM_DATA_SERVICES_REDUCER_NAME],
    async () => {
      try {
        const data = await fetchAllServices();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchAllServices",
          file: "containers/EditSalon.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: true,
    }
  );

  return (
    <Box>
      <Grid container spacing={2} paddingX={4} paddingTop={2}>
        <Grid item xs={12}>
          <EditableServicesList services={services} isLargeView />
          {!!errors?.services?.message && (
            <FormHelperText error>{errors?.services?.message}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default WizardSalonServices;
