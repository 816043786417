import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import history from "./history";
import { SYSTEM_ROUTES, RENEW_SUBSCRIPTION_ROUTES } from "./constants";
import { useAuthContext } from "../common/Auth/useAuthContext";
import { getUser } from "../common/Auth/utils";
import Loader from "../common/components/Loader";
import ProtectedRoute from "./ProtectedRoute";
import MainLayout from "../common/components/MainLayout";
import PublicRouteLayout from "../common/components/PublicRouteLayout";
import { getDefaultRouteBasedOnPermission } from "./utils";
import { CLIENT_SUBSCRIPTION_DATA } from "../common/constants";
import { logError } from "../common/services/LogError";
import { getSalonSubscription } from "../common/api/clientAPI";

// Lazy-loaded components
const Login = lazy(() => import("../containers/Login"));
const ForgotPassword = lazy(() => import("../containers/ForgotPassword"));
const CreatePassword = lazy(() => import("../containers/CreatePassword"));
const AcceptInvitation = lazy(() => import("../containers/AcceptInvitation"));
const CreateNewToken = lazy(() => import("../containers/CreateNewToken"));
const ResetPassword = lazy(() => import("../containers/ResetPassword"));

function MainRoutes() {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();

  const localStorageUser = !!getUser() && JSON.parse(getUser());

  const currentUser = user || localStorageUser;

  const {
    isFetching: isFetchingClientFetching,
    isLoading: isLoadingClientSubscriptionData,
    data: subscriptionData,
  } = useQuery(
    [CLIENT_SUBSCRIPTION_DATA],
    async () => {
      try {
        const data = await getSalonSubscription();

        return data;
      } catch (error) {
        logError({
          methmodName: "getSalonSubscription",
          file: "MainLayout.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: !!currentUser,
    }
  );

  const isSubscriptionActive =
    !!subscriptionData && subscriptionData?.hasActiveSubscription;

  const defaultRoute = !!isSubscriptionActive
    ? getDefaultRouteBasedOnPermission(currentUser?.scopes)
    : "renew-membership";

  return (
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <Routes>
            <Route
              index
              element={
                currentUser ? (
                  <Navigate to={defaultRoute} replace />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route element={<PublicRouteLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="create-password" element={<CreatePassword />} />
              <Route path="accept-invitation" element={<AcceptInvitation />} />
              <Route path="create-new-token" element={<CreateNewToken />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>

            {!isSubscriptionActive
              ? RENEW_SUBSCRIPTION_ROUTES.map((route) => {
                  const {
                    path,
                    element: Element,
                    roles,
                    layout: Layout,
                  } = route;
                  return (
                    <Route
                      key={path}
                      path={path}
                      roles={roles}
                      element={
                        <Layout>
                          <Element />
                        </Layout>
                      }
                    />
                  );
                })
              : SYSTEM_ROUTES.map((route) => {
                  const {
                    path,
                    element: Element,
                    layout: Layout,
                    roles,
                    scopes,
                  } = route;
                  return (
                    <Route
                      key={path}
                      path={path}
                      roles={roles}
                      element={
                        <Suspense fallback={<Loader />}>
                          <ProtectedRoute roles={roles} scopes={scopes}>
                            <Layout>
                              <Element />
                            </Layout>
                          </ProtectedRoute>
                        </Suspense>
                      }
                    />
                  );
                })}

            <Route
              path="/no-permission"
              element={<p>There's No Permission, Contact Administrator</p>}
            />

            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </MainLayout>
      </Suspense>
    </Router>
  );
}

export default MainRoutes;
