import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import "yup-phone-lite";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";

import CircularProgressWithLabel from "../CircularProgressWithLabel/CircularProgressWithLabel";
import WizardIntroHOC from "./components/WizardIntroHOC";
import WizardSalonProfile from "./components/WizardSalonProfile";
import WizardSalonServices from "./components/WizardSalonServices";
import WizardSalonMembers from "./components/WizardSalonMembers";
import WizardGallery from "./components/WizardGallery";
import WizardShareLink from "./components/WizardShareLink";
import {
  CLIENT_DATA_REDUCER_NAME,
  CLIENT_SERVICES_DATA_REDUCER_NAME,
  LIST_MEMBERS_ALL_REDUCER_NAME,
  SYSTEM_DATA_MEMBERS_TYPES_REDUCER_NAME,
  SYSTEM_DATA_SECTIONS_REDUCER_NAME,
} from "../../constants";
import { logError } from "../../services/LogError";
import {
  editSalonProfile,
  uploadSalonCoverOrThumbnail,
} from "../../../containers/EditSalon/api";
import { fetchAllMembertypes, fetchAllSections } from "../../api/systemAPI";
import useCustomSnackbar from "../Snackbar/useSnackbar";
import { COUNTRY_CODES } from "../../countryCodes";

function getEmptyPromise() {
  return new Promise((resolve, reject) => {
    // This promise does not perform any asynchronous operation.
    // You can simply call resolve() to fulfill the promise.
    resolve();
  });
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DAY_OF_WEEK = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

const styles = {
  dialogActionStyle: {
    borderTop: "1px solid #D9D9D9",
    paddingY: 2,
    paddingX: 4,
  },
};

const STEP_NUMBER = 7;
const ACTUAL_STEP_NUMBER = 5;

function Wizard(props) {
  const { handleCloseWizard = () => {} } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const snackbar = useCustomSnackbar();

  const clientData = queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME]);

  const [open, setOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    coverImage: "",
    profileImage: "",
    phoneNumber: "",
    profileNameAr: "",
    profileName: "",
    from: "",
    to: "",
    sections: [],
    memberType: [],
    workingDays: [],
    services: [],
  };

  const validationSchema = [
    //validation for step0
    yup.object({}),
    //validation for step1
    yup.object({}),
    //validation for step2
    yup.object({
      phoneNumber: yup
        .string()
        .phone(COUNTRY_CODES, t("form_error_phone_number"))
        .required(t("form_error_required")),
      profileName: yup.string().required(t("form_required")),
      profileNameAr: yup.string().required(t("form_required")),
      sections: yup
        .array()
        .of(yup.string())
        .min(1, t("form_at_least_1"))
        .required(t("form_required")),
      memberType: yup
        .array()
        .of(yup.string())
        .min(1, t("form_at_least_1"))
        .required(t("form_required")),
      workingDays: yup
        .array()
        .of(yup.string())
        .min(1, t("form_at_least_1"))
        .required(t("form_required")),
      from: yup.string().required(t("form_required")),
      to: yup.string().required(t("form_required")),
      profileImage: yup
        .mixed()
        .required("form_required")
        .test("is-valid-type", t("form_required"), (value) => {
          return !!value;
        }),
      coverImage: yup
        .mixed()
        .required("form_required")
        .test("is-valid-type", t("form_required"), (value) => {
          return !!value;
        }),
    }),
    //validation for services step3
    yup.object({
      //   services: yup
      //     .array()
      //     .of(yup.string())
      //     .min(1, t("form_at_least_1"))
      //     .required(t("form_required")),
    }),
    //validation for members step4
    yup.object({}),
    //validation for gallery step5
    yup.object({}),
    //validation for step6 share link
    yup.object({}),
  ];

  const currentValidationSchema = validationSchema[activeStep];
  const methods = useForm({
    shouldUnregister: false,
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const {
    isFetching: isFetchingSections,
    isLoading: isLoadingSections,
    data: sections,
  } = useQuery(
    [SYSTEM_DATA_SECTIONS_REDUCER_NAME],
    async () => {
      try {
        const data = await fetchAllSections();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchAllSections",
          file: "common/components/Wizard.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: true,
    }
  );

  const {
    isFetching: isFetchingMembersTypesArr,
    isLoading: isLoadingMembersTypesArr,
    data: membersTypesArr,
  } = useQuery(
    [SYSTEM_DATA_MEMBERS_TYPES_REDUCER_NAME],
    async () => {
      try {
        const data = await fetchAllMembertypes();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchAllMembertypes",
          file: "common/components/Wizard.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: true,
    }
  );

  function getStepTitle(activeStep) {
    switch (activeStep) {
      case 0:
        return "";
      case 1:
        return "";
      case 2:
        return t("salon_info");
      case 3:
        return t("salon_services");
      case 4:
        return t("salon_members");
      case 5:
        return t("salon_gallery");
      case 6:
        return t("wizard_share_link");
      default:
        return "Unknown step";
    }
  }

  function getStepContent(activeStep) {
    switch (activeStep) {
      case 0:
        return <WizardIntroHOC activeStep={activeStep} />;
      case 1:
        return <WizardIntroHOC activeStep={activeStep} />;
      case 2:
        return <WizardSalonProfile errors={errors} control={control} />;
      case 3:
        return <WizardSalonServices errors={errors} />;
      case 4:
        return <WizardSalonMembers />;
      case 5:
        return <WizardGallery />;
      case 6:
        return <WizardShareLink />;
      default:
        return "Unknown step";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === STEP_NUMBER) {
        return STEP_NUMBER;
      }
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        return 0;
      }
      return prevActiveStep - 1;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadThumbnailOrCover = (image, imgType = "thumbnail") => {
    let photoName = "";
    const formData = new FormData();

    try {
      let source = { uri: image.path };

      const ext = image.mime.split("/")[1];
      photoName = `salon-${
        imgType === "cover" ? "coverImage" : "thumbnail"
      }-image.${ext}`;
    } catch (error) {
      photoName = `salon-${
        imgType === "cover" ? "coverImage" : "thumbnail"
      }-image`;
    }
    formData.append("file", image, photoName);

    const uploadConfig = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const imgTypeParam = imgType === "thumbnail" ? "logo" : "cover";

    return uploadSalonCoverOrThumbnail(imgTypeParam, formData, uploadConfig);
  };

  const onSubmit = async (data) => {
    if (activeStep === 2) {
      try {
        setIsLoading(true);

        const {
          workingDays,
          phoneNumber,
          profileName,
          profileNameAr,
          from: startTime,
          to: endTime,
          sections: selectedSections,
          memberType,
          coverImage,
          profileImage,
        } = data;

        const excludedDays = DAY_OF_WEEK.filter(
          (day) => !workingDays?.includes(day)
        );
        const startTimeHour = moment(startTime, "hh:mm A").hours();
        const startTimeMinute = moment(startTime, "hh:mm A").minutes();
        const endTimeHour = moment(endTime, "hh:mm A").hours();
        const endTimeMinute = moment(endTime, "hh:mm A").minutes();

        const sectionFormatted = selectedSections?.map((section) => {
          return sections.find((sectionArr) => sectionArr.name === section)?.id;
        });

        const memberTypesFormatted = memberType?.map((member) => {
          return membersTypesArr.find((m) => m.name === member)?.id;
        });

        const salonInfo = {
          address: clientData.address,
          openingTime: {
            startTime: {
              hour: startTimeHour,
              minute: startTimeMinute,
            },
            exludedDays: excludedDays,
            endTime: {
              hour: endTimeHour,
              minute: endTimeMinute,
            },
            type: "Scheduled",
          },
          phoneNumbers: [phoneNumber],
          profiles: [
            {
              language: "en",
              name: profileName,
              termsAndConditions: "",
            },
            {
              language: "ar",
              name: profileNameAr,
              termsAndConditions: "",
            },
          ],
          lockoutPeriod: clientData.lockoutPeriod,
          memberTypes: memberTypesFormatted,
          sections: sectionFormatted,
        };

        const promiseOne = editSalonProfile(salonInfo);

        const promiseProfileImage =
          profileImage instanceof Blob
            ? handleUploadThumbnailOrCover(profileImage, "thumbnail")
            : getEmptyPromise();
        const promiseCoverImage =
          coverImage instanceof Blob
            ? handleUploadThumbnailOrCover(coverImage, "cover")
            : getEmptyPromise();

        const results = await Promise.all([
          promiseOne,
          promiseCoverImage,
          promiseProfileImage,
        ]);

        const settings = clientData.settings;
        let mergedResults = {
          ...results[0],
          coverImage: !!results[1] ? results[1]?.url : results[0].coverImage,
          thumbnail: !!results[2] ? results[2]?.url : results[0].thumbnail,
          settings,
        };

        queryClient.setQueryData([CLIENT_DATA_REDUCER_NAME], mergedResults);

        return handleNext();
      } catch (error) {
        return logError({
          methmodName: "fetchClientServices",
          file: "common/components/Wizard.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    } else if (activeStep === 3) {
      const clientServices = queryClient.getQueryData([
        CLIENT_SERVICES_DATA_REDUCER_NAME,
      ]);

      if (
        !clientServices ||
        !Array.isArray(clientServices) ||
        clientServices.length === 0
      ) {
        return snackbar.showError(t("wizard_services_error"));
      }
      return handleNext();
    } else if (activeStep === STEP_NUMBER - 1) {
      handleCloseWizard();
      return queryClient.invalidateQueries([LIST_MEMBERS_ALL_REDUCER_NAME]);
    }
    handleNext();
  };

  useEffect(() => {
    const {
      coverImage,
      thumbnail,
      phoneNumbers,
      profiles,
      sections,
      memberTypes,
      openingTime,
    } = clientData;
    const { exludedDays, startTime, endTime } = openingTime || {};
    const proAr =
      profiles[0].language === "ar" ? profiles[0].name : profiles[1].name;
    const proEn =
      profiles[0].language === "en" ? profiles[0].name : profiles[1].name;

    const workingDays = DAY_OF_WEEK.filter(
      (day) => !!exludedDays && !exludedDays?.includes(day)
    );
    const from = moment()
      .set({
        hour: startTime?.hour,
        minute: startTime?.minute,
      })
      .format("hh:mm A");
    const to = moment()
      .set({
        hour: endTime?.hour,
        minute: endTime?.minute,
      })
      .format("hh:mm A");

    // remove whitespace from phonenumbers
    const formattedPhoneNumber = phoneNumbers?.map((o) => o.replace(/\s/g, ""));

    reset({
      coverImage: coverImage ?? "",
      profileImage: thumbnail ?? "",
      phoneNumber:
        !!formattedPhoneNumber &&
        Array.isArray(formattedPhoneNumber) &&
        formattedPhoneNumber.length > 0
          ? formattedPhoneNumber[0]
          : "",
      profileNameAr: proAr ?? "",
      profileName: proEn ?? "",
      from: from ?? "",
      to: to ?? "",
      sections: sections?.map((sec) => sec.name) ?? [],
      memberType: memberTypes?.map((mem) => mem.name) ?? [],
      workingDays: workingDays ?? [],
    });
  }, [clientData]);

  const { dialogActionStyle } = styles;

  const MODAL_PERCENTAGE_HEIGHT = 0.75;
  const dialogStyles = {
    minHeight: { xs: window.innerHeight * MODAL_PERCENTAGE_HEIGHT },
    height: "100%",
  };
  const dialogContentStyle = {
    height: "100%",
    minHeight: { xs: window.innerHeight * MODAL_PERCENTAGE_HEIGHT },
    display: "flex",
    flexDirection: "column",
    padding: 0,
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"lg"}
        fullWidth
        sx={dialogStyles}
      >
        <FormProvider {...methods}>
          <form>
            <DialogTitle
              borderBottom={"1px solid #D9D9D9"}
              justifyContent={"space-between"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              paddingX={matchesSmUp ? 4 : 0}
              bgcolor={"#EEF1F4"}
            >
              <Typography variant="h5" fontWeight={900} color={"primary"}>
                {getStepTitle(activeStep)}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent sx={dialogContentStyle}>
              {getStepContent(activeStep)}
            </DialogContent>
            <DialogActions sx={dialogActionStyle}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                flexGrow={1}
                justifyContent={"space-between"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  gap={2}
                >
                  {activeStep > 1 && (
                    <>
                      <CircularProgressWithLabel
                        value={((activeStep - 1) / ACTUAL_STEP_NUMBER) * 100}
                        isFinalStep={activeStep - 1 === ACTUAL_STEP_NUMBER}
                      />
                      <Typography fontSize={12}>
                        {t("required_info")}
                      </Typography>
                    </>
                  )}
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={activeStep > 2 ? "flex-start" : "flex-end"}
                  gap={2}
                >
                  <Button
                    variant="text"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {t("prev")}
                  </Button>
                  <LoadingButton
                    variant="contained"
                    endIcon={
                      activeStep ===
                      STEP_NUMBER - 1 ? undefined : theme.direction ===
                        "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )
                    }
                    onClick={handleSubmit(onSubmit)}
                    loading={isLoading}
                    loadingPosition="end"
                  >
                    {activeStep === STEP_NUMBER - 1 ? t("done") : t("next")}
                  </LoadingButton>
                </Stack>
              </Stack>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  );
}

export default Wizard;
