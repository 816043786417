import moment from "moment";

export const formatAppointmentsListResponse = (response) => {
  return {
    ...response,
    content: response.content.map((item) => {
      return {
        name:
          !!item.user.firstname && !!item.user.lastname
            ? `${item.user.firstname} ${item.user.lastname}`
            : `${item.user.displayName}`,
        time: moment(item.startTime).format("DD MMM, YYYY - hh:mm A"),
        color: item.color,
        id: item.id,
        avatar: item.user.imageUrl,
        totalPriceType: item.totalPriceType,
        status: item.status,
      };
    }),
  };
};
