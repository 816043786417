import { SCOPES } from "../constants";

const ABILITY = {
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
  ALL: "all",
};

const access = {
  admin: {
    subject: "manage",
    action: [ABILITY.READ, ABILITY.UPDATE, ABILITY.DELETE],
  },
  member: {
    subject: [SCOPES.DASHBOARD, SCOPES.NOTIFICATION],
    action: [ABILITY.READ, ABILITY.UPDATE, ABILITY.DELETE],
  },
};

export default access;
