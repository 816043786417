import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

import { logError } from "../../../common/services/LogError";
import * as API from "../api";
import useCustomSnackbar from "../../../common/components/Snackbar/useSnackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  dialogRootStyle: {
    ".MuiPaper-root": {
      paddingX: 10,
      paddingY: 3,
      borderRadius: 2,
    },
  },
  iconStyle: { width: 60, height: 60 },
  dialogActionStyle: { justifyContent: "center", paddingX: 2 },
  flexTriple: { flex: 3 },
  flexDouble: { flex: 2 },
};

function RemoveMemberDialog(props) {
  const {
    isOpen,
    handleClose = () => {},
    name = "",
    userId,
    disableNavigation = false,
  } = props;
  const { t } = useTranslation();
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveMember = async () => {
    try {
      setIsLoading(true);
      const result = await API.deleteMember(userId);
      snackbar.showSuccess(t("delete_member_success_message"));
      handleClose();
      !disableNavigation && navigate(-1);
    } catch (error) {
      logError({
        methmodName: "handleRemoveMember",
        file: "MemberProfile/components/RemoveMemberDialog.js",
        error: error,
      });
      snackbar.showError(t("delete_member_fail_message"));
    } finally {
      setIsLoading(false);
    }
  };

  const {
    dialogRootStyle,
    iconStyle,
    dialogActionStyle,
    flexDouble,
    flexTriple,
  } = styles;
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={dialogRootStyle}
      maxWidth="lg"
    >
      <DialogTitle display={"flex"} justifyContent={"center"}>
        <HelpOutlineIcon sx={iconStyle} color="error" />
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight={700} fontSize={22}>
          {t("remove_name_from_staff_members", { name })}
        </Typography>
      </DialogContent>
      <DialogActions sx={dialogActionStyle}>
        <LoadingButton
          onClick={handleRemoveMember}
          loading={isLoading}
          variant="contained"
          color="error"
          sx={flexTriple}
        >
          {t("remove_staff_member")}
        </LoadingButton>
        <Button onClick={handleClose} variant="outlined" sx={flexDouble}>
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveMemberDialog;
