import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { nanoid } from "nanoid";
import { InputAdornment, TextField as MuiTextField } from "@mui/material";
import get from "lodash/get";

function TextField(props) {
  const {
    name,
    label,
    inputProps,
    id = nanoid(),
    gutterBottom,
    icon,
    endIcon,
    placeholder,
    hint,
    disabled,
    ...rest
  } = props;

  const methods = useFormContext();
  const {
    setValue,
    control,
    trigger,
    formState: { errors },
  } = methods;

  const errorMessage = React.useMemo(() => {
    const fieldError = get(errors, name, null);
    if (fieldError) {
      return fieldError.message;
    }
    return null;
  }, [name, errors]);

  useEffect(() => {
    if (rest.defaultValue) {
      setValue(name, rest.defaultValue);
    }
  }, [rest.defaultValue]);

  // Register Component as form Field

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <MuiTextField
            label={label}
            id={id}
            placeholder={placeholder}
            variant="outlined"
            error={Boolean(errorMessage)}
            helperText={errorMessage || hint}
            disabled={disabled}
            InputProps={{
              "aria-invalid": Boolean(errorMessage),
              inputProps: inputProps,
              startAdornment: icon ? (
                <InputAdornment position="end">{icon}</InputAdornment>
              ) : null,
              endAdornment: endIcon ? (
                <InputAdornment position="end">{endIcon}</InputAdornment>
              ) : null,
            }}
            {...rest}
            {...field}
            onChange={(e) => {
              rest.onChange && rest.onChange(e);
              rest.onChange && trigger(name);
              field.onChange(e);
            }}
            inputRef={field.ref}
          />
        </>
      )}
      {...rest}
    />
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  gutterBottom: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  hint: PropTypes.string,
  placeholder: PropTypes.string,
};

TextField.defaultProps = {
  label: "",
  gutterBottom: false,
  inputProps: {},
  hint: null,
  placeholder: "",
};

export default TextField;
