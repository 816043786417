import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "@emotion/styled";
import { fetchNotification, seenNotifications } from "../api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Stack from "@mui/material/Stack";
import moment from "moment";
import arLocale from "moment/locale/ar";
import enLocale from "moment/locale/en-gb";
import { useNavigate } from "react-router-dom";

import { useLanguageContext } from "../../../common/Language/useLanguageContext";
import {
  NOTIFICATION,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_TYPES,
  TABS_VALUES,
} from "../constants";
import { NOTIFICATION_COUNT } from "../../../common/constants";
import { logError } from "../../../common/services/LogError";

function Notification(props) {
  const { onChangeTab } = props;

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { language } = useLanguageContext();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const notificationCount = queryClient.getQueryData([NOTIFICATION_COUNT]);
  moment.locale(language, [language === "ar" ? arLocale : enLocale]); // So, you can pass in 'en', 'fr', or 'es' as the first argument. In this case, it's 'fr'

  const {
    isFetching: isFetchingNotification,
    isLoading: isLoadingNotification,
    data: notifications = { content: [], totalElements: 0 },
  } = useQuery([NOTIFICATION], async () => {
    try {
      const data = await fetchNotification({ page: page, limit: 10 });
      return data;
    } catch (error) {
      logError({
        methmodName: "fetchNotification",
        file: "containers/Notification/components/Notification.jsx",
        error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
      });
    }
  });

  const { content, totalElements } = notifications;

  const formattedNotifications = useMemo(() => {
    try {
      let obj = {};
      for (let index = 0; index < content.length; index++) {
        const notification = content[index];
        const notificationDate = moment(notification.sendDate).format(
          "YYYY-MM-DD"
        );

        obj[notificationDate] = content.filter((singleNot, i) => {
          if (
            moment(notificationDate, "YYYY-MM-DD").isSame(
              moment(singleNot.sendDate, "YYYY-MM-DD"),
              "day"
            )
          ) {
            return singleNot;
          }
        });
      }

      return obj;
    } catch (e) {
      logError({
        methodName: "formattedNotifications",
        file: "Notification.jsx",
        error: e,
      });
    }
  }, [notifications]);

  const renderTitle = useCallback((date) => {
    if (moment(date).isSame(moment())) {
      return t("today");
    } else if (moment(date).isSame(moment().subtract(1, "days"))) {
      return t("yesterday");
    }

    return moment(date).format("DD/MM/YYYY");
  }, []);

  const fetchMore = async () => {
    try {
      const newPage = page + 1;
      const data = await fetchNotification({
        page: newPage,
        limit: 10,
      });
      queryClient.setQueryData([NOTIFICATION], {
        totalElements: notifications.totalElements,
        content: [...notifications.content, ...data.content],
      });
      setPage(newPage);
    } catch (e) {
      logError({
        methodName: "fetchMore",
        file: "Notification.jsx",
        error: e,
      });
    }
  };
  const onNotificationClick = useCallback(
    (notification) => {
      // convert all if else if to switch
      switch (notification.action) {
        case NOTIFICATION_ACTIONS.REMINDER_APPOINTMENTS_TODAY:
          onChangeTab("", TABS_VALUES.PENDING_APPOINTMENTS);
          break;
        case NOTIFICATION_ACTIONS.REMINDER_APPOINTMENTS_UNCOMPETED:
          onChangeTab("", TABS_VALUES.COMPLETED_APPOINTMENTS);
          break;
        case NOTIFICATION_ACTIONS.CLIENT_ONLINE_PAYMENT_FOR_TRANSACTION:
          return navigate(
            `/view-payment-link?id=${notification?.data?.paymentLinkId}`
          );
        case NOTIFICATION_ACTIONS.CLIENT_USER_APPROVE_RESCHEDULE_ONLINE_BOOKING:
        case NOTIFICATION_ACTIONS.CLIENT_USER_REJECT_RESCHEDULE_ONLINE_BOOKING:
        case NOTIFICATION_ACTIONS.CLIENT_USER_ONLINE_BOOKING:
        case NOTIFICATION_ACTIONS.CLIENT_USER_DELETE_ONLINE_BOOKING:
        case NOTIFICATION_ACTIONS.CLIENT_USER_UPDATE_ONLINE_BOOKING:
          return navigate("/my-calendar", {
            state: {
              appointmentId: notification?.data?.appointmentId,
              date: moment(notification?.data?.startTime)
                .locale("en")
                .format("YYYY-MM-DD"),
            },
          });
        case NOTIFICATION_ACTIONS.CLIENT_WHATSAPP_APPOINTMENT_REMINDER:
          return navigate(
            `/whatsapp-reminders?remindersGroupId=${notification?.data?.remindersGroupId}`
          );
        default:
          break;
      }
    },
    [onChangeTab]
  );
  const setSeenNotification = useCallback(async () => {
    await seenNotifications();
    queryClient.invalidateQueries([NOTIFICATION_COUNT]);
  }, []);

  useEffect(() => {
    return () => {
      if (notificationCount?.generalNotificationCount > 0) {
        setSeenNotification();
      }
    };
  }, []);

  return (
    <>
      <Paper sx={styles.root}>
        <InfiniteScroll
          dataLength={page * 10}
          next={fetchMore}
          hasMore={content.length < totalElements}
          height={window.innerHeight * 0.8}
          scrollableTarget="scrollableDiv"
          loader={
            <Stack direction="row" justifyContent={"center"}>
              <CircularProgress />
            </Stack>
          }
        >
          <Stack px={2} py={2} gap={3}>
            {!isLoadingNotification &&
            Object.keys(formattedNotifications).length === 0 ? (
              <Box
                fontSize={"24px"}
                fontWeight={400}
                py={8}
                display="flex"
                justifyContent="center"
              >
                {t("there_is_no_notifications")}
              </Box>
            ) : (
              Object.keys(formattedNotifications).map((key) => {
                return (
                  <Stack gap={2} key={key}>
                    <Typography sx={styles.title}>
                      {renderTitle(key)}
                    </Typography>
                    {formattedNotifications[key].map((item) => {
                      const Icon = NOTIFICATION_TYPES[item.action].icon;
                      const color = NOTIFICATION_TYPES[item.action].color;
                      return (
                        <Stack
                          key={item.id}
                          direction={"row"}
                          onClick={() => onNotificationClick(item)}
                          sx={styles.notificationWrapperStyle}
                        >
                          <IconWrapper color={color}>
                            <Icon sx={styles.icon} />
                          </IconWrapper>
                          <Stack flexGrow={1}>
                            <Typography sx={styles.title}>
                              {item.title}
                            </Typography>
                            <Typography sx={styles.messageBody}>
                              {item.body}
                            </Typography>
                          </Stack>
                          <Typography
                            alignSelf={"flex-end"}
                            color={"#858585"}
                            fontSize={14}
                          >
                            {moment(item.sendDate).locale(language).fromNow()}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                );
              })
            )}
          </Stack>
        </InfiniteScroll>
      </Paper>
    </>
  );
}

const styles = {
  root: {},
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: "700",
    fontSize: "15px",
    textTransform: "capitalize",
    color: "#000000",
  },
  messageBody: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#000000",
  },
  icon: {
    color: "#fff",
  },
  notificationWrapperStyle: {
    cursor: "pointer",
  },
};

const IconWrapper = styled("div")((props) => ({
  height: "40px",
  width: "40px",
  display: "flex",
  borderRadius: "4px",
  justifyContent: "center",
  alignItems: "center",
  background: props.color,
  color: "fff",
  marginInlineEnd: 10,
}));

export default Notification;
