import httpClient from "../../http/HttpClient";

export const getGalleryPaginated = (payload) =>
  httpClient.get(`/file/client/gallery/posts`).withParams(payload).send();

export const editSalonService = ({ id, payload }) =>
  httpClient.put(`/client/service/${id}`).withData(payload).send();

export const getSalonLocation = (payload) =>
  httpClient.put(`/client/location`).withData(payload).send();

export const uploadSalonCoverOrThumbnail = (imgType, payload, headers) =>
  httpClient
    .post(`/client/${imgType}/upload`)
    .withData(payload)
    .withHeaders({
      "content-type": "multipart/form-data",
    })
    .send();

export const editSalonProfile = (payload) =>
  httpClient.put(`/client/`).withData(payload).send();

export const uploadGalleryPost = (payload) =>
  httpClient.post(`/file/client/gallery/post/`).withData(payload).send();

export const deleteGalleryPost = (id) =>
  httpClient.delete(`/file/client/gallery/post/${id}`).send();
