import React from "react";
import { FormHelperText, Stack, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimeField } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";

const styles = {
  timePickerErrorStyle: {
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C3281C",
      "&:hover": {
        borderColor: "#C3281C",
      },
    },
    timePickerStyle: {},
  },
};

function TimePickerComponent(props) {
  const { name, defaultValue } = props;
  const { control } = useFormContext();
  const { timePickerErrorStyle, timePickerStyle } = styles;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const handleTimeChange = (newTime) => {
          onChange(newTime);
        };
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              <TimeField
                control={control}
                ampm={false}
                format={"hh"}
                value={value}
                sx={!!error ? timePickerErrorStyle : timePickerStyle}
                onChange={(newValue) => {
                  handleTimeChange(newValue);
                }}
              />
              <Typography>:</Typography>
              <TimeField
                control={control}
                ampm={false}
                format={"mm"}
                value={value}
                sx={!!error ? timePickerErrorStyle : timePickerStyle}
                onChange={(newValue) => {
                  handleTimeChange(newValue);
                }}
              />
              <TimeField
                control={control}
                format={"a"}
                value={value}
                sx={!!error ? timePickerErrorStyle : timePickerStyle}
                onChange={(newValue) => {
                  handleTimeChange(newValue);
                }}
              />
            </Stack>

            {!!error?.message && (
              <FormHelperText error>{error?.message}</FormHelperText>
            )}
          </LocalizationProvider>
        );
      }}
    />
  );
}

export default TimePickerComponent;
