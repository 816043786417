import { useContext } from "react";
import SettingsContext from "./SettingsContext";

export const useSettingsContext = () => {
  const settings = useContext(SettingsContext);
  if (!settings) {
    throw new Error(
      "useSettingsContext can only be used inside SettingsProvider"
    );
  }
  return settings;
};
