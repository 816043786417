import React from "react";
import ProfileUploadSection from "./StepOne/ProfileUploadSection";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useQueryClient } from "@tanstack/react-query";
import Typography from "@mui/material/Typography";
import moment from "moment";

import PhoneNumber from "../../../Form/Field/PhoneNumber";
import MuiTextField from "../../../Form/Field/TextField";
import {
  SYSTEM_DATA_MEMBERS_TYPES_REDUCER_NAME,
  SYSTEM_DATA_SECTIONS_REDUCER_NAME,
} from "../../../constants";
import SectionDropdown from "./StepOne/SectionDropdown";
import ToggleMultipleGroup from "../../../Form/Field/ToggleMultipleGroup";
import TimeRangePicker from "./StepOne/TimeRangePicker";

const styles = {
  iconStyle: {
    width: "48px",
    height: "48px",
    marginRight: 1,
  },
};

function WizardSalonProfile(props) {
  const { errors, control } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const membersTypesArr = queryClient.getQueryData([
    SYSTEM_DATA_MEMBERS_TYPES_REDUCER_NAME,
  ]);
  const sections = queryClient.getQueryData([
    SYSTEM_DATA_SECTIONS_REDUCER_NAME,
  ]);

  const { iconStyle } = styles;

  const daysOfWeek = [
    {
      id: "SUNDAY",
      label: t("SUNDAY"),
      value: "SUNDAY",
    },
    {
      id: "MONDAY",
      label: t("MONDAY"),
      value: "MONDAY",
    },
    {
      id: "TUESDAY",
      label: t("TUESDAY"),
      value: "TUESDAY",
    },
    {
      id: "WEDNESDAY",
      label: t("WEDNESDAY"),
      value: "WEDNESDAY",
    },
    {
      id: "THURSDAY",
      label: t("THURSDAY"),
      value: "THURSDAY",
    },
    {
      id: "FRIDAY",
      label: t("FRIDAY"),
      value: "FRIDAY",
    },
    {
      id: "SATURDAY",
      label: t("SATURDAY"),
      value: "SATURDAY",
    },
  ];

  return (
    <Box>
      <ProfileUploadSection />
      <Grid container spacing={2} paddingX={4} paddingTop={2}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneNumber
                  value={value}
                  onChange={onChange}
                  defaultCountry="ps"
                  placeholder={t("mobile_no")}
                  error={!!errors?.phoneNumber?.message}
                />
              )}
            />

            {!!errors?.phoneNumber?.message && (
              <FormHelperText error>
                {errors?.phoneNumber?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiTextField
            name="profileName"
            label={t("profile_name")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MuiTextField
            name="profileNameAr"
            label={t("profile_name_ar")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionDropdown
            title={t("member_type")}
            options={membersTypesArr}
            control={control}
            name="memberType"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionDropdown
            title={t("sections")}
            options={sections}
            control={control}
            name="sections"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errors?.workingDays?.message}
          >
            <Typography>{t("working_days")}</Typography>
            <ToggleMultipleGroup
              name="workingDays"
              control={control}
              errors={errors}
              options={daysOfWeek}
            />

            {!!errors?.workingDays?.message && (
              <FormHelperText error>
                {t(errors?.workingDays?.message, { name: t("working_days") })}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} marginBottom={4}>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errors?.workingDays?.message}
          >
            <Typography>{t("opening_hours")}</Typography>
            <TimeRangePicker
              startTime={moment().set({
                hour: 8,
                minute: 0,
              })}
              endTime={moment().set({
                hour: 24,
                minute: 0,
              })}
              errors={errors}
            />
            {(!!errors?.from?.message || !!errors?.to?.message) && (
              <FormHelperText error>
                {t(errors?.from?.message || errors?.to?.message)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WizardSalonProfile;
