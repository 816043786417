import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "react-i18next";

const styles = {
  selectStyle: { paddingY: 0.6 },
};

function SectionDropdown(props) {
  const {
    title,
    options = [],
    defaultValue,
    name,
    labelId = "demo-multiple-checkbox-label",
    control,
    errors,
  } = props;

  const { t } = useTranslation();

  const { selectStyle } = styles;
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{title}</InputLabel>
      <Controller
        render={({ field }) => {
          const { value: fieldValues } = field;

          return (
            <Select
              multiple
              labelId={labelId}
              label={title}
              input={<OutlinedInput label={title} />}
              renderValue={(selected) => selected.join(", ")}
              {...field}
              defaultValue={defaultValue}
              size="large"
              sx={selectStyle}
              error={!!errors[name]?.message}
            >
              {options.map((option, index) => {
                const { name, id } = option;
                return (
                  <MenuItem key={`${id}-${index}`} value={name}>
                    <Checkbox checked={fieldValues?.includes(name)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
        name={name}
        control={control}
      />
      {!!errors[name]?.message && (
        <FormHelperText error>
          {t(errors[name]?.message, { name: title })}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SectionDropdown;
