export const MEMBERS_SCOPE_MAPPER = {
  SERVICES: "services_scope",
  SERVICES_ADD: "services_add_scope",
  SERVICES_EDIT: "services_edit_scope",
  SERVICES_DELETE: "services_delete_scope",
  OFFERS: "offers_scope",
  OFFERS_ADD: "offers_add_scope",
  OFFERS_EDIT: "offers_edit_scope",
  OFFERS_PUBLISH: "offers_publish_scope",
  OFFERS_UNPUBLISH: "offers_unpublish_scope",
  OFFERS_SHARE: "offers_share_scope",
  OFFERS_PROMOTION_URL: "offers_promotion_url_scope",
  OFFERS_VIEW_APPOINTMENTS: "offers_view_appointments_scope",
  APPOINTMENTS: "appointments_scope",
  APPOINTMENTS_ADD: "appointments_add_scope",
  APPOINTMENTS_EDIT: "appointments_edit_scope",
  APPOINTMENTS_DELETE: "appointments_delete_scope",
  APPOINTMENTS_ADD_REMOVE_SERVICE: "appointments_add_remove_service_scope",
  APPOINTMENTS_COMPLETE: "appointments_complete_scope",
  APPOINTMENTS_APPROVE: "appointments_approve_scope",
  APPOINTMENTS_REJECT: "appointments_reject_scope",
  APPOINTMENTS_CANCEL: "appointments_cancel_scope",
  DASHBOARD: "dashboard_scope",
  DASHBOARD_TOTAL_APPOINTMENTS: "dashboard_total_appointments_scope",
  DASHBOARD_TOTAL_REVIEWS: "dashboard_total_reviews_scope",
  DASHBOARD_TOTAL_REVENUE: "dashboard_total_revenue_scope",
  DASHBOARD_TOTAL_CUSTOMERS: "dashboard_total_customers_scope",
  DASHBOARD_TOTAL_PROFILE_VISITS: "dashboard_total_profile_visits_scope",
  DASHBOARD_UPCOMING_APPOINTMENTS: "dashboard_upcoming_appointments_scope",
  DASHBOARD_TOP_SERVICES: "dashboard_top_services_scope",
  DASHBOARD_INCOME_BREAKDOWN: "dashboard_income_breakdown_scope",
  CUSTOMERS: "customers_scope",
  CUSTOMERS_ADD: "customers_add_scope",
  CUSTOMERS_EDIT: "customers_edit_scope",
  CUSTOMERS_BLOCK: "customers_block_scope",
  CUSTOMERS_UNBLOCK: "customers_unblock_scope",
  CUSTOMERS_VIEW_APPOINTMENTS: "customers_view_appointments_scope",
  NOTIFICATION: "notification_scope",
  NOTIFICATION_PENDING_APPOINTMENTS: "notification_pending_appointments_scope",
  NOTIFICATION_COMPLETED_APPOINTMENTS:
    "notification_completed_appointments_scope",
  MEMBER_SHIP: "member_ship_scope",
  MEMBER_SHIP_CANCEL: "member_ship_cancel_scope",
  MEMBER_SHIP_RENEW: "member_ship_renew_scope",
  MEMBER_SHIP_UPGRADE: "member_ship_upgrade_scope",
  MEMBER_SHIP_DOWNGRADE: "member_ship_downgrade_scope",
  MEMBER_SHIP_HISTORY: "member_ship_history_scope",
  STAFF_MANAGEMENT: "staff_management_scope",
  FINANCIAL_MANAGEMENT: "finantial_management_scope",
  PAYMENT_MANAGEMENT: "payment_management_scope",
  MARKETING_PROMOTION: "marketing_promotion_scope",
  APPOINTMENTS_HISTORY: "appointments_history_scope",
};

export const MEMBER_SCOPE_EXPLANATION_MAPPER = {
  APPOINTMENTS: "appointement_explanation",
  CUSTOMERS: "customers_explanation",
  MEMBER_SHIP: "membership_explanation",
  NOTIFICATION: "notification_explanation",
  DASHBOARD: "dashboard_explanation",
  OFFERS: "offers_explanation",
  SERVICES: "services_explanation",
  STAFF_MANAGEMENT: "staff_management_scope",
  PAYMENT_MANAGEMENT: "payment_explanation",
  FINANCIAL_MANAGEMENT: "finantial_explanation",
  MARKETING_PROMOTION: "marketing_promotion_explanation",
  APPOINTMENTS_HISTORY: "appointments_history_explanation",
};

export const MEMBER_SCOPE_ARRAY = [
  "APPOINTMENTS",
  "CUSTOMERS",
  "MEMBER_SHIP",
  "NOTIFICATION",
  "DASHBOARD",
  "OFFERS",
  "SERVICES",
  "STAFF_MANAGEMENT",
  "PAYMENT_MANAGEMENT",
  "FINANCIAL_MANAGEMENT",
  "MARKETING_PROMOTION",
  "APPOINTMENTS_HISTORY",
];
