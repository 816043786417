import React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import Box from "@mui/material/Box";

import WizardIntroPageOne from "./WizardIntroPageOne";
import WizardIntroPageTwo from "./WizardIntroPageTwo";

const styles = {
  mobileSteperStyle: {
    justifyContent: "center",
    paddingTop: 2,
    ".MuiMobileStepper-dotActive": {
      width: 24,
      height: 8,
      borderRadius: "99px",
    },
  },
  wrapperStyle: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
};
function WizardIntroHOC(props) {
  const { activeStep = 0 } = props;
  const { mobileSteperStyle, wrapperStyle } = styles;

  if (activeStep > 1) {
    return null;
  }
  return (
    <Box sx={wrapperStyle}>
      {activeStep === 0 && <WizardIntroPageOne />}
      {activeStep === 1 && <WizardIntroPageTwo />}
      <MobileStepper
        variant="dots"
        steps={2}
        position="static"
        activeStep={activeStep}
        sx={mobileSteperStyle}
      />
    </Box>
  );
}

export default WizardIntroHOC;
