import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";

import { ReactComponent as Logo } from ".././../assets/svg/logo.svg";
import MenuTab from "./MenuTab";

const MENU_LIST = "MENU_LIST";

const styles = {
  menuIcon: {
    color: "#687373",
    cursor: "pointer",
  },
  button: {
    width: "100%",
    justifyContent: "start",
    minHeight: "48px",
    padding: "0px 24px",
  },
  activeButton: {
    backgroundColor: "rgb(110,75,135, 0.2)",
  },
};
function Sidebar(props) {
  const { buttons, onChange } = props;
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onClick = useCallback(
    (value) => {
      onChange(undefined, value);
      handleDrawerClose();
    },
    [onChange]
  );

  return (
    <>
      <MenuIcon sx={styles.menuIcon} onClick={handleDrawerOpen} />

      <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
        <Box>
          <Box
            px={2}
            py={3}
            display="flex"
            width="100%"
            justifyContent="center"
          >
            <Logo />
          </Box>
          <Stack mt={3}>
            {buttons.map((link) => {
              const Icon = link.icon;

              return link.type === MENU_LIST ? (
                <Box
                  sx={{
                    ...styles.button,
                    ...(location.pathname === link.value &&
                      styles.activeButton),
                  }}
                  key={link.title}
                >
                  <MenuTab
                    label={link.title}
                    menuItems={link.menuItems}
                    icon={<Icon />}
                    handleDrawerClose={handleDrawerClose}
                  />
                </Box>
              ) : (
                <Button
                  key={link.value}
                  disableFocusRipple={true}
                  disableRipple={true}
                  disableTouchRipple={true}
                  startIcon={<Icon style={{ width: 30 }} />}
                  sx={{
                    ...styles.button,
                    ...(location.pathname === link.value &&
                      styles.activeButton),
                  }}
                  onClick={(e) => onClick(link.value)}
                  label={link.title}
                >
                  {link.title}
                </Button>
              );
            })}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}

export default Sidebar;
