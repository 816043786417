import React, { useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useQueryClient } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";

import useCustomSnackbar from "../Snackbar/useSnackbar";
import { logError } from "../../services/LogError";
import CheckBoxGroup from "../../Form/Field/CheckBoxGroup";
import { LIST_MEMBERS_REDUCER_NAME } from "../../constants";
import { MEMBERS_SCOPE_MAPPER } from "../../../containers/EditMembers/constants";
import { bulkRemoveClientMembers } from "../../api/clientAPI";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MODAL_PERCENTAGE_HEIGHT = 0.75;

const styles = {
  dialogActionStyle: {
    borderTop: "1px solid #D9D9D9",
    paddingY: 2,
    paddingX: 4,
  },
  dialogStyles: {
    minHeight: { xs: window.innerHeight * MODAL_PERCENTAGE_HEIGHT },
    height: "100%",
  },
  dialogContentStyle: {
    height: "100%",
    minHeight: { xs: window.innerHeight * MODAL_PERCENTAGE_HEIGHT },
    display: "flex",
    flexDirection: "column",
    paddingX: 4,
  },
};

function RemoveMembersDialog(props) {
  const { handleCloseWizard = () => {} } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const snackbar = useCustomSnackbar();

  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const members = queryClient.getQueryData([LIST_MEMBERS_REDUCER_NAME]);

  // Validation schema
  const validationSchema = yup.object({
    members: yup.array().min(1).max(3),
  });

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      members: [],
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const memberNotSelectedIDs = members
        .filter((member) => !data.members.includes(member.userId))
        .map((member) => member.userId);

      const body = { memberIds: memberNotSelectedIDs };
      const result = await bulkRemoveClientMembers(body);

      snackbar.showSuccess(t("downgrade_members_success"));

      handleCloseWizard();
      window.location.reload();
    } catch (error) {
      snackbar.showError(error?.response?.data?.message);
      return logError({
        methmodName: "fetchClientServices",
        file: "common/components/RemoveMemberDialog.jsx",
        error: ` path ${error?.response?.data?.path} with error ${error}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {};

  const { dialogStyles, dialogContentStyle, dialogActionStyle } = styles;
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"lg"}
        fullWidth
        sx={dialogStyles}
      >
        <FormProvider {...methods}>
          <form>
            <DialogTitle
              borderBottom={"1px solid #D9D9D9"}
              justifyContent={"space-between"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              paddingX={matchesSmUp ? 4 : 0}
              bgcolor={"#EEF1F4"}
            >
              <Typography variant="h5" fontWeight={900} color={"primary"}>
                {t("downgrade_stripe_title")}
              </Typography>
            </DialogTitle>

            <DialogContent sx={dialogContentStyle}>
              <Typography
                variant="h6"
                fontWeight={900}
                color={"inherit"}
                mt={2}
              >
                {t("downgrade_stripe_subtitle")}
              </Typography>
              <Typography variant="body2" color={"inherit"} mt={2}>
                {t("downgrade_stripe_paragraph_1")}
              </Typography>
              <Typography variant="body2" color={"inherit"} mt={2} mb={3}>
                {t("downgrade_stripe_paragraph_2")}
              </Typography>
              <Box paddingLeft={2}>
                <CheckBoxGroup
                  options={members?.map((member) => {
                    return {
                      value: member.userId,
                      name: `${member.displayName} [${member.email}]`,
                      helperText: member.scopes
                        .map((scope) => t(MEMBERS_SCOPE_MAPPER[scope.value]))
                        .join(", "),
                    };
                  })}
                  control={control}
                  errors={errors}
                  name={"members"}
                  label={""}
                />
              </Box>
              <Typography variant="body2" color={"inherit"} mt={2}>
                {t("downgrade_stripe_paragraph_3")}
              </Typography>
            </DialogContent>
            <DialogActions sx={dialogActionStyle}>
              <LoadingButton
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
                loadingPosition="end"
              >
                {t("done")}
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  );
}

export default RemoveMembersDialog;
