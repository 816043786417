import React from "react";
import {
  usePhoneInput,
  FlagEmoji,
  parseCountry,
  defaultCountries,
} from "react-international-phone";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import "react-international-phone/style.css";
import { useLanguageContext } from "../../Language/useLanguageContext";

const styles = {
  inputAdormentStyle: { marginRight: "2px", marginLeft: "-8px" },
  inputAdormentStyleRTL: { marginRight: "-8px", marginLeft: "2px" },
  flagEmojiStyle: { marginRight: "8px" },
  flexFlagemojiStyle: { display: "flex" },
};

const SELECT_SX_STYLE = {
  width: "max-content",
  fieldset: {
    display: "none",
  },
  '&.Mui-focused:has(div[aria-expanded="false"])': {
    fieldset: {
      display: "block",
    },
  },
  ".MuiSelect-select": {
    padding: "8px",
    paddingRight: "24px !important",
  },
  svg: {
    right: 0,
  },
};

export default function PhoneNumber(props) {
  const { language } = useLanguageContext();

  const {
    value,
    onChange,
    defaultCountry = "ps",
    placeholder,
    error,
    ...restProps
  } = props;

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry,
      value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      },
    });

  const {
    inputAdormentStyle,
    inputAdormentStyleRTL,
    flagEmojiStyle,
    flexFlagemojiStyle,
  } = styles;

  return (
    <TextField
      variant="outlined"
      label={placeholder}
      color="primary"
      placeholder={placeholder}
      value={phone}
      onChange={handlePhoneValueChange}
      error={error}
      type="tel"
      inputRef={inputRef}
      dir={"ltr"}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={
              language === "ar" ? inputAdormentStyleRTL : inputAdormentStyle
            }
          >
            <Select
              dir={"ltr"}
              MenuProps={{
                style: {
                  height: "300px",
                  top: "10px",
                  left: "-34px",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: language === "ar" ? "left" : "left",
                },
              }}
              sx={SELECT_SX_STYLE}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => (
                <FlagEmoji iso2={value} style={flexFlagemojiStyle} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem dir={"ltr"} key={country.iso2} value={country.iso2}>
                    <FlagEmoji iso2={country.iso2} style={flagEmojiStyle} />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
}
