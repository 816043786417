import React, { Suspense, lazy } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const IntroSvg = lazy(async () => ({
  default: (await import("../../../../assets/svg/intro_slider_page_one.svg"))
    .ReactComponent,
}));

function WizardIntroPageOne() {
  const { t } = useTranslation();

  return (
    <Stack
      direction={"column"}
      gap={1}
      flexGrow={1}
      justifyContent={"center"}
      alignItems="center"
    >
      <Stack
        direction={"column"}
        gap={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Suspense fallback={<div></div>}>
          <IntroSvg width={"100%"} height={"100%"} />
        </Suspense>
        <Typography variant="h5" fontWeight={700} color={"primary"}>
          {t("intro_p1_title")}
        </Typography>
        <Typography variant="h6" fontWeight={500} color={"primary"}>
          {t("intro_p1_subtitle")}
        </Typography>
      </Stack>
      <Box marginTop={4}>
        <Typography>{t("intro_p1_paragraph_1")}</Typography>
        <Typography>{t("intro_p1_paragraph_2")}</Typography>
      </Box>
    </Stack>
  );
}

export default WizardIntroPageOne;
