import React from "react";
import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";

function MemberTableSkeleton() {
  return (
    <TableRow>
      <TableCell width={400}>
        <Stack direction={"row"} alignItems="center" gap={2} paddingRight={2}>
          <Avatar>
            <Skeleton />
          </Avatar>
          <Skeleton height={40} width={300} />
        </Stack>
      </TableCell>
      <TableCell>
        <Skeleton height={40} />
      </TableCell>
      <TableCell>
        <Skeleton height={40} />
      </TableCell>
      <TableCell>
        <Skeleton height={40} />
      </TableCell>
      <TableCell>
        <Skeleton height={40} width={100} />
      </TableCell>
      <TableCell>
        <Skeleton height={40} />
      </TableCell>
    </TableRow>
  );
}

export default MemberTableSkeleton;
