import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import { Controller } from "react-hook-form";
import { useMediaQuery } from "@mui/material";

const styles = {
  oddServiceStyle: {
    backgroundColor: "#FFFFFF",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  evenServiceStyle: {
    backgroundColor: "#F5F4F5",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  oddServiceMobileStyle: {
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    borderRadius: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  evenServiceMobileStyle: {
    backgroundColor: "#F5F4F5",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    borderRadius: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function Service(props) {
  const { index, name, id, enableAddRemove = false, control } = props;

  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleRowClicked = (onChange, value) => {
    onChange(!value);
  };
  const {
    evenServiceStyle,
    oddServiceStyle,
    evenServiceMobileStyle,
    oddServiceMobileStyle,
  } = styles;
  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <CardActionArea
            onClick={() => handleRowClicked(onChange, value)}
            sx={
              index % 2 === 0
                ? isSmallScreen
                  ? evenServiceMobileStyle
                  : evenServiceStyle
                : isSmallScreen
                ? oddServiceMobileStyle
                : oddServiceStyle
            }
          >
            <Typography fontSize={isSmallScreen ? "0.8rem" : "1rem"}>
              {name}
            </Typography>
            {!!enableAddRemove &&
              (!!value ? (
                <Stack
                  direction="row"
                  spacing={isSmallScreen ? 0 : 1}
                  alignItems="center"
                >
                  <Chip
                    avatar={<CheckIcon color="success" />}
                    label={t("selected")}
                    variant="outlined"
                    color="success"
                    size={isSmallScreen ? "small" : "medium"}
                  />

                  <IconButton>
                    <RemoveIcon />
                  </IconButton>
                </Stack>
              ) : (
                <IconButton>
                  <AddIcon />
                </IconButton>
              ))}
          </CardActionArea>
        );
      }}
    />
  );
}

export default Service;
