import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Controller, useFormContext } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

function MultipleSelectWithCheckboxes(props) {
  const handleRenderValue = (selected) => {
    const output = selected.map((selectedItem) => {
      return options.find((option) => option.value === selectedItem)?.name;
    });
    return output.join(", ");
  };

  const { options, title, labelId, name, renderValue= handleRenderValue} = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();



  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{title}</InputLabel>
      <Controller
        render={({ field }) => {
          const { value: fieldValues } = field;

          return (
            <Select
              multiple
              labelId={labelId}
              label={title}
              input={<OutlinedInput label={title} />}
              renderValue={renderValue}
              {...field}
              sx={{ paddingY: 0.6 }}
              error={!!errors[name]?.message}
            >
              {options?.map((option, index) => {
                const { name, id, value } = option;
                return (
                  <MenuItem key={`${id}-${index}`} value={value}>
                    <Checkbox checked={fieldValues?.includes(value)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
        name={name}
        control={control}
      />
      {!!errors[name]?.message && (
        <FormHelperText error>{errors[name]?.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export default MultipleSelectWithCheckboxes;
