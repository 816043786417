import React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { useQueryClient } from "@tanstack/react-query";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { ShareSocial } from "react-share-social";

import { CLIENT_DATA_REDUCER_NAME } from "../../../constants";
import useCustomSnackbar from "../../Snackbar/useSnackbar";

const styles = {
  copyBtnStyle: { minWidth: 150 },
  shareStyle: {
    copyContainer: { padding: 0, display: "none" },
    root: {
      padding: 0,
      border: 0,
    },
  },
};

function WizardShareLink() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const snackbar = useCustomSnackbar();

  const promotionalUrl = queryClient.getQueryData([
    CLIENT_DATA_REDUCER_NAME,
  ])?.promotionalUrl;

  const handleCopy = () => {
    navigator.clipboard.writeText(promotionalUrl);
    snackbar.showInfo(t("copied"));
  };

  const handleOnSocialButtonClicked = () => {};

  const { copyBtnStyle, shareStyle } = styles;
  return (
    <Box>
      <Grid container spacing={3} paddingX={4} paddingTop={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700}>
            {t("wizard_account_is_ready")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography marginBottom={2}>
            {t("wizard_share_link_paragraph")}
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <TextField
              value={promotionalUrl}
              fullWidth
              variant="outlined"
              disabled
            />
            <Button variant="contained" sx={copyBtnStyle} onClick={handleCopy}>
              {t("copy")}
            </Button>
          </Stack>
          <ShareSocial
            url={promotionalUrl}
            socialTypes={["facebook", "whatsapp", "twitter", "linkedin"]}
            style={shareStyle}
            onSocialButtonClicked={handleOnSocialButtonClicked}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default WizardShareLink;
