import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQueryClient } from "@tanstack/react-query";

import MuiTextField from "../../../../Form/Field/TextField";
import MultipleSelectWithCheckboxes from "../../../../Form/Field/MultipleSelectWithCheckboxes";
import { logError } from "../../../../services/LogError";
import useCustomSnackbar from "../../../Snackbar/useSnackbar";
import { GET_SCOPE_LIST } from "../../../../../containers/MemberProfile/constants";
import {
  getScopeList,
  inviteMember,
} from "../../../../../containers/MemberProfile/api";
import { MEMBERS_SCOPE_MAPPER } from "../../../../../containers/EditMembers/constants";
import { ROLES } from "../../../../../routes/constants";
import { LIST_MEMBERS_REDUCER_NAME } from "../../../../constants";

function InviteMember() {
  const { t } = useTranslation();
  const snackbar = useCustomSnackbar();
  const queryClient = useQueryClient();

  const schema = yup.object({
    displayName: yup.string().required(t("form_required")),
    email: yup.string().email(t("form_email")).required(t("form_required")),
    scopes: yup.array().min(1, t("form_at_least_one")),
  });

  const methods = useForm({
    defaultValues: {
      email: "",
      displayName: "",
      scopes: [],
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset } = methods;

  const [isLoading, setIsLoading] = useState(false);

  const {
    isFetching: isScopeListFetching,
    isLoading: isScopeListLoading,
    data: scopeList,
  } = useQuery(
    [GET_SCOPE_LIST],
    async () => {
      try {
        const data = await getScopeList();
        return data.filter((scope) => scope.group === scope.value);
      } catch (error) {
        logError({
          methmodName: "getScopeList",
          file: "containers/Wizard/components/StepThree/InviteMembets.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: true,
    }
  );

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const { scopes, displayName, email } = data;

      const payload = {
        scopes: scopes,
        roles: [ROLES.SALON_EMPLOYEE],
        displayName,
        email,
      };
      const result = await inviteMember(payload);
      reset();
      snackbar.showSuccess(
        t("invite_member_success_message", { email: email })
      );
      queryClient.invalidateQueries([LIST_MEMBERS_REDUCER_NAME]);
    } catch (error) {
      logError({
        methmodName: "updateMember",
        file: "containers/Wizard/components/InviteMember.jsx",
        error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
      });
      snackbar.showError(t("invite_member_fail_message"));
    } finally {
      setIsLoading(false);
    }
  };

  const scopeOptions = scopeList?.map((scope) => {
    return {
      ...scope,
      name: t(MEMBERS_SCOPE_MAPPER[scope.value]),
    };
  });

  return (
    <FormProvider {...methods}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={700}>
              {t("add_staff_member")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiTextField
              name="displayName"
              label={t("staff_member_name")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiTextField
              name="email"
              label={t("staff_member_email")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MultipleSelectWithCheckboxes
              title={t("permissions")}
              name="scopes"
              labelId="scopesOfMembers"
              options={scopeOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              sx={{ paddingY: 1.8 }}
              onClick={handleSubmit(onSubmit)}
              startIcon={<PersonAddAlt1Icon />}
              loading={isLoading}
            >
              {t("add_staff_member")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default InviteMember;
