import * as Sentry from "@sentry/react";

export const logError = ({
  methodName = "",
  file = "",
  params = {},
  error = "",
}) => {
  let msg = "";
  if (methodName) {
    msg += methodName;
  }
  if (file) {
    msg += " --" + file + "--";
  }
  Array.from(Object.keys(params)).forEach((key) => {
    msg += " " + key + "=" + JSON.stringify(params[key]);
  });
  msg += JSON.stringify(error);
  if (process.env.REACT_APP_IS_DEV) {
    console.error(msg);
    return;
  }
  Sentry.captureException(new Error(msg));
};
