import React, { lazy } from "react";

import PrivateRouteLayout from "../common/components/PrivateRouteLayout";
import { SCOPES } from "../common/constants";
import RenewLayout from "../common/components/RenewLayout";

// Lazy Loading
const Dashboard = lazy(() => import("../containers/Dashboard"));
const Calendar = lazy(() => import("../containers/Calendar"));
const Appointments = lazy(() => import("../containers/Appointments"));
const EditSalon = lazy(() => import("../containers/EditSalon"));
const EditMembers = lazy(() => import("../containers/EditMembers"));
const MemberProfile = lazy(() => import("../containers/MemberProfile"));
const MyCustomers = lazy(() => import("../containers/MyCustomers"));
const MyOffers = lazy(() => import("../containers/MyOffers"));
const ViewAppointments = lazy(() => import("../containers/ViewAppointments"));
const Notification = lazy(() => import("../containers/Notification"));
const Subscripton = lazy(() => import("../containers/Subscription"));
const BillingHistory = lazy(() => import("../containers/BillingHistroy"));
const MemberWorkingSchedule = lazy(() =>
  import("../containers/MemberWorkingSchedule")
);
const Settings = lazy(() => import("../containers/Settings"));
const MemberAssignServices = lazy(() =>
  import("../containers/MemberAssignServices")
);
const Payments = lazy(() => import("../containers/Payments"));
const Financials = lazy(() => import("../containers/Financials"));
const FinancialTransactions = lazy(() =>
  import("../containers/FinancialTransactions")
);
const Status = lazy(() => import("../containers/Status"));
const GeneralSettings = lazy(() => import("../containers/GeneralSettings"));
const DeepLinks = lazy(() => import("../containers/DeepLinks"));
const PushNotifications = lazy(() => import("../containers/PushNotification"));
const SendPushNotifications = lazy(() =>
  import("../containers/SendPushNotifications")
);
const MyCoupons = lazy(() => import("../containers/MyCoupons"));
const SendCoupon = lazy(() => import("../containers/SendCoupon"));
const PaymentLinks = lazy(() => import("../containers/PaymentLinks"));
const PaymentLinkViewer = lazy(() => import("../containers/PaymentLinkViewer"));
const SmsMessages = lazy(() => import("../containers/SmsMessages"));
const SendSmsMessages = lazy(() => import("../containers/SendSmsMessages"));
const WhatsappMessages = lazy(() => import("../containers/WhatsappMessages"));
const SendWhatsappMessages = lazy(() =>
  import("../containers/SendWhatsappMessages")
);
const WhatsappReminders = lazy(() => {
  return import("../containers/WhatsappReminders");
});

const SelectAPlan = lazy(() => {
  return import("../containers/SelectAPlan");
});
const RenewMembership = lazy(() => import("../containers/RenewMembership"));

const ROLES = {
  OWNER: "OWNER",
  INFLUENCER: "influencer",
  STORE: "store",
  SALON_EMPLOYEE: "SALON_EMPLOYEE",
};

const SYSTEM_ROUTES = [
  {
    path: "dashboard",
    layout: PrivateRouteLayout,
    element: Dashboard,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.DASHBOARD],
  },
  {
    path: "my-calendar",
    layout: PrivateRouteLayout,
    element: Calendar,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.APPOINTMENTS],
  },
  {
    path: "my-appointments",
    layout: PrivateRouteLayout,
    element: Appointments,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.APPOINTMENTS_HISTORY],
  },
  {
    path: "edit-salon",
    layout: PrivateRouteLayout,
    element: EditSalon,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "manage-members",
    layout: PrivateRouteLayout,
    element: EditMembers,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.STAFF_MANAGEMENT],
  },
  {
    path: "member-profile",
    layout: PrivateRouteLayout,
    element: MemberProfile,
    roles: [ROLES.OWNER],
    scopes: [],
  },
  {
    path: "member-working-schedule",
    layout: PrivateRouteLayout,
    element: MemberWorkingSchedule,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.APPOINTMENTS],
  },
  {
    path: "assign-services",
    layout: PrivateRouteLayout,
    element: MemberAssignServices,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.SERVICES],
  },
  {
    path: "my-customers",
    layout: PrivateRouteLayout,
    element: MyCustomers,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.CUSTOMERS],
  },
  {
    path: "my-offers",
    layout: PrivateRouteLayout,
    element: MyOffers,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.OFFERS],
  },
  {
    path: "view-appointments",
    layout: PrivateRouteLayout,
    element: ViewAppointments,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.APPOINTMENTS],
  },
  {
    path: "notification",
    layout: PrivateRouteLayout,
    element: Notification,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.NOTIFICATION],
  },
  {
    path: "subscription",
    layout: PrivateRouteLayout,
    element: Subscripton,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.MEMBER_SHIP],
  },
  {
    path: "billing-history",
    layout: PrivateRouteLayout,
    element: BillingHistory,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.MEMBER_SHIP],
  },

  {
    path: "settings",
    layout: PrivateRouteLayout,
    element: Settings,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.APPOINTMENTS],
  },
  {
    path: "payments",
    layout: PrivateRouteLayout,
    element: Payments,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.PAYMENTS],
  },
  {
    path: "my-financials",
    layout: PrivateRouteLayout,
    element: Financials,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.FINANCIAL],
  },
  {
    path: "financial-transactions",
    layout: PrivateRouteLayout,
    element: FinancialTransactions,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.FINANCIAL],
  },
  {
    path: "payments",
    layout: PrivateRouteLayout,
    element: Payments,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.PAYMENTS],
  },
  {
    path: "status",
    layout: PrivateRouteLayout,
    element: Status,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "general-settings",
    layout: PrivateRouteLayout,
    element: GeneralSettings,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "deep-links",
    layout: PrivateRouteLayout,
    element: DeepLinks,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.MARKETING_PROMOTION],
  },
  {
    path: "push-notifications",
    layout: PrivateRouteLayout,
    element: PushNotifications,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.MARKETING_PROMOTION],
  },
  {
    path: "send-push-notifications",
    layout: PrivateRouteLayout,
    element: SendPushNotifications,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.MARKETING_PROMOTION],
  },
  {
    path: "my-coupons",
    layout: PrivateRouteLayout,
    element: MyCoupons,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.MARKETING_PROMOTION],
  },
  {
    path: "send-coupons",
    layout: PrivateRouteLayout,
    element: SendCoupon,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.MARKETING_PROMOTION],
  },
  {
    path: "payment-links",
    layout: PrivateRouteLayout,
    element: PaymentLinks,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.PAYMENTS],
  },
  {
    path: "view-payment-link",
    layout: PrivateRouteLayout,
    element: PaymentLinkViewer,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [SCOPES.PAYMENTS],
  },
  {
    path: "mobile-sms",
    layout: PrivateRouteLayout,
    element: SmsMessages,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "send-sms-messages",
    layout: PrivateRouteLayout,
    element: SendSmsMessages,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "whatsapp-messages",
    layout: PrivateRouteLayout,
    element: WhatsappMessages,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "send-whatsapp-messages",
    layout: PrivateRouteLayout,
    element: SendWhatsappMessages,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "whatsapp-reminders",
    layout: PrivateRouteLayout,
    element: WhatsappReminders,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "select-a-plan",
    layout: PrivateRouteLayout,
    element: SelectAPlan,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
];

const RENEW_SUBSCRIPTION_ROUTES = [
  {
    path: "renew-membership",
    layout: RenewLayout,
    element: RenewMembership,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "subscription",
    layout: RenewLayout,
    element: Subscripton,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
  {
    path: "select-a-plan",
    layout: RenewLayout,
    element: SelectAPlan,
    roles: [ROLES.OWNER, ROLES.SALON_EMPLOYEE],
    scopes: [],
  },
];

export { ROLES, SYSTEM_ROUTES, RENEW_SUBSCRIPTION_ROUTES };
