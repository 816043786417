import { logError } from "../services/LogError";

export const roundNumber = (num, scale) => {
  try {
    if (!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = "";
      if (+arr[1] + scale > 0) {
        sig = "+";
      }
      return +(
        Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
        "e-" +
        scale
      );
    }
  } catch {}
};

export const removeFirstMatch = (arr, itemToRemove) => {
  try {
    const index = arr.indexOf(itemToRemove);

    if (index !== -1) {
      return [...arr.slice(0, index), ...arr.slice(index + 1)];
    }
  } catch (error) {
    logError({
      methodName: "removeFirstMatch",
      file: "common/utils/common.js",
      error: error,
    });
    return arr;
  }
};

export const extractCountryCode = (countryObj) => {
  try {
    const countryIndex = countryObj?.id?.lastIndexOf(".");
    return countryObj?.id.substring(countryIndex + 1) || "";
  } catch (error) {
    return "ps";
  }
};