import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const containerStyle = { display: "flex", alignItems: "center" };
const wrapperStyle = { width: "100%", mr: 1 };
const textWrapperStyle = { minWidth: 35 };

const FileUploaderModal = (props) => {
  const { isOpen, handleClose, progressValue = 0 } = props;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      //   onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Box sx={containerStyle}>
            <Box sx={wrapperStyle}>
              <LinearProgress variant="determinate" value={progressValue} />
            </Box>
            <Box sx={textWrapperStyle}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                progressValue || 0
              )}%`}</Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default FileUploaderModal;
