import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { useNavigate, createSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";

import useCustomSnackbar from "../../../Snackbar/useSnackbar";
import { logError } from "../../../../services/LogError";
import RemoveMemberDialog from "../../../../../containers/MemberProfile/components/RemoveMemberDialog";
import { ROLES } from "../../../../../routes/constants";
import { resendInvitationMember } from "../../../../../containers/EditMembers/api";
import {
  LIST_MEMBERS_ALL_REDUCER_NAME,
  LIST_MEMBERS_REDUCER_NAME,
} from "../../../../constants";

const styles = {
  moreIconStyle: { color: "black" },
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.colors.black.solid,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.colors.black.solid,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function MoreMenu(props) {
  const { displayName, userId, roles, inviteStatus } = props;

  const { t } = useTranslation();
  const snackbar = useCustomSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isOwner = roles.includes(ROLES.OWNER);
  const isPendingMember = inviteStatus === "PENDING";

  const handleRemoveDialogClose = () => {
    queryClient.invalidateQueries([LIST_MEMBERS_REDUCER_NAME]);
    queryClient.invalidateQueries([LIST_MEMBERS_ALL_REDUCER_NAME]);
    setIsRemoveDialogOpen(false);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditCustomer = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    // setDefaultCustomerValues({ phoneNumber, displayName, id });
    // handleClose();
    // handleEditCustomerCb();
  };

  const handleRemoveMember = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorEl(null);
    setIsRemoveDialogOpen(true);
  };

  const handleResendInvite = async (event) => {
    try {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      setIsLoading(true);
      const result = await resendInvitationMember(userId);
      snackbar.showSuccess(
        t("resend_pending_member_invitation_success_message")
      );
    } catch (error) {
      logError({
        methmodName: "handleResendInvite",
        file: "containers/Wizard/components/MoreMenu.jsx",
        error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
      });
      snackbar.showError(t("resend_pending_member_invitation_fail_message"));
    } finally {
      setAnchorEl(null);
      setIsLoading(false);
    }
  };

  const { moreIconStyle } = styles;

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        sx={moreIconStyle}
        onClick={handleClick}
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          target="_blank"
          href={`/member-profile?${createSearchParams({
            accountId: userId,
          }).toString()}`}
        >
          <EditIcon />
          {t("edit_member_wizard")}
        </MenuItem>

        {!!isPendingMember && (
          <MenuItem onClick={handleResendInvite} disableRipple>
            {isLoading ? (
              <CircularProgress size={20} color="warning" />
            ) : (
              <ReplayIcon />
            )}
            {t("resend_invite")}
          </MenuItem>
        )}
        {!isOwner && (
          <MenuItem onClick={handleRemoveMember} disableRipple>
            <DeleteIcon />
            {t("remove_member")}
          </MenuItem>
        )}
      </StyledMenu>
      <RemoveMemberDialog
        isOpen={isRemoveDialogOpen}
        handleClose={handleRemoveDialogClose}
        name={displayName}
        userId={userId}
        disableNavigation
      />
    </div>
  );
}

export default MoreMenu;
