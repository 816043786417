import { useState } from "react";
import SettingsContext from "./SettingsContext";

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({ notificationSound: true });

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
