import React, { memo, useMemo, useCallback } from "react";
import Box from "@mui/material/Box";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  START_DAY_HOUR,
  ENG_DAY_HOUR,
} from "../../../../../containers/Calendar/constants";
import { CLIENT_DATA_REDUCER_NAME } from "../../../../constants";
import TimeField from "../../../../Form/Field/TimeField";

function TimeRangePicker(props) {
  const { startTime, endTime, errors } = props;

  const { t } = useTranslation();
  const methods = useFormContext();
  const { watch, setValue } = methods;

  const formWatcher = watch();

  const queryClient = useQueryClient();
  const salon = queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME]);
  const clientData = queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME]);

  const startTimeOptions = useMemo(() => {
    const periodsInADay = moment.duration(1, "day").as("minute");
    const timeLabels = [];
    const startTimeMoment =
      !!startTime && moment(startTime).isValid()
        ? moment(startTime, "hh:mm")
        : clientData.openingTime?.type === "OnDemand"
        ? moment(`${START_DAY_HOUR}:00`, "hh:mm")
        : moment(
            `'${`${salon?.openingTime.startTime?.hour}`}:${`${salon?.openingTime.startTime?.minute}`}'`,
            "hh:mm"
          );

    const endTimeMoment =
      !!endTime && moment(endTime).isValid()
        ? moment(endTime, "hh:mm")
        : clientData.openingTime?.type === "OnDemand"
        ? moment(`${ENG_DAY_HOUR}:00`, "hh:mm")
        : moment(
            `'${`${salon?.openingTime.endTime?.hour}`}:${`${salon?.openingTime.endTime?.minute}`}'`,
            "hh:mm"
          );

    for (let i = 0; i <= periodsInADay; i += 15) {
      const time = startTimeMoment.add(i === 0 ? 0 : 15, "minute");

      if (time.isSame(endTimeMoment)) {
        break;
      }

      timeLabels.push({
        label: time.format("hh:mm A"),
        value: time.format("hh:mm A"),
      });
    }

    return timeLabels.slice(0, -1);
  }, [
    startTime,
    clientData.openingTime?.type,
    salon?.openingTime.startTime?.hour,
    salon?.openingTime.startTime?.minute,
    salon?.openingTime.endTime?.hour,
    salon?.openingTime.endTime?.minute,
    endTime,
  ]);

  const endTimeOptions = useMemo(() => {
    const fromIndex = startTimeOptions.findIndex(
      (row) => row.value === (formWatcher?.from || startTimeOptions[0].value)
    );
    return startTimeOptions.slice(fromIndex + 1, startTimeOptions.length);
  }, [formWatcher, startTimeOptions]);

  const onChangeFrom = useCallback(
    (value) => {
      const fromIndex = startTimeOptions.findIndex(
        (row) => row.value === value
      );
      const momentFrom = moment(value, "hh:mm A");
      const momentTo = moment(formWatcher["to"], "hh:mm A");

      if (momentFrom.isSameOrAfter(momentTo) || !formWatcher["to"]) {
        setValue("to", startTimeOptions[fromIndex + 1].value);
      }
    },
    [formWatcher, setValue, startTimeOptions]
  );

  return (
    <Box sx={style.timeFieldsWrapper}>
      <TimeField
        name="from"
        options={startTimeOptions}
        label={t("from")}
        postChange={onChangeFrom}
        disableClearable={true}
        size="medium"
        hasError={!!errors["from"]?.message}
      />
      <TimeField
        name="to"
        options={endTimeOptions}
        label={t("to")}
        disableClearable={true}
        size="medium"
        hasError={!!errors["to"]?.message}
      />
    </Box>
  );
}
const style = {
  timeFieldsWrapper: {
    display: "flex",
    gap: "12px",
  },
};
export default memo(TimeRangePicker);
