import React, { useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useQueryClient } from "@tanstack/react-query";
import styled from "@emotion/styled";
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  Avatar as MuiAvatar,
  Hidden,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LogoutIcon from "@mui/icons-material/Logout";

import Footer from "../../common/components/Footer/Footer";
import {
  CLIENT_DATA_REDUCER_NAME,
  MEMBER_DATA_REDUCER_NAME,
  SCOPES,
} from "../../common/constants";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";

import { useLanguageContext } from "../../common/Language/useLanguageContext";
import { useAuthContext } from "../../common/Auth/useAuthContext";
import { deleteUser } from "../../common/Auth/utils";
import { intersectionScopes } from "../../routes/utils";
import LanguageDialog from "../../common/components/LanguagesDialog/LanguageDialog";

const styles = {
  containerStyle: { flexGrow: 1, minHeight: "100vh", overflow: "hidden" },
  navbar: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "16px 32px",
    justifyContent: "space-between",
  },
  leftArea: {
    display: "flex",
    alignItems: "center",
  },
  rightArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "4px",
  },
  optionWrapper: {
    display: "flex",
    gap: "12px",
  },
  menuPaper: {
    minWidth: "25ch",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 30,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  notificationIcon: {
    color: "#687373",
    cursor: "pointer",
  },
  activeIcon: {
    color: "#6E4B87",
  },
  notificationBadge: {
    "& .MuiBadge-badge": { background: "#D9534F", color: "#fff" },
  },
  menuIcon: {
    color: "#6E4B87",
    cursor: "pointer",
  },
  avatar: { marginInlineEnd: 1.5 },
  menuWrapper: {
    ":hover": {
      background: "inherit",
    },
  },
  tabsStyle: {
    overflow: "visible",
    ".MuiTabs-scroller	": { overflow: "visible !important" },
    ".MuiTabs-flexContainer	": { gap: 1 },
    display: "flex",
    alignItems: "center",
  },
  tabStyle: {
    padding: 0,
    fontSize: "16px",
    "@media (min-width: 1200px) and (max-width: 1350px)": {
      fontSize: "14px",
    },
  },
  tabIconStyle: {
    width: 25,
    "@media (min-width: 1200px) and (max-width: 1350px)": {
      width: 20,
    },
  },
  menuTabIconStyle: { width: 30 },
  materialIconStyle: { color: "#000" },
  restartSubscriptionStyle: {
    paddingX: 4,
    fontWeight: "900",
    marginBottom: { xs: 3, sm: 1 },
  },
};
const breakpoints = {
  large: "1200px",
  xlarge: "1350px",
};
const TabsWrapper = styled(Stack)(({ theme }) => ({
  marginLeft: "50px",
  [`@media (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.xlarge})`]:
    {
      marginLeft: "35px",
    },
}));
const Tabs = styled(MuiTabs)(({ theme }) => ({
  "& .Mui-selected ": {
    color: "red",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  ".Mui-selected": {
    fontWeight: "700",
    color: `${theme.palette.primary.main} !important`,
    "& > svg > path": {
      stroke: `${theme.palette.primary.main}  !important`,
    },
  },
}));

const Tab = styled(MuiTab)(({ theme }) => ({
  color: "#000000",
  textTransform: "capitalize",
  fontSize: "16px",
  lineHeight: "19px",
  fontWeight: "400",
  minHeight: "auto",
  "& .MuiButtonBase-root": {
    display: "inline-flex",
  },
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  color: "#000000",
}));

function RenewLayout(props) {
  const { children } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { language, setLanguage } = useLanguageContext();
  const { user, setUser } = useAuthContext();

  const thumbnailImage =
    queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME])?.thumbnail || "";

  const LINKS = useMemo(
    () => [
      {
        title: t("membership_and_subscription"),
        value: "/subscription",
        icon: ReceiptIcon,
        scopes: [SCOPES.MEMBER_SHIP],
      },
    ],
    [t]
  );

  const [value, setValue] = useState(LINKS[0].value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLogout = useCallback(() => {
    try {
      deleteUser();
      setUser(null);
      handleClose();
      navigate("/login", { replace: true });
    } catch {}
  }, []);

  const onMembershipClicked = useCallback(() => {
    try {
      navigate("/subscription");
    } catch (error) {}
  }, []);

  const handleChangeLanguage = useCallback(() => {
    setIsLanguageDialogOpen(true);
  }, []);

  const headerActions = useMemo(
    () => [
      {
        label: t("membership_and_subscription"),
        onClick: onMembershipClicked,
        icon: <ReceiptIcon />,
        scopes: [SCOPES.MEMBER_SHIP],
      },
      {
        label: t("logout"),
        onClick: onLogout,
        icon: <LogoutIcon />,
        scopes: [],
      },
    ],

    [t, onMembershipClicked, onLogout]
  );

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    navigate(newValue, { replace: true });
  }, []);

  const handleCloseLanguageModal = useCallback(() => {
    setIsLanguageDialogOpen(false);
  }, []);

  return (
    <Box
      sx={styles.containerStyle}
      display={"flex"}
      flexDirection={"column"}
      flex={1}
    >
      <Grid container spacing={1} flex={1} flexDirection={"column"}>
        <Grid item xs={12}>
          <Box>
            <Box sx={styles.navbar}>
              <Box sx={styles.leftArea}>
                <Logo />
                <Hidden xlDown>
                  <TabsWrapper direction="row" alignItems="center">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      sx={styles.tabsStyle}
                    >
                      {LINKS.filter((link) =>
                        intersectionScopes(link.scopes, user.scopes)
                      ).map((link) => {
                        const Icon = link.icon;
                        return (
                          <Tab
                            key={link.title}
                            disableFocusRipple={true}
                            disableRipple={true}
                            disableTouchRipple={true}
                            icon={<Icon style={styles.menuTabIconStyle} />}
                            iconPosition="start"
                            value={link.value}
                            label={link.title}
                            sx={styles.tabStyle}
                          />
                        );
                      })}
                    </Tabs>
                  </TabsWrapper>
                </Hidden>
              </Box>

              <Box sx={styles.rightArea}>
                <Box marginRight={2}>
                  <Button
                    variant="text"
                    color="inherit"
                    startIcon={<LanguageIcon />}
                    onClick={handleChangeLanguage}
                  >
                    {language === "ar" ? t("arabic") : t("english")}
                  </Button>
                </Box>

                <Divider orientation="vertical" variant="middle" flexItem />
                <Box sx={styles.rightArea} marginLeft={2}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    sx={styles.menuWrapper}
                  >
                    <Avatar src={thumbnailImage} sx={styles.avatar} />

                    <KeyboardArrowDownIcon />
                  </IconButton>
                  {!!anchorEl && (
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: styles.menuPaper,
                      }}
                    >
                      {headerActions
                        .filter((headerAction) =>
                          intersectionScopes(headerAction.scopes, user.scopes)
                        )
                        .map((option) => (
                          <MenuItem
                            key={option.label}
                            onClick={() => option.onClick()}
                          >
                            <Box sx={styles.optionWrapper}>
                              {option.icon}
                              {option.label}
                            </Box>
                          </MenuItem>
                        ))}
                    </Menu>
                  )}
                </Box>
              </Box>
            </Box>
            <LanguageDialog
              isOpen={isLanguageDialogOpen}
              handleClose={handleCloseLanguageModal}
              setLanguage={setLanguage}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          flex={1}
          sx={{
            flexGrow: "1 !important",
            paddingX: "24px !important",
          }}
        >
          {children}
        </Grid>

        <Grid item xs={12} width={"100%"}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
}

export default RenewLayout;
