import React from "react";
import { Navigate } from "react-router-dom";
import { intersectionRoutes, intersectionScopes } from "./utils";
import { useAuthContext } from "../common/Auth/useAuthContext";

const ProtectedRoute = ({
  roles: routeRoles = [],
  scopes: routeScopes = [],
  children,
}) => {
  const { user } = useAuthContext();

  const hasPermission = intersectionRoutes(routeRoles, user?.roles || []);
  const hasScope = intersectionScopes(routeScopes, user?.scopes || []);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (hasPermission.length === 0) {
    return <Navigate to="/no-permission" replace />;
  }
  if (!hasScope) {
    return <Navigate to="/no-permission" replace />;
  }

  return children;
};

export default ProtectedRoute;
