import httpClient from "../../http/HttpClient";

export const getSalonMembers = () => httpClient.get(`/client/members`).send();

export const deleteInvitedMember = (memberId) =>
  httpClient.delete(`client/members/${memberId}`).send();

export const resendInvitationMember = (memberId) =>
  httpClient
    .post(`/client/members/resend/invite/${memberId}`)
    .withData()
    .send();
