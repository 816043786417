import React, { Suspense, lazy } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Logo = lazy(async () => ({
  default: (await import("../.././../assets/svg/logo.svg")).ReactComponent,
}));
const FacebookLogo = lazy(async () => ({
  default: (await import("../.././../assets/svg/facebook.svg")).ReactComponent,
}));
const InstagramLogo = lazy(async () => ({
  default: (await import("../.././../assets/svg/instagram.svg")).ReactComponent,
}));
const TiktokLogo = lazy(async () => ({
  default: (await import("../.././../assets/svg/tiktok.svg")).ReactComponent,
}));
const LinkedInLogo = lazy(async () => ({
  default: (await import("../.././../assets/svg/linkedin.svg")).ReactComponent,
}));

const Item = styled(Paper)(({ theme }) => ({
  background: "transparent",
  paddingTop: theme.spacing(8),
  textAlign: "center",
  color: theme.palette.text.secondary,
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(2),
  },
}));

const styles = {
  linkStyle: { color: "#6F6C90", fontSize: "16px" },
  headerStyle: {
    fontWeight: "700",
    fontSize: { xs: "16px", sm: "20px" },
    color: "#170F49",
  },
  iconStyle: {
    fill: "#170F49",
    fontSize: "20px",
    marginRight: { xs: "2px", sm: "8px" },
    alignSelf: "center",
  },
  salonSloganStyle: { maxWidth: { xs: "100%", sm: "70%" } },
  dividerStyle: { mt: { xs: 2, sm: 8 } },
};

const DISPLAY_ONLY_MOBILE = {
  display: {
    xs: "block",
    sm: "none",
  },
};

const DISPLAY_EXCEPT_MOBILE = {
  display: {
    xs: "none",
    sm: "flex",
  },
};

const sloganTextAlign = { xs: "center", sm: "left" };

const logoWrapperMargin = { xs: 0, sm: "15%" };
const logoWrapperAlignItems = { xs: "center", sm: "flex-start" };

function ListItemLink(props) {
  const { icon, primary, to, href, onClick } = props;

  let restOfProps = !!href
    ? { target: "_blank", rel: "noopener", href: href }
    : { onClick };
  return (
    <li>
      <ListItem button component={Link} {...restOfProps}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

function Footer() {
  const { t } = useTranslation();

  const handleEmailSend = (e) => {
    e.preventDefault();
    window.location.href = "mailto:info@salon-app.com";
  };

  const handleTelephoneClicked = (e) => {
    e.preventDefault();
    window.location.href = "tel:+972597505100";
  };

  const { headerStyle, linkStyle, iconStyle, salonSloganStyle, dividerStyle } =
    styles;

  return (
    <Box>
      <Item elevation={3}>
        <Grid container spacing={2} columns={15} alignItems={"flex-start"}>
          <Grid item xs={15} sx={DISPLAY_ONLY_MOBILE}>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={headerStyle}> {t("policies")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List aria-label="main mailbox folders" dense>
                  <ListItemLink
                    href="https://salon-app.com/privacy-policy"
                    primary={t("privacy_policy")}
                  />
                  <ListItemLink
                    href="https://salon-app.com/terms-of-service"
                    primary={t("terms_of_service")}
                  />
                  <ListItemLink
                    href="https://salon-app.com/terms-of-use"
                    primary={t("terms_of_use")}
                  />
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography sx={headerStyle}> {t("links")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List aria-label="main mailbox folders" dense>
                  <ListItemLink
                    href="https://salon-app.com"
                    primary={t("landing_page")}
                  />
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography sx={headerStyle}>{t("contact_us")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List aria-label="main mailbox folders" dense>
                  <ListItemLink
                    to="#"
                    primary={"info@salon-app.com"}
                    icon={<EmailIcon sx={iconStyle} />}
                    onClick={handleEmailSend}
                  />
                  <ListItemLink
                    to="#"
                    primary={"+972597505100"}
                    icon={<CallIcon sx={iconStyle} />}
                    onClick={handleTelephoneClicked}
                  />
                  <ListItemLink
                    to="#"
                    primary={t("salon_location")}
                    icon={<LocationOnIcon sx={iconStyle} />}
                  />
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={15} sm={6}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems={logoWrapperAlignItems}
              spacing={2}
              ml={logoWrapperMargin}
            >
              <Suspense fallback={<div></div>}>
                <Logo />
              </Suspense>
              <Typography
                py={1}
                textAlign={sloganTextAlign}
                sx={salonSloganStyle}
              >
                {t("salon_slogan")}
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <IconButton
                  aria-label="Facebook"
                  size="small"
                  href="https://www.facebook.com/Salonbeautybooking"
                  target="_blank"
                  rel="noopener"
                >
                  <Suspense fallback={<div></div>}>
                    <FacebookLogo />
                  </Suspense>
                </IconButton>
                <IconButton
                  aria-label="Instagram"
                  size="small"
                  href="https://www.instagram.com/salonbeautybooking/"
                  target="_blank"
                  rel="noopener"
                >
                  <Suspense fallback={<div></div>}>
                    <InstagramLogo />
                  </Suspense>
                </IconButton>
                <IconButton
                  aria-label="Tiktok"
                  size="small"
                  href="https://www.tiktok.com/@salonbeautybooking"
                  target="_blank"
                  rel="noopener"
                >
                  <Suspense fallback={<div></div>}>
                    <TiktokLogo />
                  </Suspense>
                </IconButton>
                <IconButton
                  aria-label="Linkedin"
                  size="small"
                  href="https://www.linkedin.com/company/salon-beauty-booking/"
                  target="_blank"
                  rel="noopener"
                >
                  <Suspense fallback={<div></div>}>
                    <LinkedInLogo />
                  </Suspense>
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={15} sm={3} sx={DISPLAY_EXCEPT_MOBILE}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography variant="body1" sx={headerStyle} gutterBottom>
                {t("policies")}
              </Typography>
              <Link
                href="https://salon-app.com/privacy-policy"
                underline="hover"
                sx={linkStyle}
                target="_blank"
                rel="noopener"
              >
                {t("privacy_policy")}
              </Link>

              <Link
                href="https://salon-app.com/terms-of-service"
                underline="hover"
                sx={linkStyle}
                target="_blank"
                rel="noopener"
              >
                {t("terms_of_service")}
              </Link>

              <Link
                href="https://salon-app.com/terms-of-use"
                underline="hover"
                sx={linkStyle}
                target="_blank"
                rel="noopener"
              >
                {t("terms_of_use")}
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={15} sm={3} sx={DISPLAY_EXCEPT_MOBILE}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography variant="body1" sx={headerStyle} gutterBottom>
                {t("links")}
              </Typography>
              <Link
                href="https://salon-app.com"
                underline="hover"
                sx={linkStyle}
                target="_blank"
                rel="noopener"
              >
                {t("landing_page")}
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={15} sm={3} sx={DISPLAY_EXCEPT_MOBILE}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography variant="body1" sx={headerStyle} gutterBottom>
                {t("contact_us")}
              </Typography>
              <Stack direction={"row"}>
                <EmailIcon sx={iconStyle} />
                <Link
                  underline="hover"
                  href="#"
                  onClick={handleEmailSend}
                  sx={linkStyle}
                >
                  info@salon-app.com
                </Link>
              </Stack>
              <Stack direction={"row"}>
                <CallIcon sx={iconStyle} />
                <Link
                  underline="hover"
                  href="#"
                  onClick={handleTelephoneClicked}
                  sx={linkStyle}
                  dir="ltr"
                >
                  +972597505100
                </Link>
              </Stack>
              <Stack direction={"row"}>
                <LocationOnIcon sx={iconStyle} />
                <Typography
                  underline="hover"
                  onClick={() => {
                    console.info("I'm a button.");
                  }}
                  sx={linkStyle}
                  textAlign={"left"}
                >
                  {t("salon_location")}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={dividerStyle} />
        <Box paddingY={2}>{t("footer_copyright")}</Box>
      </Item>
    </Box>
  );
}

export default Footer;
