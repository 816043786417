export const VIEW_TYPES_VALUES = {
  MONTHLY: "Month",
  WEEKLY: "Week",
  DAILY: "Day",
};

export const AGENDA_REDUCER_NAME = "AGENDA";
export const AGENDA_WORKING_HOURS_REDUCER_NAME = "AGENDA_WORKING_HOURS";
export const AGENDA_WORKING_HOURS_REDUCER_NAME_UTC = "AGENDA_WORKING_HOURS_UTC";

export const START_DAY_HOUR = 8;
export const ENG_DAY_HOUR = 20;
export const CELL_DURATION = 30; //in munutes

export const APPOINTMENT_STATUS_COLOR = {
  pending: "warning",
  approved: "success",
};

export const APPOINTMENT_STATUSES_VALUES = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  RESCHEDULED: "RESCHEDULED",
  CLOSED: "CLOSED",
};

export const APPOINTMENT_COLORS = [
  "#D50001",
  "#6D6363",
  "#F4511E",
  "#F5BF25",
  "#32B579",
  "#0A8043",
  "#039BE5",
  "#3F50B5",
  "#7886CB",
  "#8E24AA",
  "#616161",
];

export const COLOR_PICKER_DEFAULT_COLOR = "#D50001";

export const API_VIEWS_TYPES = {
  [VIEW_TYPES_VALUES.MONTHLY]: "MONTHLY",
  [VIEW_TYPES_VALUES.WEEKLY]: "WEEKLY",
  [VIEW_TYPES_VALUES.DAILY]: "DAILY",
};

export const API_WORKING_HOURS_VIEWS_TYPES = {
  [VIEW_TYPES_VALUES.MONTHLY]: "MONTH",
  [VIEW_TYPES_VALUES.WEEKLY]: "WEEK",
  [VIEW_TYPES_VALUES.DAILY]: "DAY",
};

export const TOTAL_PRICE_TYPE = {
  NONE: "NONE",
  FREE: "FREE",
  RANGE: "RANGES",
  FIXED: "FIXED",
};

export const COUPON_TYPES = {
  DISCOUNT: "DISCOUNT",
  FIXED: "FIXED",
};

export const SLOT_TYPES = {
  CLOSED: "closed",
  ALMOST_FULL: "almost_full",
  FULL: "full",
};

export const SLOT_TYPES_COLORS = {
  [SLOT_TYPES.ALMOST_FULL]: "#F1B095",
  [SLOT_TYPES.CLOSED]: "#EA7777",
};

export const RESERVATIONS_TYPES = {
  EXPIRED: "EXPIRED",
  FREE: "FREE",
  ALMOST_FULL: "ALMOST_FULL",
  FULL: "FULLY_BOOKED",
  CLOSED: "CLOSED",
};

export const SLOTS_FILTER_OPTIONS_VALUES = {
  ALL: "all_slots",
  AVAILABLE: "available_slots",
};

export const SLOTS_FILTER_OPTIONS = [
  { label: "all_slots", value: SLOTS_FILTER_OPTIONS_VALUES.ALL },
  { label: "available_slots", value: SLOTS_FILTER_OPTIONS_VALUES.AVAILABLE },
];

export const RESERVATION_CUSTOMERS_REDUCER_NAME = "RESERVATION_CUSTOMERS";

export const BOOKING_TYPES = {
  REGULAR: "REGULAR",
  INSTANT: "INSTANT",
};

export const CLOSED_DAY_REOCURRING_TYPES = {
  DAILY: "DAILY",
  NEVER: "NEVER",
};

export const GET_MEMBERS_FROM_SERVICES = "GET_MEMBERS_FROM_SERVICES";

export const APPOINTMENT_FINANCIAL_TRANSACTION =
  "APPOINTMENT_FINANCIAL_TRANSACTION";

export const APPOINTMENT_PAYMENT_TRANSACTION =
  "APPOINTMENT_PAYMENT_TRANSACTION";

export const SCHEDULAR_APPOINTMENT_TYPES = {
  APPOINTMENT: "APPOINTMENT",
  SOURCE_APPOINTMENT: "SOURCE_APPOINTMENT",
  DRAFT_APPOINTMENT: "DRAFT_APPOINTMENT",
};

export const APPOINTMENT_CARD_TYPES = {
  APPOINTMENT: "APPOINTMENT",
  PACKAGE: "PACKAGE",
  OFFER: "OFFER",
  CLOSED: "CLOSED",
};

export const APPOINTMENT_DETAILS_TYPES = {
  APPOINTMENT: "APPOINTMENT",
  EDIT_SERVICE: "EDIT_SERVICE",
};
