import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import styled from "@emotion/styled";

const CheckBox = (props) => {
  const {
    name,
    defaultValue,
    label,
    width,
    height,
    onChangeCb = () => {},
    fontSize = "1rem",
    ...rest
  } = props;

  const { control, setValue } = useFormContext();
  const labelStyle = {
    fontSize: fontSize,
  };

  const onChange = (event) => {
    setValue(name, event.target.checked);
    onChangeCb(event.target.checked);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => {
        if (!!label) {
          return (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={onChange}
                    checkedIcon={
                      <BpCheckedIcon width={width} height={height} />
                    }
                    icon={<BpIcon width={width} height={height} />}
                    {...rest}
                  />
                }
                label={<span style={labelStyle}>{label}</span>}
              />
            </FormGroup>
          );
        }
        return (
          <Checkbox
            checked={field.value}
            onChange={onChange}
            checkedIcon={<BpCheckedIcon width={width} height={height} />}
            icon={<BpIcon width={width} height={height} />}
            {...rest}
          />
        );
      }}
    />
  );
};

const BpIcon = styled("span")(({ theme, width, height }) => ({
  borderRadius: 3,
  width: width,
  height: height,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme, width, height }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: width,
    height: height,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
}));

export default CheckBox;
