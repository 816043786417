import httpClient from "../../http/HttpClient";

export const fetchClientData = () => httpClient.get(`/client/me`).send();

export const fetchMemberData = () => httpClient.get(`/client/member/me`).send();

export const fetchNotificationCount = () =>
  httpClient.get(`/client/notifications/all`).send();

export const fetchClientServices = () =>
  httpClient.get(`/client/services`).send();

export const fetchClientServicesWithMembers = () =>
  httpClient.get(`/client/services?includeMember=true`).send();

export const fetchClientServicesWithoutNotCompleted = () =>
  httpClient
    .get(
      `/client/services?completeOnly=true&includeMember=true&onlyHasMember=true`
    )
    .send();

export const editClientServices = (payload) =>
  httpClient.put(`/client/services`).withData(payload).send();

export const getSalonMembers = () => httpClient.get(`/client/members`).send();

export const getSalonMember = () => httpClient.get(`/client/member`).send();

export const getSalonSubscription = () =>
  httpClient.get(`/client/mySubscriptionInfo`).send();

export const bulkRemoveClientMembers = (payload) =>
  httpClient.post(`/client/members/delete/multiple`).withData(payload).send();

export const getUpgradeToEliteSubscription = () =>
  httpClient.get(`/client/subscription/upgradeToElite`).send();

export const getAllMembers = () =>
  httpClient.get(`/client/calendar/members`).send();
