import { useContext } from "react";
import LanguageContext from "./LanguageContext";

export const useLanguageContext = () => {
  const language = useContext(LanguageContext);
  if (!language) {
    throw new Error(
      "useLanguageContext can only be used inside LanguageProvider"
    );
  }
  return language;
};
