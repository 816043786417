import styled from "@emotion/styled";
import { FormHelperText } from "@mui/material";

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: "400",
  fontSize: "14px",
  textTransform: "capitalize",
  marginTop: 0,
}));
