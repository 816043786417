import React, { forwardRef, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import Box from "@mui/system/Box";
import Stack from "@mui/material/Stack";

import ServicesSelectorForm from "../../../../common/components/ServicesSelectorForm/ServicesSelectorForm";
import { CLIENT_SERVICES_DATA_REDUCER_NAME } from "../../../../common/constants";
import { StyledFormHelperText } from "../../../../common/components/FormHelperText";
import { fetchClientServicesWithMembers } from "../../../../common/api/clientAPI";
import { logError } from "../../../../common/services/LogError";

const Services = forwardRef((props, ref) => {
  const {
    onFinishCb,
    returnFullData = false,
    clientDefaultServices = [],
  } = props;
  const queryClient = useQueryClient();
  const {
    formState: { errors },
  } = useFormContext();

  const { data: clientServices = [] } = useQuery(
    [CLIENT_SERVICES_DATA_REDUCER_NAME],
    async () => {
      try {
        const data = await fetchClientServicesWithMembers();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchClientServicesWithMembers",
          file: "containers/Calendar/components/Reservation/Services.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const clientServicesWithoutNotcompleted = useMemo(
    () =>
      clientServices?.map((service) => {
        // const newSubServices = service.subServices?.filter(
        //   (item) => item.completed
        // );
        return {
          completed: service?.completed,
          id: service?.id,
          name: service?.name,
          subServices: service?.subServices,
        };
      }),
    [clientServices]
  );

  return (
    <Stack gap="0">
      <Box marginBottom="-20px">
        {!!errors["services"] && (
          <StyledFormHelperText>
            {errors["services"]?.message}
          </StyledFormHelperText>
        )}
      </Box>

      <ServicesSelectorForm
        services={clientServicesWithoutNotcompleted}
        clientServices={clientDefaultServices}
        onFinishCb={onFinishCb}
        submitRef={ref}
        returnFullData={returnFullData}
      />
    </Stack>
  );
});

export default Services;
