import { Amplify, Auth, Storage } from "aws-amplify";

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_WEBCLIENT_ID,
      authenticationFlowType:
        process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOWTYPE,
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_AWSS3_STORAGE_BUCKET,
        region: process.env.REACT_APP_S3_REGION,
      },
    },
  });
}
export function AuthorizeFederatedIdentity() {
  return Auth.signIn(
    process.env.REACT_APP_COGNITO_SALONADMIN_USER,
    process.env.REACT_APP_COGNITO_SALONADMIN_PASSWORD
  );
}

export function checkIfAWSUserIsLoggedIn() {
  return Auth.currentAuthenticatedUser();
}
//Configure Storage with S3 bucket information
export function SetS3Config(bucket, level) {
  Storage.configure({
    bucket: bucket,
    level: level,
    region: process.env.REACT_APP_S3_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID,
  });
}
