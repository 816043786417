import React from "react";

import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slide from "@mui/material/Slide";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { WEB_SOCKETS } from "../../../websockets-constants";
import { APPOINTMENT_STATUSES_VALUES } from "../../../../containers/Calendar/constants";
import { CLIENT_DATA_REDUCER_NAME, CURRENCY_SYMBOL } from "../../../constants";

const BOTTOM_RIGHT_PLACEMENT = { vertical: "bottom", horizontal: "right" };

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const styles = {
  cardStyle: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 500,
    maxHeight: 170,
    cursor: "pointer",
    backgroundColor: "rgb(50, 50, 50)",
  },
  cardMediaStyle: { width: 100 },
  cardContentStyle: { padding: "8px 16px 0", color: "#FFFFFF" },
  textStyle: {
    lineHeight: "1.35",
    marginTop: "8px",
  },
};

function AppointmentSnackbar(props) {
  const { appointment = {}, isOpen, handleClose = () => {} } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const profiles =
    queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME])?.profiles || "";
  const currencySign =
    queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME])?.currency?.sign ||
    CURRENCY_SYMBOL;

  const proAr =
    profiles[0]?.language === "ar" ? profiles[0]?.name : profiles[1]?.name;
  const proEn =
    profiles[0]?.language === "en" ? profiles[0]?.name : profiles[1]?.name;

  const handleNewAppointmentClick = () => {
    handleClose();
    if (appointment?.socketType === WEB_SOCKETS.PAYMENT_LINK_PAID_TRANSACTION) {
      return navigate(`/view-payment-link?id=${appointment?.paymentLinkId}`);
    }
    return navigate("/my-calendar", {
      state: {
        appointmentId: appointment?.id,
        date: moment(appointment?.startTime).locale("en").format("YYYY-MM-DD"),
      },
    });
  };

  const getNotificationTitle = () => {
    switch (appointment.socketType) {
      case WEB_SOCKETS.NEW_APPOINTMENT:
        return t("socket_new_reservation");
      case WEB_SOCKETS.CLIENT_MEMBER_UPDATE_APPOINTMENT:
        if (
          appointment?.appointment?.status ===
          APPOINTMENT_STATUSES_VALUES.REJECTED
        ) {
          return t("socket_client_reject_reservation");
        }
        if (
          appointment?.appointment?.status ===
          APPOINTMENT_STATUSES_VALUES.COMPLETED
        ) {
          return t("socket_client_complete_reservation");
        }
        return t("socket_edit_reservation");
      case WEB_SOCKETS.USER_UPDATE_APPOINTMENT:
        return t("socket_edit_reservation");
      case WEB_SOCKETS.CLIENT_MEMBER_CANCEL_APPOINTMENT:
      case WEB_SOCKETS.USER_CANCEL_APPOINTMENT:
        return t("socket_cancel_reservation");
      case WEB_SOCKETS.USER_REJECT_RESCHEDULE_APPOINTMENT:
        return t("socket_reject_reservation");
      case WEB_SOCKETS.USER_APPROVE_RESCHEDULE_APPOINTMENT:
        return t("socket_approve_reservation");
      case WEB_SOCKETS.PAYMENT_LINK_PAID_TRANSACTION:
        return t("socket_paid_transaction_title", {
          salon_name: proEn,
        });
      default:
        return "";
    }
  };

  const getNotificationBody = () => {
    switch (appointment.socketType) {
      case WEB_SOCKETS.NEW_APPOINTMENT:
        return t("socket_new_appointment", {
          name: appointment?.userName,
          date: moment(appointment?.startTime).format("DD-MM-YYYY HH:mm A"),
        });
      case WEB_SOCKETS.USER_UPDATE_APPOINTMENT:
        return t("socket_edit_appointment", {
          name: appointment?.userName,
          date: moment(appointment?.startTime).format("DD-MM-YYYY HH:mm A"),
        });
      case WEB_SOCKETS.CLIENT_MEMBER_UPDATE_APPOINTMENT:
        if (
          appointment?.appointment?.status ===
          APPOINTMENT_STATUSES_VALUES.REJECTED
        ) {
          return t("socket_client_reject_appointment", {
            name: appointment?.member?.displayName,
            date: moment(appointment?.appointment?.startTime).format(
              "DD-MM-YYYY HH:mm A"
            ),
          });
        }
        if (
          appointment?.appointment?.status ===
          APPOINTMENT_STATUSES_VALUES.COMPLETED
        ) {
          return t("socket_complete_appointment", {
            name: appointment?.member?.displayName,
            date: moment(appointment?.appointment?.startTime).format(
              "DD-MM-YYYY HH:mm A"
            ),
          });
        }
        return t("socket_edit_appointment", {
          name: appointment?.member?.displayName,
          date: moment(appointment?.appointment?.startTime).format(
            "DD-MM-YYYY HH:mm A"
          ),
        });
      case WEB_SOCKETS.USER_CANCEL_APPOINTMENT:
        return t("socket_cancel_appointment", {
          name: appointment?.userName,
          date: moment(appointment?.startTime).format("DD-MM-YYYY HH:mm A"),
        });
      case WEB_SOCKETS.CLIENT_MEMBER_CANCEL_APPOINTMENT:
        return t("socket_cancel_appointment", {
          name: appointment?.member?.displayName,
          date: moment(appointment?.appointment?.startTime).format(
            "DD-MM-YYYY HH:mm A"
          ),
        });
      case WEB_SOCKETS.USER_REJECT_RESCHEDULE_APPOINTMENT:
        return t("socket_reject_reschedule_appointment", {
          name: appointment?.userName,
          date: moment(appointment?.startTime).format("DD-MM-YYYY HH:mm A"),
        });
      case WEB_SOCKETS.USER_APPROVE_RESCHEDULE_APPOINTMENT:
        return t("socket_approve_reschedule_appointment", {
          name: appointment?.userName,
          date: moment(appointment?.startTime).format("DD-MM-YYYY HH:mm A"),
        });
      case WEB_SOCKETS.PAYMENT_LINK_PAID_TRANSACTION:
        console.log("appointment", appointment);
        return t("socket_payment_link_paid_body", {
          customer: appointment?.fromObj?.name,
          amount: appointment?.amount,
          currency: currencySign,
        });

      default:
        return "";
    }
  };

  return (
    <Snackbar
      anchorOrigin={BOTTOM_RIGHT_PLACEMENT}
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      autoHideDuration={7000}
    >
      <Card sx={styles.cardStyle} onClick={handleNewAppointmentClick}>
        <CardMedia
          component="img"
          sx={styles.cardMediaStyle}
          image={
            !!appointment?.member
              ? appointment?.member?.imageUrl
              : appointment?.user?.imageUrl
          }
          alt={`${
            !!appointment?.member
              ? appointment?.member?.displayName
              : appointment?.user?.displayName
          } image`}
        />
        <Stack direction={"column"} alignItems={"flex-start"}>
          <CardContent sx={styles.cardContentStyle}>
            <Typography component="div" variant="h6">
              {getNotificationTitle()}
            </Typography>

            <Typography variant="subtitle1" color="white" sx={styles.textStyle}>
              {getNotificationBody()}
            </Typography>
          </CardContent>
        </Stack>
      </Card>
    </Snackbar>
  );
}

export default AppointmentSnackbar;
