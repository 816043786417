import { useMemo } from "react";
import { useSnackbar } from "material-ui-snackbar-provider";

export default function useCustomSnackbar() {
  const snackbar = useSnackbar();
  const snackbarInstance = useMemo(() => {
    const showMessage =
      (type, title) =>
      (message, title = "", action, handleAction, customParameters) =>
        snackbar.showMessage(message, action, handleAction, {
          ...customParameters,
          title,
          type,
        });
    return {
      ...snackbar,
      showMessage: showMessage("info"),
      showInfo: showMessage("info"),
      showWarning: showMessage("warning"),
      showError: showMessage("error"),
      showSuccess: showMessage("success"),
    };
  }, [snackbar]);

  return snackbarInstance;
}
