export const CLIENT_DATA_REDUCER_NAME = "CLIENT_DATA";
export const MEMBER_DATA_REDUCER_NAME = "MEMBER_DATA";
export const LIST_MEMBERS_REDUCER_NAME = "LIST_MEMBERS_REDUCER_NAME";
export const LIST_MEMBERS_ALL_REDUCER_NAME = "LIST_MEMBERS_ALL_REDUCER_NAME";
export const CLIENT_SERVICES_DATA_REDUCER_NAME = "CLIENT_SERVICES_DATA";
export const CLIENT_SERVICES_WITH_MEMBER_DATA_REDUCER_NAME =
  "CLIENT_SERVICES_WITH_MEMBER_DATA_REDUCER_NAME";
export const SYSTEM_DATA_SERVICES_REDUCER_NAME = "SYSTEM_SERVICES_DATA";
export const SYSTEM_DATA_SECTIONS_REDUCER_NAME = "SYSTEM_SECTIONS_DATA";
export const SYSTEM_DATA_CITIES_REDUCER_NAME =
  "SYSTEM_DATA_CITIES_REDUCER_NAME";
export const SYSTEM_DATA_MEMBERS_TYPES_REDUCER_NAME =
  "SYSTEM_MEMBERS_TYPES_DATA";
export const SILENT_LOGIC_DATA_REDUCER_NAME = "SILENT_LOGIN_DATA";
export const CLIENT_SUBSCRIPTION_DATA = "CLIENT_SUBSCRIPTION_DATA";

export const PUBLIC_ROUTES = [
  "/login",
  "/forgot-password",
  "/create-password",
  "/create-new-token",
  "/accept-invitation",
  "/reset-password",
];

export const CURRENCY_SYMBOL = "₪";

//enum APPOINTMENT_STATUS
export const APPOINTMENT_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  RESCHEDULED: "RESCHEDULED",
};
Object.freeze(APPOINTMENT_STATUS);

//enum APPOINTMENT_STATUS
export const VIEW_APPOINTMENT_TYPES = {
  CUSTOMER: "customer",
  OFFER: "offer",
  UPCOMING: "upcoming",
  COUPON: "coupon",
};
Object.freeze(VIEW_APPOINTMENT_TYPES);

// enum
export const APPOINTMENT_TYPES = {
  CLIENT: "CLIENT",
  REGULAR: "REGULAR",
  OFFER: "OFFER",
  PACKAGE: "PACKAGE",
  CLIENT_PACKAGE: "CLIENT_PACKAGE",
};
Object.freeze(APPOINTMENT_TYPES);

export const chart_scheme = [
  "#6E4B87",
  "#54CDD9",
  "#5C729B",
  "#F0AD4E",
  "#D9534F",
  "#5CB85C",
];

export const REVENUES_CHART_SCHEME_COLORS = [
  "#878BCD",
  "#D292D2",
  "#F6AEC5",
  "#FDF1A1",
  "#BFEB88",
  "#83D1D4",
  /**end of paleete */
  "#6E4B87",
  "#54CDD9",
  "#F0AD4E",
  "#5C729B",
  "#5CB85C",
  "#fbfdf6",
];

export const EXPENSES_CHART_SCHEME_COLORS = [
  "#3B495E",
  "#598D91",
  "#B8AC8D",
  "#717882",
  "#C79393",
  "#623D59",
  /** end of pallette */
  "#CD5C5C",
  "#F08080",
  "#FA8072",
  "#E9967A",
  "#FFA07A",
];

export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";

export const NOTIFICATION_ACTIONS = {
  APPOINTMENT: "APPOINTMENT",
  INVOICE: "INVOICE",
  REMINDER_APPOINTMENTS_UNCOMPETED: "REMINDER_APPOINTMENTS_UNCOMPETED",
  REMINDER_APPOINTMENTS_PENDING: "REMINDER_APPOINTMENTS_PENDING",
  REMINDER_APPOINTMENTS_TODAY: "REMINDER_APPOINTMENTS_TODAY",
};

export const NOTIFICATION_TABS_TYPES = {
  GENERAL: "general",
  PENDING: "pending",
  COMPLETED: "completed",
};

export const SCOPES = {
  APPOINTMENTS: "APPOINTMENTS",
  CUSTOMERS: "CUSTOMERS",
  MEMBER_SHIP: "MEMBER_SHIP",
  NOTIFICATION: "NOTIFICATION",
  DASHBOARD: "DASHBOARD",
  OFFERS: "OFFERS",
  SERVICES: "SERVICES",
  STAFF_MANAGEMENT: "STAFF_MANAGEMENT",
  PAYMENTS: "PAYMENT_MANAGEMENT",
  FINANCIAL: "FINANCIAL_MANAGEMENT",
  MARKETING_PROMOTION: "MARKETING_PROMOTION",
  APPOINTMENTS_HISTORY: "APPOINTMENTS_HISTORY",
};
export const SORTING_VALUES = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export const FINANCIALS_PAYMENT_LABEL = {
  NEW: "NEW",
  SCHEDULED: "SCHEDULED",
  REPEATED: "REPEATED",
};
export const FINANCIAL_TRANSACTION_TYPE = {
  IN: "earnings",
  OUT: "expenses",
};
export const FINANCIAL_TRANSACTION_TYPE_TRANSLATED = {
  IN: "revenue",
  OUT: "expense",
};
export const FINANCIAL_CONTACTS_TYPE = {
  CUSTOMERS: "Customers",
  MEMBERS: "Members",
  PROVIDER: "Provider",
};
export const FINANCIAL_TRANSACTION_TYPE_KEY = {
  IN: "IN",
  OUT: "OUT",
};
export const FINANCIAL_TRANSACTION_MIXED_TYPES = [
  "Bookings",
  "حجوزات",
  "Products Sales",
  "بيع منتجات",
  "Walk-In Bookings",
  "حجوزات بدون موعد",
  "On-site Products Sales",
  "بيع منتجات اوفلاين",
];
export const PAYMENT_TYPE = {
  DOWN_PAYMENT: "down_payment",
  PAYMENT_ON_ACCOUNT: "payment_on_account",
};
export const DAY_OF_WEEK = {
  SUNDAY: "SUNDAY",
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
};
export const FINANCIALS_REPEATED_VALUE = {
  // DAILY: "daily_repeted",
  WEEKLY: "weekly_repeted",
  MONTHLY: "mounthly_repeted",
  YEARLY: "yearly_repeted",
};
export const FINANCIALS_TABLE_HEADER_OPTIONS = {
  DATE: "date",
  DAY: "day",
  REVENUES: "revenues",
  EXPENSES: "expenses",
  PROFIT: "profit",
};
export const DETAILS_TABLE_HEADER_OPTIONS = {
  CREATED: "created",
  POSTING_DATE: "date",
  TRANSACTION_DATE: "transactionDate",
  TRANSACTION_CATEGORY: "transactionCategoryId",
  AMOUNT: "amount",
  LABEL: "transactionLabel",
  FROM: "fromId",
  TO: "toId",
  NOTE: "note",
};

export const APP_PAYMENT_DEFAULT_VALUES = {
  amount: "",
  fullyAmount: "",
  paymentStatus: "PENDING",
  paymentDate: null,
  completionDate: null,
  paymentCompletionTime: null,
  paymentType: null,
  category: null,
  from: null,
  to: null,
  paymentMethod: "CASH",
  contactType: "PROVIDER",
  paymentLink: "",
  transactionAlert: false,
  transactionStatement: false,
  notes: "",
};

export const REVIEW_CARD_WIDTH = 400;
export const REVIEW_CARD_MOBILE_WIDTH = 325;

export const SUBSCRIPTION_TYPES = {
  ELITE: "ELITE",
  PREMIUM: "PREMIUM",
};

export const WALK_IN_CUSTOMER = "WALK_IN_CUSTOMER";
