import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  Avatar as MuiAvatar,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import Hidden from "@mui/material/Hidden";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LogoutIcon from "@mui/icons-material/Logout";
import ModeEdit from "@mui/icons-material/ModeEdit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import styled from "@emotion/styled";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

import { ReactComponent as Logo } from ".././../assets/svg/logo.svg";
import { ReactComponent as Dashboard } from ".././../assets/svg/dashboard.svg";
import { ReactComponent as Calendar } from ".././../assets/svg/calendar.svg";
import { ReactComponent as AppointmentsIcon } from ".././../assets/svg/appointments.svg";
import { ReactComponent as MyCustomerIcon } from ".././../assets/svg/my_customers.svg";
import { ReactComponent as MyOffersIcon } from ".././../assets/svg/my_offers.svg";
import { ReactComponent as MyPaymentIcon } from ".././../assets/svg/paymentIcon.svg";
import { ReactComponent as FinancialsIcon } from ".././../assets/svg/receipt_minus.svg";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import DiscountIcon from "@mui/icons-material/Discount";
import SmsIcon from "@mui/icons-material/Sms";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import PaymentIcon from "@mui/icons-material/Payment";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { useAuthContext } from "../Auth/useAuthContext";
import { deleteUser } from "../Auth/utils";
import {
  CLIENT_DATA_REDUCER_NAME,
  CLIENT_SUBSCRIPTION_DATA,
  NOTIFICATION_COUNT,
  SCOPES,
} from "../constants";
import LanguageDialog from "./LanguagesDialog/LanguageDialog";
import { useLanguageContext } from "../Language/useLanguageContext";
import { fetchNotificationCount } from "../api/clientAPI";
import Sidebar from "./Sidebar";
import Footer from "./Footer/Footer";
import { logError } from "../services/LogError";
import { intersectionScopes } from "../../routes/utils";
import { Can } from "../casl";
import MenuTab from "./MenuTab";

const ITEM_HEIGHT = 48;
const breakpoints = {
  large: "1200px",
  xlarge: "1350px",
};

const styles = {
  navbar: {
    background: "#FFFFFF",
    boxShadow:
      "0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08)",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "16px 32px",
    justifyContent: "space-between",
  },
  leftArea: {
    display: "flex",
    alignItems: "center",
  },
  rightArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "4px",
  },
  optionWrapper: {
    display: "flex",
    gap: "12px",
  },
  menuPaper: {
    minWidth: "25ch",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 30,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  notificationIcon: {
    color: "#687373",
    cursor: "pointer",
  },
  activeIcon: {
    color: "#6E4B87",
  },
  notificationBadge: {
    "& .MuiBadge-badge": { background: "#D9534F", color: "#fff" },
  },
  menuIcon: {
    color: "#6E4B87",
    cursor: "pointer",
  },
  avatar: { marginInlineEnd: 1.5 },
  menuWrapper: {
    ":hover": {
      background: "inherit",
    },
  },
  tabsStyle: {
    overflow: "visible",
    ".MuiTabs-scroller	": { overflow: "visible !important" },
    ".MuiTabs-flexContainer	": { gap: 1 },
    display: "flex",
    alignItems: "center",
  },
  tabStyle: {
    padding: 0,
    fontSize: "16px",
    "@media (min-width: 1200px) and (max-width: 1350px)": {
      fontSize: "14px",
    },
  },
  tabIconStyle: {
    width: 25,
    "@media (min-width: 1200px) and (max-width: 1350px)": {
      width: 20,
    },
  },
  menuTabIconStyle: { width: 30 },
  materialIconStyle: { color: "#000" },
  linkStyle: {
    color: "inherit",
    textDecoration: "underline",
    padding: 0,
    paddingBottom: "3px",
    marginLeft: "3px",
  },
};

const MENU_LIST = "MENU_LIST";

function PrivateRouteLayout(props) {
  const { t } = useTranslation();
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useAuthContext();
  const { language, setLanguage } = useLanguageContext();

  const queryClient = useQueryClient();

  const thumbnailImage =
    queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME])?.thumbnail || "";

  const nextRenewalDate = queryClient.getQueryData([
    CLIENT_SUBSCRIPTION_DATA,
  ])?.nextRenewalDate;

  const LINKS = useMemo(
    () => [
      {
        title: t("dashboard"),
        value: "/dashboard",
        icon: Dashboard,
        scopes: [SCOPES.DASHBOARD],
      },
      {
        title: t("my_calendar"),
        value: "/my-calendar",
        icon: Calendar,
        scopes: [SCOPES.APPOINTMENTS],
      },
      {
        title: t("my_appointments"),
        value: "/my-appointments",
        icon: AppointmentsIcon,
        scopes: [SCOPES.APPOINTMENTS_HISTORY],
      },
      {
        title: t("my_customers"),
        value: "/my-customers",
        icon: MyCustomerIcon,
        scopes: [SCOPES.CUSTOMERS],
      },
      {
        title: t("promotional_tools"),
        type: MENU_LIST,
        menuItems: [
          {
            title: t("mobile_sms"),
            value: "/mobile-sms",
            icon: () => <SmsIcon sx={styles.materialIconStyle} />,
            scopes: [SCOPES.MARKETING_PROMOTION],
          },
          {
            title: t("whatsapp_messages"),
            value: "/whatsapp-messages",
            icon: () => <WhatsAppIcon sx={styles.materialIconStyle} />,
            scopes: [SCOPES.MARKETING_PROMOTION],
          },
          {
            title: t("my_offers"),
            value: "/my-offers",
            icon: MyOffersIcon,
            scopes: [SCOPES.OFFERS],
          },
          {
            title: t("push_notifications"),
            value: "/push-notifications",
            icon: () => <AdUnitsIcon sx={styles.materialIconStyle} />,
            scopes: [SCOPES.MARKETING_PROMOTION],
          },
          {
            title: t("dynamic_link"),
            value: "/deep-links",
            icon: () => <InsertLinkIcon sx={styles.materialIconStyle} />,
            scopes: [SCOPES.MARKETING_PROMOTION],
          },
          {
            title: t("copouns"),
            value: "/my-coupons",
            icon: () => <DiscountIcon sx={styles.materialIconStyle} />,
            scopes: [SCOPES.MARKETING_PROMOTION],
          },
        ],
        icon: LoyaltyIcon,
        scopes: [SCOPES.MARKETING_PROMOTION, SCOPES.OFFERS],
      },
      {
        title: t("financial_headers"),
        type: MENU_LIST,
        menuItems: [
          {
            title: t("financial_transactions"),
            value: "/my-financials",
            icon: FinancialsIcon,
            scopes: [SCOPES.FINANCIAL],
          },
          {
            title: t("payments"),
            value: "/payments",
            icon: MyPaymentIcon,
            scopes: [SCOPES.PAYMENTS],
          },
          {
            title: t("payment_links"),
            value: "/payment-links",
            icon: () => <PaymentIcon sx={styles.materialIconStyle} />,
            scopes: [SCOPES.PAYMENTS],
          },
        ],
        icon: FinancialsIcon,
        scopes: [SCOPES.FINANCIAL, SCOPES.PAYMENTS],
      },
    ],
    []
  );

  const [value, setValue] = useState(LINKS[0].value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
  const [isSubscriptionAlertOpen, setIsSubscriptionAlertOpen] = useState(false);

  const open = Boolean(anchorEl);

  const { data: notificationCount = { totalNotificationCount: 0 } } = useQuery(
    [NOTIFICATION_COUNT],
    async () => {
      try {
        const data = await fetchNotificationCount();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchNotificationCount",
          file: "PrivateRouteLayout.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    }
  );

  const { totalNotificationCount } = notificationCount;

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    navigate(newValue, { replace: true });
  }, []);

  const onLogout = useCallback(() => {
    try {
      deleteUser();
      setUser(null);
      setAnchorEl(null);
      navigate("/login", { replace: true });
    } catch {}
  }, []);

  const onEditSalon = useCallback(() => {
    try {
      setAnchorEl(null);
      navigate("/edit-salon");
    } catch {}
  }, []);

  const onEditSettings = useCallback(() => {
    try {
      setAnchorEl(null);
      navigate("/settings");
    } catch {}
  }, []);

  const onMembershipClicked = useCallback(() => {
    try {
      setAnchorEl(null);
      navigate("/subscription");
    } catch (error) {}
  }, []);

  const headerActions = useMemo(
    () => [
      {
        label: t("membership_and_subscription"),
        onClick: onMembershipClicked,
        icon: <ReceiptIcon />,
        scopes: [SCOPES.MEMBER_SHIP],
      },
      {
        label: t("edit_my_profile"),
        onClick: onEditSalon,
        icon: <ModeEdit />,
        scopes: [],
      },
      {
        label: t("settings"),
        onClick: onEditSettings,
        icon: <SettingsIcon />,
        scopes: [],
      },
      {
        label: t("logout"),
        onClick: onLogout,
        icon: <LogoutIcon />,
        scopes: [],
      },
    ],

    [t, onMembershipClicked, onEditSalon, onEditSettings, onLogout]
  );

  const handleChangeLanguage = useCallback(() => {
    setIsLanguageDialogOpen(true);
  }, []);

  const handleCloseLanguageModal = useCallback(() => {
    setIsLanguageDialogOpen(false);
  }, []);
  const onNotificationClick = useCallback(() => {
    setValue("/notification");
    navigate("/notification", { replace: true });
  }, []);

  const handleCloseSubscriptionAlert = useCallback(() => {
    setIsSubscriptionAlertOpen(false);
  }, []);

  const handleGoToSubscriptionPage = useCallback(() => {
    setIsSubscriptionAlertOpen(false);
    navigate("/subscription");
  }, [navigate]);

  useEffect(() => {
    const tabValue = LINKS.find(
      (link) => location.pathname === link.value
    )?.value;

    if (!!tabValue) {
      setValue(tabValue);
    } else {
      setValue(false);
    }
  }, [location]);

  useEffect(() => {
    let timerId = null;
    const checkIfNextRenewalWithinThreeDays = () => {
      if (!nextRenewalDate) {
        return;
      }
      const now = moment();
      const givenDate = moment(nextRenewalDate);

      const diffInDays = givenDate.diff(now, "days");

      if (diffInDays <= 3) {
        timerId = setTimeout(() => {
          setIsSubscriptionAlertOpen(true);
        }, 3000);
      }
    };

    checkIfNextRenewalWithinThreeDays();

    return () => {
      !!timerId && clearTimeout(timerId);
    };
  }, [nextRenewalDate]);

  return (
    <Box>
      <Box sx={styles.navbar}>
        <Hidden lgDown>
          <Box sx={styles.leftArea}>
            <Logo />
            <TabsWrapper direction="row" alignItems="center">
              <Tabs value={value} onChange={handleChange} sx={styles.tabsStyle}>
                {LINKS.filter((link) =>
                  intersectionScopes(link.scopes, user.scopes)
                ).map((link) => {
                  const Icon = link.icon;
                  return link.type === MENU_LIST ? (
                    <MenuTab
                      key={link.title}
                      label={link.title}
                      menuItems={link.menuItems}
                      icon={<Icon style={styles.tabIconStyle} />}
                    />
                  ) : (
                    <Tab
                      key={link.title}
                      disableFocusRipple={true}
                      disableRipple={true}
                      disableTouchRipple={true}
                      icon={<Icon style={styles.menuTabIconStyle} />}
                      iconPosition="start"
                      value={link.value}
                      label={link.title}
                      sx={styles.tabStyle}
                    />
                  );
                })}
              </Tabs>
            </TabsWrapper>
          </Box>
        </Hidden>
        <Hidden lgUp>
          <Sidebar
            buttons={LINKS.filter((link) =>
              intersectionScopes(link.scopes, user.scopes)
            )}
            onChange={handleChange}
          />
        </Hidden>
        <Box sx={styles.rightArea}>
          <Can I="read" a={SCOPES.NOTIFICATION}>
            <Box display="flex" px={3}>
              <Badge
                sx={styles.notificationBadge}
                badgeContent={totalNotificationCount}
              >
                <NotificationsIcon
                  onClick={onNotificationClick}
                  sx={{
                    ...styles.notificationIcon,
                    ...(location.pathname === "/notification" &&
                      styles.activeIcon),
                  }}
                />
              </Badge>
            </Box>
          </Can>
          <Box marginRight={2}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<LanguageIcon />}
              onClick={handleChangeLanguage}
            >
              {language === "ar" ? t("arabic") : t("english")}
            </Button>
          </Box>

          <Divider orientation="vertical" variant="middle" flexItem />
          <Box sx={styles.rightArea} marginLeft={2}>
            <IconButton
              aria-label="more"
              id="private-long-button"
              aria-controls={open ? "private-long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              sx={styles.menuWrapper}
            >
              <Avatar src={thumbnailImage} sx={styles.avatar} />

              <KeyboardArrowDownIcon />
            </IconButton>

            {!!anchorEl && (
              <Menu
                id="private-long-menu"
                MenuListProps={{
                  "aria-labelledby": "private-long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {headerActions
                  .filter((headerAction) =>
                    intersectionScopes(headerAction.scopes, user.scopes)
                  )
                  .map((option) => (
                    <MenuItem
                      key={option.label}
                      onClick={() => option.onClick()}
                    >
                      <Box sx={styles.optionWrapper}>
                        {option.icon}
                        {option.label}
                      </Box>
                    </MenuItem>
                  ))}
              </Menu>
            )}
          </Box>
        </Box>
      </Box>
      <Box paddingBottom={4}>
        <Collapse in={isSubscriptionAlertOpen}>
          <Alert
            severity="info"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseSubscriptionAlert}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>{t("subscription_ends_soon")}</AlertTitle>
            {t("subscription_almost_end_alert_p1", {
              date: moment(nextRenewalDate).format("MMMM DD, YYYY"),
            })}
            <Button
              variant="text"
              sx={styles.linkStyle}
              onClick={handleGoToSubscriptionPage}
            >
              {t("click_here")}
            </Button>
            {t("subscription_almost_end_alert_p2")}
          </Alert>
        </Collapse>
        {children}
      </Box>
      <Footer />
      <LanguageDialog
        isOpen={isLanguageDialogOpen}
        handleClose={handleCloseLanguageModal}
        setLanguage={setLanguage}
      />
    </Box>
  );
}

const TabsWrapper = styled(Stack)(({ theme }) => ({
  marginLeft: "50px",
  [`@media (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.xlarge})`]:
    {
      marginLeft: "35px",
    },
}));
const Tabs = styled(MuiTabs)(({ theme }) => ({
  "& .Mui-selected ": {
    color: "red",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  ".Mui-selected": {
    fontWeight: "700",
    color: `${theme.palette.primary.main} !important`,
    "& > svg > path": {
      stroke: `${theme.palette.primary.main}  !important`,
    },
  },
}));

const Tab = styled(MuiTab)(({ theme }) => ({
  color: "#000000",
  textTransform: "capitalize",
  fontSize: "16px",
  lineHeight: "19px",
  fontWeight: "400",
  minHeight: "auto",
  "& .MuiButtonBase-root": {
    display: "inline-flex",
  },
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  color: "#000000",
}));
export default PrivateRouteLayout;
