import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function DeleteServiceDialog(props) {
  const {
    isOpen,
    handleClose = () => {},
    onConfirmCb = () => {},
    service = {},
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          {t("delete_service_title", { name: service?.name })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("delete_service_paragraph", {
              name: service?.name,
              members: service?.member?.displayName,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("cancel")}
          </Button>
          <Button onClick={onConfirmCb} autoFocus>
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
