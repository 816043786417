import { useCallback } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
const TimeField = (props) => {
  const {
    name,
    defaultValue,
    label,
    options,
    postChange,
    size = "small",
    ...rest
  } = props;
  const { control, setValue } = useFormContext();
  const onChange = useCallback(
    (e, selected) => {
      setValue(name, selected.value);
      if (postChange) {
        postChange(selected.value);
      }
    },
    [name, postChange, setValue]
  );
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            {...field}
            onChange={onChange}
            options={options}
            isOptionEqualToValue={(option, value) => {
              return option.value === value;
            }}
            sx={!!fieldState?.error ? styles.rootWithError : styles.root}
            {...rest}
            renderInput={(params) => (
              <TextField {...params} label={label} size={size} />
            )}
          />
        );
      }}
    />
  );
};
const styles = {
  root: { flex: 1, borderRadius: "4px" },
  rootWithError: {
    flex: 1,
    borderRadius: "4px",
    border: "1px solid red",
    ".MuiFormLabel-root": {
      backgroundColor: "#FFFFFF",
    },
  },
};
export default TimeField;
