import React, { useState, useRef, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { CLIENT_SERVICES_DATA_REDUCER_NAME } from "../../constants";
import { editClientServices, fetchClientServices } from "../../api/clientAPI";
import ServicePicker from "../ServicePicker/ServicePicker";
import useCustomSnackbar from "../Snackbar/useSnackbar";

import ServicesSelectorForm from "../ServicesSelectorForm/ServicesSelectorForm";
import { useLanguageContext } from "../../Language/useLanguageContext";
import { logError } from "../../services/LogError";
import EditableServicesListSekelton from "./EditableServicesListSekelton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: `${window.innerHeight * 0.8}px`,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  borderWidth: 0.5,
  borderColor: "#C1C1C1",
  borderStyle: "solid",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "25ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const BACKDROP_PROPS = {
  timeout: 500,
};

const styles = {
  saveBtnStyle: { justifyContent: "flex-end", display: "flex" },
  dividerStyle: { marginTop: "19px" },
  addServicesTextStyle: { fontWeight: "700", fontSize: 24 },
  noResultTextStyle: {
    fontSize: 18,
    color: "#687373",
    fontWeight: "700",
    marginTop: 2,
  },
  serviceTextStyle: {
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: "700",
  },
  servicesWrapperStyle: {
    maxHeight: 400,
    overflow: "scroll",
    marginBottom: 2,
  },
  servicesLargeWrapperStyle: {
    maxHeight: 525,
    overflow: "scroll",
    marginBottom: 5,
  },
  flexGrow: { flex: 1 },
  closeIconBtnStyle: { position: "absolute", right: 0 },
};

function EditableServicesList(props) {
  const { services, isLargeView = false } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const snackbar = useCustomSnackbar();

  const submitRef = useRef();

  const [isAddServiceModalOpen, setIsAddServiceModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useLanguageContext();

  const {
    data: clientServices = [],
    isLoading: isClientServiceLoading,
    refetch,
  } = useQuery(
    [CLIENT_SERVICES_DATA_REDUCER_NAME],
    async () => {
      try {
        const data = await fetchClientServices();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchClientServices",
          file: "common/components/EditableServicesList.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [language]);

  // Mutations
  const mutation = useMutation(editClientServices, {
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.setQueryData([CLIENT_SERVICES_DATA_REDUCER_NAME], data);
      snackbar.showSuccess("services added successfully");
    },
  });

  const handleAdd = () => {
    setIsAddServiceModalOpen(true);
  };

  const handleClose = () => {
    setIsAddServiceModalOpen(false);
  };

  const handleSearch = (e) => {
    const keyword = e.target.value;
    setSearchTerm(keyword);
  };
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSave = () => {
    submitRef.current.click();
  };

  const onSubmit = (data) => {
    try {
      setIsLoading(true);

      let newServices = [];
      const servicesLength = services.length;

      for (let index = 0; index < servicesLength; index++) {
        const parentService = services[index];
        const subServicesLength = parentService?.subServices.length;

        for (let j = 0; j < subServicesLength; j++) {
          const service = parentService?.subServices[j];
          if (data.find((item) => item.subServiceId === service?.id)) {
            newServices.push(service);
          }
        }
      }
      mutation.mutate(newServices);
    } catch (error) {
      logError({
        error,
        file: "EditableServicesList.jsx",
        methodName: "onSubmit",
        params: { data },
      });
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const results = [];

  for (let i = 0; i < clientServices.length; i++) {
    const service = clientServices[i];
    let filteredService = {
      completed: service.completed,
      id: service.id,
      name: service.name,
      subServices: [],
    };
    const serviceSubServicesLength = service.subServices.length;
    for (let index = 0; index < serviceSubServicesLength; index++) {
      const subService = service.subServices[index];

      if (subService.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredService.subServices.push(subService);
      }
    }
    if (filteredService.subServices.length > 0) {
      results.push(filteredService);
    }
  }

  const {
    saveBtnStyle,
    dividerStyle,
    addServicesTextStyle,
    noResultTextStyle,
    serviceTextStyle,
    servicesWrapperStyle,
    flexGrow,
    closeIconBtnStyle,
    servicesLargeWrapperStyle,
  } = styles;

  const allsubServices = results
    ?.map((service) => {
      return service.subServices;
    })
    .flat();
  const showWarning = allsubServices.every((item) => item.completed);

  return (
    <div>
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <div style={flexGrow}>
          <Typography variant="h4">{t("services")}</Typography>
        </div>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Search onChange={handleSearch}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={`${t("search")}...`}
              value={searchTerm}
            />
            {searchTerm?.length > 0 && (
              <IconButton onClick={handleClearSearch} sx={closeIconBtnStyle}>
                <CloseIcon />
              </IconButton>
            )}
          </Search>
          <IconButton onClick={handleAdd}>
            <AddIcon />
          </IconButton>
          {/* <IconButton onClick={handleAdd}>
            <EditIcon />
          </IconButton> */}
        </Stack>
      </Stack>
      {!showWarning && (
        <Box marginTop={2}>
          <Alert severity="warning">{t("uncompleted_services_warning")}</Alert>
        </Box>
      )}

      <Box sx={isLargeView ? servicesLargeWrapperStyle : servicesWrapperStyle}>
        {isClientServiceLoading ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
            return <EditableServicesListSekelton key={item} index={index} />;
          })
        ) : results && results.length > 0 ? (
          results?.map((service) => {
            const { id, subServices, name } = service || {};
            return (
              <div key={id}>
                <Typography color="primary" sx={serviceTextStyle}>
                  {name}
                </Typography>
                {subServices.map((subService, index) => {
                  const {
                    id: subServiceId,
                    name: subServiceName,
                    completed,
                    discount,
                    duration,
                    price,
                    minPrice,
                    maxPrice,
                  } = subService || {};

                  return (
                    <ServicePicker
                      key={subServiceId}
                      name={subServiceName}
                      index={index}
                      id={subServiceId}
                      parentID={id}
                      completed={completed}
                      defaultValues={{
                        discount,
                        duration,
                        price,
                        minPrice,
                        maxPrice,
                        isPriceRange: !!minPrice && !!maxPrice,
                      }}
                    />
                  );
                })}
              </div>
            );
          })
        ) : (
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={noResultTextStyle}
          >
            {t("no_results_found")}
          </Typography>
        )}
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isAddServiceModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={BACKDROP_PROPS}
      >
        <Fade in={isAddServiceModalOpen}>
          <Box sx={style}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="h6"
                component="h2"
                color="primary"
                sx={addServicesTextStyle}
              >
                {t("add_services")}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>

            <Divider sx={dividerStyle} />

            <ServicesSelectorForm
              services={services}
              clientServices={clientServices}
              onFinishCb={onSubmit}
              submitRef={submitRef}
            />

            <Box sx={saveBtnStyle}>
              <LoadingButton
                onClick={handleSave}
                endIcon={<SaveIcon />}
                loading={isLoading}
                loadingPosition="end"
                variant="contained"
                size="large"
              >
                {t("save_and_close")}
              </LoadingButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default EditableServicesList;
