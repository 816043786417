import { createTheme } from "@mui/material";
import { colors } from "./colors";
import CairoTtf from "./fonts/Cairo-VariableFont_slnt,wght.ttf";

const rtlTheme = createTheme({
  typography: {
    fontFamily: "Cairo",
  },
  palette: {
    primary: {
      main: "#6E4B87",
      secondary: "#5C729B",
    },
    warning: {
      main: "#F0AD4E",
    },
    success: {
      main: "#5CB85C",
    },
    valenciaRed: {
      main: "#D9534F",
    },
    links: {
      main: "#0000FF",
      contrastText: "#fff",
    },
    warning: {
      main: "#F0AD4E",
    },
    success: {
      main: "#5CB85C",
      contrastText: "#fff",
    },
    gray: {
      main: "#858585",
      contrastText: "#fff",
      fossile: "#687373",
    },
  },
  colors,
  direction: "rtl",
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Cairo';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src:  url(${CairoTtf}) format('truetype');
        }
      `,
    },
    // Name of the component
    MuiSelect: {
      styleOverrides: {
        select: {
          // Some CSS
          padding: "12.5px 14px",
        },
      },
    },
  },
});

export default rtlTheme;
