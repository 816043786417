import { createTheme } from "@mui/material";
import { colors } from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6E4B87",
      secondary: "#5C729B",
    },
    links: {
      main: "#0000FF",
      contrastText: "#fff",
    },
    warning: {
      main: "#F0AD4E",
    },
    success: {
      main: "#5CB85C",
      contrastText: "#fff",
    },
    valenciaRed: {
      main: "#D9534F",
    },
    gray: {
      main: "#858585",
      contrastText: "#fff",
      antiFlashWhite: "#EEF1F4",
    },
    error: {
      main: "#C3281C",
    },
  },
  colors,
  typography: { button: { textTransform: "none" } },
  components: {
    // Name of the component
    MuiSelect: {
      styleOverrides: {
        select: {
          // Some CSS
          padding: "12.5px 14px",
        },
      },
    },
  },
});

export default theme;
