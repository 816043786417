import React, { useEffect } from "react";
import data from "@emoji-mart/data";
import { init } from "emoji-mart";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MainRoutes from "./routes/routes";
import AuthProvider from "./common/Auth/AuthProvider";
import ErrorBoundary from "./common/components/ErrorBoundary";
import { configureAmplify } from "./common/services/awsService";
import { AccessProvider } from "./common/casl";
import "./App.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const options = {
    // passing the client secret obtained from the server
    // clientSecret: '{{CLIENT_SECRET}}',
  };

  useEffect(() => {
    configureAmplify();
    //@emoji-mart
    init({ data });
  }, []);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <AccessProvider>
          <Elements stripe={stripePromise} options={options}>
            <MainRoutes />
          </Elements>
        </AccessProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
