import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledBadge = styled(Box)((props) => {
  const { theme, isChecked } = props;

  return {
    backgroundColor: isChecked
      ? theme.palette.success.main
      : theme.palette.error.main,
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 5,
  };
});

const styles = {
  errorStyle: { border: "1px solid red" },
};

function ToggleMultipleGroup(props) {
  const { options, name, control, errors } = props;

  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const { errorStyle } = styles;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <ToggleButtonGroup
            color={"primary"}
            value={field.value}
            onChange={(e, values) => field.onChange(values)}
            aria-label="text formatting"
            fullWidth
            sx={!!errors[name]?.message ? errorStyle : {}}
            orientation={matchesSmUp ? "horizontal" : "vertical"}
          >
            {options.map((option) => {
              const { id, label, value, isDisabled = false } = option;
              return (
                <ToggleButton
                  key={id}
                  value={value}
                  aria-label={value}
                  disabled={isDisabled}
                >
                  <StyledBadge isChecked={field.value?.includes(value)} />
                  <Typography>{label}</Typography>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        );
      }}
    />
  );
}

export default ToggleMultipleGroup;
