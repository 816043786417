class HttpError extends Error {
  constructor(name, code, response) {
    super(`[HTTP ${name} Error]`);
    this.code = code;
    this.response = response;
  }
}

class HttpAuthError extends HttpError {
  constructor(code, response) {
    super("Auth", code, response);
  }
}

class HttpServerError extends HttpError {
  constructor(code, response) {
    super(`Server`, code, response);
  }
}

class HttpValidationError extends HttpError {
  constructor(code, response) {
    super(`Validation`, code, response);
  }
}

class HttpTimeoutError extends HttpError {
  constructor(code, response) {
    super(`Timeout`, code, response);
  }
}

class HttpClientError extends HttpError {
  constructor(code, response) {
    super(`Client`, code, response);
  }
}

class HttpSchemaValidationError extends HttpError {
  constructor(message) {
    super(`Schema`, 0, message);
  }
}

export {
  HttpAuthError,
  HttpClientError,
  HttpValidationError,
  HttpSchemaValidationError,
  HttpError,
  HttpServerError,
  HttpTimeoutError,
};
