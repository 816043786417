import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { useForm } from "react-hook-form";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { styled } from "@mui/material/styles";

import {
  CLIENT_DATA_REDUCER_NAME,
  CLIENT_SERVICES_DATA_REDUCER_NAME,
  CURRENCY_SYMBOL,
} from "../../constants";
import { editSalonService } from "../../../containers/EditSalon/api";
import { logError } from "../../services/LogError";

const serviceEvenBackgroundColor = "#F5F4F5",
  serviceOddBackgroundColor = "#FFFFFF";

const schema = yup
  .object({
    duration: yup.number().positive().integer().min(15).required(),
    discount: yup.number().integer().required(),
    isPriceRange: yup.boolean(),
    maxPrice: yup.number().when("isPriceRange", {
      is: (value) => value === true,
      then: (schema) =>
        schema.positive().integer().moreThan(yup.ref("minPrice")).required(),
      otherwise: (schema) => schema.nullable(),
    }),
    minPrice: yup.number().when("isPriceRange", {
      is: (value) => value === true,
      then: (schema) => schema.positive().integer().required(),
      otherwise: (schema) => schema,
    }),
    price: yup
      .number()
      .notRequired()
      .nullable()
      .when("isPriceRange", {
        is: (value) => value === false,
        then: (schema) => schema.positive().integer().required(),
        otherwise: (schema) => schema,
      }),
  })
  .required();

const styles = {
  headerWrapperStyle: {
    margin: "15px 0",
  },
  editTextStyle: { color: "rgb(96,114,152)" },
  mt2: { mt: 2 },
  inputWrapperStyle: { p: 1, width: "100%" },
  mr1: { mr: 1 },
  minFormFlexStyle: { m: 1, flex: 1 },
  serviceEvenStyle: {
    backgroundColor: serviceEvenBackgroundColor,
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: 2,
  },
  serviceOddStyle: {
    backgroundColor: serviceOddBackgroundColor,
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: 2,
  },
  priceRangeFormStyle: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
};

const ModalStyled = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: 24,
  padding: 40,
}));
const HeaderStyles = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "29px",
  textTransform: "capitalize",
}));

const BACKDROP_PROPS = {
  timeout: 500,
};

function ServicePicker(props) {
  const { name, index, id, parentID, completed, defaultValues } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPriceRangeFlag, setIsPriceRangeFlag] = useState(
    !!defaultValues?.isPriceRange
  );
  const [isLoading, setIsLoading] = useState(false);
  const currencySign =
    queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME])?.currency?.sign ||
    CURRENCY_SYMBOL;

  const {
    headerWrapperStyle,
    editTextStyle,
    mt2,
    inputWrapperStyle,
    mr1,
    minFormFlexStyle,
    serviceEvenStyle,
    serviceOddStyle,
    priceRangeFormStyle,
  } = styles;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const mutation = useMutation(editSalonService, {
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      const oldServices = queryClient.getQueryData([
        CLIENT_SERVICES_DATA_REDUCER_NAME,
      ]);

      const targetService = oldServices?.find(
        (item) => item.id === data.serviceId
      );

      const modifiedService = {
        ...targetService,
        subServices: targetService?.subServices?.map((subService) => {
          if (subService.id === data.id) {
            return data;
          } else return subService;
        }),
      };

      const modifiedServices = oldServices?.map((oldService) => {
        if (oldService.id === data.serviceId) {
          return modifiedService;
        }
        return oldService;
      });

      queryClient.setQueryData(
        [CLIENT_SERVICES_DATA_REDUCER_NAME],
        modifiedServices
      );
      setIsEditModalOpen(false);
    },
  });

  const handleEditClicked = () => {
    setIsEditModalOpen(true);
  };

  const handleClose = () => {
    setIsPriceRangeFlag(false);
    reset();
    setIsEditModalOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      const {
        discount = 0,
        duration,
        isPriceRange,
        maxPrice,
        minPrice,
        price,
      } = data;
      setIsLoading(true);
      const dataOfService = isPriceRange
        ? {
            id,
            duration,
            minPrice,
            maxPrice,
            discount,
            name,
            serviceId: parentID,
          }
        : { id, duration, price, discount, name, serviceId: parentID };

      mutation.mutate({ id, payload: dataOfService });
    } catch (e) {
      logError({
        error: e,
        file: "ServicePicker.jsx",
        methodName: "onSubmit",
        params: { data },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={index % 2 === 0 ? serviceEvenStyle : serviceOddStyle}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {!completed && <WarningAmberIcon color={"warning"} />}
          <Typography>{name}</Typography>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Button variant="text" color="links" onClick={handleEditClicked}>
            {t("edit")}
          </Button>
        </Stack>
      </Stack>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isEditModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={BACKDROP_PROPS}
      >
        <Fade in={isEditModalOpen}>
          <ModalStyled>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                sx={headerWrapperStyle}
              >
                <HeaderStyles
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {name}
                </HeaderStyles>
                <CloseIcon onClick={handleClose} />
              </Stack>

              <Divider />

              <Typography id="transition-modal-description" sx={mt2}>
                {t("duration")}
              </Typography>
              <FormControl sx={inputWrapperStyle} variant="outlined">
                <OutlinedInput
                  margin="none"
                  id="outlined-adornment-duration"
                  endAdornment={
                    <InputAdornment position="end">
                      {t("minutes")}
                    </InputAdornment>
                  }
                  aria-describedby="outlined-duration-helper-text"
                  inputProps={{
                    "aria-label": "duration",
                  }}
                  error={!!errors?.duration?.message}
                  {...register("duration")}
                />
                {!!errors?.duration?.message && (
                  <FormHelperText>{errors?.duration?.message}</FormHelperText>
                )}
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isPriceRangeFlag} />}
                  label={t("price_range")}
                  {...register("isPriceRange")}
                  onChange={(e) => {
                    setIsPriceRangeFlag(e?.target?.checked);
                  }}
                />
              </FormGroup>
              <FormGroup sx={priceRangeFormStyle}>
                <FormControl sx={minFormFlexStyle} variant="outlined">
                  <OutlinedInput
                    margin="none"
                    id="outlined-adornment-min-price"
                    startAdornment={
                      <InputAdornment position="end" sx={mr1}>
                        {currencySign}
                      </InputAdornment>
                    }
                    aria-describedby="outlined-min-price-helper-text"
                    inputProps={{
                      "aria-label": "minPrice",
                    }}
                    {...register("minPrice")}
                    disabled={!isPriceRangeFlag}
                    error={!!errors?.minPrice?.message}
                  />
                </FormControl>
                <div> - </div>
                <FormControl sx={minFormFlexStyle} variant="outlined">
                  <OutlinedInput
                    margin="none"
                    id="outlined-adornment-max-price"
                    startAdornment={
                      <InputAdornment position="end" sx={mr1}>
                        {currencySign}
                      </InputAdornment>
                    }
                    aria-describedby="outlined-max-price-helper-text"
                    inputProps={{
                      "aria-label": "maxPrice",
                    }}
                    {...register("maxPrice")}
                    disabled={!isPriceRangeFlag}
                    error={!!errors?.maxPrice?.message}
                  />
                </FormControl>
              </FormGroup>
              {(!!errors?.minPrice?.message || !!errors?.maxPrice?.message) && (
                <FormHelperText>
                  {errors?.minPrice?.message || errors?.maxPrice?.message}
                </FormHelperText>
              )}
              <Typography id="transition-modal-description" sx={mt2}>
                {t("price")}
              </Typography>
              <FormControl sx={inputWrapperStyle} variant="outlined">
                <OutlinedInput
                  margin="none"
                  id="outlined-adornment-price"
                  startAdornment={
                    <InputAdornment position="end" sx={mr1}>
                      {currencySign}
                    </InputAdornment>
                  }
                  aria-describedby="outlined-price-helper-text"
                  inputProps={{
                    "aria-label": "price",
                  }}
                  defaultValue={0}
                  {...register("price")}
                  disabled={isPriceRangeFlag}
                  error={!!errors?.price?.message}
                />
                {!!errors?.price?.message && (
                  <FormHelperText>{errors?.price?.message}</FormHelperText>
                )}
              </FormControl>

              <Typography id="transition-modal-description" sx={mt2}>
                {t("discount")}
              </Typography>
              <FormControl sx={inputWrapperStyle} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-discount"
                  aria-describedby="outlined-discount-helper-text"
                  inputProps={{
                    "aria-label": "discount",
                  }}
                  error={!!errors?.discount?.message}
                  defaultValue={0}
                  {...register("discount")}
                  endAdornment={
                    <InputAdornment position="end" sx={mr1}>
                      %
                    </InputAdornment>
                  }
                />
                {!!errors?.duration?.message && (
                  <FormHelperText>{errors?.discount?.message}</FormHelperText>
                )}
              </FormControl>
              <LoadingButton
                type="submit"
                endIcon={<SaveIcon />}
                loading={isLoading}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
              >
                {t("save_and_close")}
              </LoadingButton>
            </form>
          </ModalStyled>
        </Fade>
      </Modal>
    </div>
  );
}

export default ServicePicker;
