import moment from "moment";
import { logError } from "../services/LogError";

export const randomColor = () =>
  Math.floor(Math.random() * 16777215).toString(16);

export const formatAgenda = (agenda) => {
  let agendaList = [];

  try {
    const agendaDateKeys = Object.keys(agenda);
    for (let index = 0; index < agendaDateKeys.length; index++) {
      const date = agenda[agendaDateKeys[index]];
      for (let i = 0; i < date.length; i++) {
        agendaList = [...agendaList, { ...date[i] }];
      }
    }
  } catch (e) {
    logError({
      methodName: "formatAgenda",
      file: "agenda.js",
      error: e,
      params: { agenda },
    });
  }

  return agendaList;
};

export function roundToQuarterHour(time) {
  const roundedTime = moment(time);
  const minutes = roundedTime.minutes();

  // Round down to the nearest quarter-hour
  const roundedMinutes = Math.floor(minutes / 15) * 15;

  // Set the rounded minutes and reset seconds and milliseconds
  roundedTime.minutes(roundedMinutes).seconds(0).milliseconds(0);

  return roundedTime;
}
