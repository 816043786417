import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const styles = {
  boxStyle: { position: "relative", display: "inline-flex" },
  labelWrapperStyle: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function CircularProgressWithLabel(props) {
  const { value, isFinalStep = false, ...restOfProps } = props;
  const { boxStyle, labelWrapperStyle } = styles;
  return (
    <Box sx={boxStyle}>
      <CircularProgress
        variant="determinate"
        value={value}
        {...restOfProps}
        color={isFinalStep ? "success" : "inherit"}
      />
      <Box sx={labelWrapperStyle}>
        <Typography
          variant="caption"
          component="div"
          color={isFinalStep ? "green" : "text.secondary"}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
