import colors from "./colors";
export default {
  primary: {
    contrastText: colors.white.main,
  },
  secondary: {},
  success: {
    ...colors.green,
    contrastText: colors.white.main,
  },
  warning: {
    ...colors.orange,
    contrastText: colors.white.main,
  },
  info: {
    ...colors.blue,
    contrastText: colors.white.main,
  },
  error: {
    ...colors.red,
    contrastText: colors.white.main,
  },
  grey: {
    contrastText: colors.white.main,
  },
};
