import React, { useState, useEffect, useMemo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

import { useLanguageContext } from "../../../../common/Language/useLanguageContext";
import { logError } from "../../../../common/services/LogError";
import SelectField from "../../../../common/Form/Field/SelectField";
import { AGENDA_REDUCER_NAME } from "../../constants";
import BackgroundLetterAvatar from "../../../../common/components/BackgroundLetterAvatar/BackgroundLetterAvatar";
import TimePickerComponent from "../../../../common/Form/Field/TimePicker";
import DeleteServiceDialog from "./components/DeleteServiceDialog";
import {
  CLIENT_SERVICES_DATA_REDUCER_NAME,
  LIST_MEMBERS_ALL_REDUCER_NAME,
} from "../../../../common/constants";
import AutocompleteComponent from "../../../../common/Form/Field/Autocomplete";
import {
  updateAppointmentService,
  addAppointmentService,
  deleteAppointmentService,
} from "../../api";
import useCustomSnackbar from "../../../../common/components/Snackbar/useSnackbar";

const styles = {
  backBtnStyle: { color: "#000000", fontSize: 32 },
  mr1: { mr: 1 },
  inputLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: " #1A1A1A",
    marginBottom: 0.5,
  },
  inputStyle: { height: 45 },
  actionButtonStyle: { padding: "12px" },
};

const validationSchema = yup.object().shape({
  service: yup.object().required(),
  price: yup.number().positive().integer().required(),
  from: yup.string().required(),
  duration: yup.number().positive().integer().min(15).required(),
  memberId: yup.string().required(),
});

const memberOptions = (options) => {
  return options?.map((item) => ({
    ...item,
    key: item.userId,
    value: item.userId,
    label: item.displayName,
  }));
};

const renderTypeOptions = ({ options }) => {
  return options?.map((option) => (
    <MenuItem key={option.key} value={option.value}>
      <Stack direction="row" alignItems="center" pr={2}>
        {!!option.imageUrl && option.imageUrl !== "" ? (
          <Avatar alt="imageUrl" src={option.imageUrl} />
        ) : (
          <BackgroundLetterAvatar name={option.label} />
        )}
        <Box pl={2}>
          <ListItemText primary={option.label} />
        </Box>
      </Stack>
    </MenuItem>
  ));
};

function generateDurationOptions() {
  const options = [];
  let duration = moment.duration(15, "minutes");

  while (duration.asHours() <= 3) {
    const hours = duration.hours();
    const minutes = duration.minutes();

    let label;
    if (hours > 0) {
      label = `${hours} hour`;
      if (hours > 1) {
        label += "s";
      }
      if (minutes > 0) {
        label += ` ${minutes} minute`;
        if (minutes > 1) {
          label += "s";
        }
      }
    } else {
      label = `${minutes} minute`;
      if (minutes > 1) {
        label += "s";
      }
    }

    options.push({
      value: duration.asMinutes(),
      label,
    });

    duration.add(5, "minutes");
  }

  return options;
}

const dropdownOptions = generateDurationOptions();

function roundUpToNearest15Minutes() {
  let now = moment();
  let remainder = 15 - (now.minute() % 15);
  if (remainder === 15) {
    return now.clone().startOf("hour").add(1, "hour").toDate();
  } else {
    return now.clone().add(remainder, "minutes").startOf("minute").toDate();
  }
}

function DrawerAppointmentEditServiceTab(props) {
  const { service, handleBackCb = () => {}, appointmentId, startTime } = props;

  const {
    currency,
    name,
    price,
    minPrice,
    maxPrice,
    discount,
    duration,
    from,
    to,
    id,
    member,
    memberId,
  } = service || {};

  const { t } = useTranslation();
  const { language } = useLanguageContext();
  const queryClient = useQueryClient();
  const snackbar = useCustomSnackbar();

  const members = queryClient.getQueryData([LIST_MEMBERS_ALL_REDUCER_NAME]);
  const clientServicesWithMembers = queryClient.getQueryData([
    CLIENT_SERVICES_DATA_REDUCER_NAME,
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const servicesFlattedArray = useMemo(() => {
    const allServices = [];

    clientServicesWithMembers?.forEach((service) => {
      service?.subServices?.forEach((subService) => {
        const { id, name } = subService;
        let formattedService = {
          id,
          label: name,
          value: id,
          parent: service.name,
          parentId: service.id,
        };
        allServices.push(formattedService);
      });
    });

    return allServices;
  }, [clientServicesWithMembers]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      price: 0,
      duration: 0,
      memberId: "",
      from: null,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = methods;

  const watchedService = watch("service");

  useEffect(() => {
    const initializeForm = () => {
      if (!service) {
        return;
      }
      // Sample inputs
      const from = moment(service.from);
      const to = moment(service.to);

      // Calculate duration
      const duration = moment.duration(to.diff(from));

      // Get duration in minutes
      const diffAsMinutes = Math.floor(duration.asMinutes());

      const initialService = {
        id: service?.serviceId,
        label: service?.name,
        parent: service?.parentName,
        parentId: service?.parentId,
        value: service?.serviceId,
      };

      reset({
        service: initialService,
        price: service.price,
        duration: diffAsMinutes,
        memberId: service.memberId,
        from: !!service.from
          ? moment(service.from).toDate()
          : roundUpToNearest15Minutes(),
      });
    };
    initializeForm();
  }, [reset, service]);

  useEffect(() => {
    const fillInitialFormWithServiceData = () => {
      try {
        const servicesFlat = clientServicesWithMembers
          .map((item) => item?.subServices)
          .flat();

        const selectedService = servicesFlat.find(
          (item) => item?.id === watchedService?.value
        );

        if (!selectedService || !!service.id) {
          return;
        }

        const memberId = selectedService.members[0]?.memberId;
        setValue("price", selectedService.price);
        setValue("duration", selectedService.duration);
        setValue("memberId", memberId);
      } catch (error) {
        logError({
          methodName: "onSubmit",
          file: "DrawerAppointmentEditServiceTab.jsx",
          error,
          params: {},
        });
      }
    };
    fillInitialFormWithServiceData();
  }, [clientServicesWithMembers, watchedService, setValue]);

  const handleBack = () => {
    handleBackCb();
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const {
        price,
        duration,
        memberId,
        from,
        service: innerService,
      } = data || {};

      const momentFrom = moment(from);
      const toDate = moment(from).clone().add(duration, "minutes");

      const startDate = moment(startTime)
        .set({
          minute: momentFrom.get("minute"),
          hour: momentFrom.get("hour"),
          second: 0,
          millisecond: 0,
        })
        .toDate();

      const endDate = moment(startTime)
        .set({
          minute: toDate.get("minute"),
          hour: toDate.get("hour"),
          second: 0,
          millisecond: 0,
        })
        .toDate();

      const dataToSend = {
        memberId,
        price,
        startDate,
        endDate,
        appointmentId,
        serviceKey: innerService.id,
      };

      !!service.id
        ? await updateAppointmentService(dataToSend)
        : await addAppointmentService(dataToSend);
      queryClient.invalidateQueries([appointmentId]);
      queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);

      handleBack();
      snackbar.showSuccess(t("edit_service_successfully"));
    } catch (e) {
      if (e?.response?.status === 400) {
        snackbar.showError(
          !!e?.response?.data?.message
            ? e?.response?.data?.message
            : t("edit_service_failed")
        );
      }
      logError({
        methodName: "onSubmit",
        file: "DrawerAppointmentEditServiceTab.jsx",
        error: e,
        params: { data },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteService = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteServiceCb = async () => {
    try {
      setIsDeleteDialogOpen(false);
      await deleteAppointmentService({
        appointmentId,
        serviceKey: service.serviceId,
      });
      queryClient.invalidateQueries([appointmentId]);
      queryClient.invalidateQueries([AGENDA_REDUCER_NAME]);
      handleBack();
      snackbar.showSuccess(t("delete_service_successfully"));
    } catch (error) {
      snackbar.showError(t("delete_service_failed"));
      logError({
        methodName: "handleDeleteServiceCb",
        file: "DrawerAppointmentEditServiceTab.jsx",
        error,
        params: { appointmentId, serviceKey: service.serviceId },
      });
    }
  };

  return (
    <Box paddingY={2} paddingX={3}>
      <IconButton onClick={handleBack} sx={{ padding: 0 }}>
        {language === "ar" ? (
          <ArrowForwardIcon sx={styles.backBtnStyle} />
        ) : (
          <ArrowBackIcon sx={styles.backBtnStyle} />
        )}
      </IconButton>
      <Typography variant="h5" marginBottom={2} marginTop={1}>
        {!!service.serviceId ? `${t("edit")} ${name}` : `${t("add_service")}`}
      </Typography>

      <FormProvider {...methods}>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputLabel sx={styles.inputLabel}>{`${t(
                "service"
              )}`}</InputLabel>

              <AutocompleteComponent
                name="service"
                options={servicesFlattedArray}
                disableClearable
                disabled={!!service.serviceId}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={styles.inputLabel}>{`${t("price")}`}</InputLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors?.price?.message}
              >
                <OutlinedInput
                  margin="none"
                  id="outlined-adornment-price"
                  startAdornment={
                    <InputAdornment position="end" sx={styles.mr1}>
                      {currency?.sign}
                    </InputAdornment>
                  }
                  aria-describedby="outlined-price-helper-text"
                  inputProps={{
                    "aria-label": "price",
                  }}
                  defaultValue={0}
                  {...register("price")}
                  error={!!errors?.price?.message}
                />
                {!!errors?.price?.message ? (
                  <FormHelperText>{errors?.price?.message}</FormHelperText>
                ) : (
                  <Box height={22} />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={styles.inputLabel}>{`${t(
                "start_time"
              )}`}</InputLabel>
              <TimePickerComponent name="from" defaultValue={null} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={styles.inputLabel}>{`${t(
                "duration"
              )}`}</InputLabel>
              <SelectField
                name="duration"
                options={dropdownOptions}
                disableClearable={true}
                fullWidth
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={styles.inputLabel}>{`${t("member")}`}</InputLabel>
              <SelectField
                name="memberId"
                options={memberOptions(members)}
                disableClearable={true}
                fullWidth
                renderOption={renderTypeOptions}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={3}
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="large"
                  onClick={handleDeleteService}
                  sx={styles.actionButtonStyle}
                  disabled={!service.serviceId}
                >
                  <DeleteIcon />
                </Button>

                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  size="large"
                  onClick={() => handleSubmit(onSubmit)()}
                  fullWidth
                  sx={styles.actionButtonStyle}
                >
                  {t("save")}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <DeleteServiceDialog
        isOpen={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        onConfirmCb={handleDeleteServiceCb}
        service={service}
      />
    </Box>
  );
}

export default DrawerAppointmentEditServiceTab;
