import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";

const styles = {
  selectDefaultStyles: { padding: 0.5 },
};
const defaultMenu = ({ options }) => {
  return options.map((option) => (
    <MenuItem key={option.id} value={option.value} disabled={option.disabled}>
      {option.label}
    </MenuItem>
  ));
};
const SelectField = (props) => {
  const {
    name,
    defaultValue,
    options,
    errors = {},
    errorHeight = 22,
    renderOption = defaultMenu,
    fullWidth = false,
    ...rest
  } = props;

  const { control, setValue } = useFormContext();

  const onChange = useCallback((e) => {
    setValue(name, e.target.value);
  }, []);

  const { selectDefaultStyles } = styles;

  return (
    <FormControl
      fullWidth={fullWidth}
      component="fieldset"
      error={!!errors[name]}
    >
      <FormGroup>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <Select
                {...rest}
                {...field}
                onChange={onChange}
                fullWidth
                sx={selectDefaultStyles}
              >
                {renderOption({ options })}
              </Select>
            );
          }}
        />
      </FormGroup>
      {!!errors[name]?.message ? (
        <FormHelperText>{errors[name]?.message}</FormHelperText>
      ) : (
        <Box height={errorHeight} />
      )}
    </FormControl>
  );
};
export default SelectField;
