import { SCOPES } from "../common/constants";

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function intersectionRoutes(routeRoles, userRoles) {
  const filteredArray = routeRoles.filter((value) => userRoles.includes(value));
  return filteredArray;
}

export function intersectionScopes(routeScopes, userScopes) {
  if (routeScopes?.length === 0) {
    return true;
  }
  return routeScopes.some((routeScope) => userScopes.includes(routeScope));
}

export function getDefaultRouteBasedOnPermission(scopes) {
  if (!scopes || !Array.isArray(scopes)) {
    return "/";
  }
  if (scopes.includes(SCOPES.DASHBOARD)) {
    return "/dashboard";
  } else if (scopes.includes(SCOPES.APPOINTMENTS)) {
    return "/my-calendar";
  } else if (scopes.includes(SCOPES.CUSTOMERS)) {
    return "/my-customers";
  } else if (scopes.includes(SCOPES.OFFERS)) {
    return "/my-offers";
  } else if (scopes.includes(SCOPES.SERVICES)) {
    return "/assign-services";
  } else if (scopes.includes(SCOPES.PAYMENTS)) {
    return "/payments";
  } else {
    return "/"; // If none of the specified values are found
  }
}
