import React, { useState, useCallback, useMemo } from "react";
import Picker from "@emoji-mart/react";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "@emotion/styled";
import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { useLanguageContext } from "../../Language/useLanguageContext";
import { Typography } from "@mui/material";

const removeFirstMatch = (arr, itemToRemove) => {
  const index = arr.indexOf(itemToRemove);
  if (index !== -1) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }
  return arr;
};

const styles = {
  colorWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    cursor: "pointer",
  },
};
function EmojiPicker(props) {
  const {
    name,
    defaultValue,
    whitespace = "normal",
    placement = "bottom-end",
    maxWidth = "none",
    pt = 0,
    pl = 0,
    pr = 0,
    justifyContent = "flex-start",
    pb = 0,
    border = "none",
    borderRadius = 0,
    color = "black",
    iconComponent: IconComponent = KeyboardArrowDownIcon,
  } = props;

  const sxWithColor = useMemo(() => ({ color: color }), [color]);

  const { language } = useLanguageContext();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const { setValue, watch } = useFormContext();
  const emojis = watch(name);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  }, []);

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOnEmojiSelected = useCallback(
    (data) => {
      const { shortcodes } = data || {};

      const emojisList = !!emojis ? [...emojis, shortcodes] : [shortcodes];
      setValue(name, emojisList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [emojis]
  );

  const handleRemoveSelectedEmojis = useCallback(
    (emoji) => {
      setValue(name, removeFirstMatch(emojis, emoji), {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [emojis]
  );

  return (
    <Box
      onClick={handleClick}
      border={border}
      pt={pt}
      pl={pl}
      pr={pr}
      pb={pb}
      borderRadius={borderRadius}
    >
      <Box
        justifyContent={justifyContent}
        onClick={handleClick}
        maxWidth={maxWidth}
        sx={styles.colorWithIcon}
      >
        <Stack
          maxWidth={"100px"}
          direction="row"
          alignItems={"center"}
          gap={0.3}
          flexWrap={"wrap"}
        >
          {emojis?.length > 0 ? (
            emojis?.map((emoji, index) => {
              return (
                <em-emoji
                  onClick={() => {
                    handleRemoveSelectedEmojis(emoji);
                  }}
                  key={`${emoji}-${index}`}
                  shortcodes={emoji}
                ></em-emoji>
              );
            })
          ) : (
            <Typography color={color} fontSize={12} whiteSpace={whitespace}>
              {t("select_emoji")}
            </Typography>
          )}
        </Stack>
        <IconComponent sx={sxWithColor} />
      </Box>
      <StyledPopper
        open={isOpen}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade in={isOpen} {...TransitionProps} timeout={350}>
            <div>
              <ClickAwayListener onClickAway={handleClickOutside}>
                <div>
                  <Picker
                    onEmojiSelect={handleOnEmojiSelected}
                    locale={language}
                  />
                </div>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </StyledPopper>
    </Box>
  );
}

export default EmojiPicker;

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
}));
