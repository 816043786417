import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ar } from "yup-locales";
import { setLocale, defaultLocale } from "yup";
import { useNavigate } from "react-router-dom";

import {
  fetchClientData,
  fetchMemberData,
  getSalonMembers,
  getSalonMember,
  getSalonSubscription,
  getAllMembers,
} from "../api/clientAPI";
import {
  CLIENT_DATA_REDUCER_NAME,
  MEMBER_DATA_REDUCER_NAME,
  LIST_MEMBERS_REDUCER_NAME,
  LIST_MEMBERS_ALL_REDUCER_NAME,
  PUBLIC_ROUTES,
  CLIENT_SUBSCRIPTION_DATA,
} from "../constants";
import Loader from "../components/Loader";
import { useAuthContext } from "../Auth/useAuthContext";
import { useLanguageContext } from "../Language/useLanguageContext";
import { useSettingsContext } from "../Settings/useSettingsContext";
import {
  getHeaders,
  getUser,
  setUser as localStorageSetUser,
  getNotificationSound,
} from "../Auth/utils";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import httpClient from "../../http/HttpClient";
import {
  AuthorizeFederatedIdentity,
  checkIfAWSUserIsLoggedIn,
} from "../services/awsService";
import { silentLogin } from "../api/systemAPI";
import { getBrowserType } from "../utils/browser";
import { logError } from "../services/LogError";
import Wizard from "./Wizard/Wizard";
import { ROLES } from "../../routes/constants";
import WebSocket from "./WebSocket/WebSocket";
import RemoveMembersDialog from "./RemoveMembersDialog/RemoveMembersDialog";

const yupDefaultLocale = {
  mixed: { ...defaultLocale.mixed },
  string: { ...defaultLocale.string },
  number: { ...defaultLocale.number },
  date: { ...defaultLocale.date },
  boolean: { ...defaultLocale.boolean },
  object: { ...defaultLocale.object },
  array: { ...defaultLocale.array },
};

function MainLayout(props) {
  const { children } = props;
  const location = useLocation();
  const { i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isWizardShown, setIsWizardShown] = useState(false);
  const [isDowngradeWizardShown, setIsDowngradeWizardShown] = useState(false);

  const { user, setUser } = useAuthContext();
  const { setLanguage } = useLanguageContext();
  const { setSettings } = useSettingsContext();

  const localStorageUser = !!getUser() && JSON.parse(getUser());

  const currentUser = user || localStorageUser;

  let isPublicRoute =
    PUBLIC_ROUTES.includes(location.pathname) ||
    (location.pathname === "/" && !currentUser);

  const {
    isFetching: isFetchingClient,
    isLoading: isLoadingClientData,
    data,
  } = useQuery(
    [CLIENT_DATA_REDUCER_NAME],
    async () => {
      try {
        const data = await fetchClientData();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchClientData",
          file: "MainLayout.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: !!currentUser,
    }
  );

  const {
    isFetching: isFetchingClientFetching,
    isLoading: isLoadingClientSubscriptionData,
    data: subscriptionData,
  } = useQuery(
    [CLIENT_SUBSCRIPTION_DATA],
    async () => {
      try {
        const data = await getSalonSubscription();
        if (!data.hasActiveSubscription) {
          navigate("/renew-membership");
        }

        return data;
      } catch (error) {
        logError({
          methmodName: "getSalonSubscription",
          file: "MainLayout.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: !!currentUser,
    }
  );

  const {
    isFetching: isFetchingMember,
    isLoading: isLoadingMember,
    data: memberData,
  } = useQuery(
    [MEMBER_DATA_REDUCER_NAME, user],
    async () => {
      try {
        const data = await fetchMemberData();
        return data;
      } catch (error) {
        logError({
          methmodName: "fetchMemberData",
          file: "MainLayout.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: !!currentUser,
      onSuccess: async (data) => {
        setUser({
          ...currentUser,
          roles: data?.roles || [],
          scopes: data?.scopes || [],
          id: data?.id ?? "",
        });

        localStorageSetUser(
          JSON.stringify({
            ...currentUser,
            roles: data?.roles || [],
            scopes: data?.scopes || [],
            id: data?.id ?? "",
          })
        );

        try {
          const awsLoggedInUser = await checkIfAWSUserIsLoggedIn();

          const awsUser =
            !awsLoggedInUser && (await AuthorizeFederatedIdentity());
        } catch (error) {
          console.log("error", error);
        }
      },
    }
  );

  const {
    isFetching,
    isLoading: isLoadingMembers,
    data: members,
  } = useQuery(
    [LIST_MEMBERS_REDUCER_NAME],
    async () => {
      try {
        const data =
          !!user &&
          Array.isArray(user?.roles) &&
          !!user.roles?.includes(ROLES.OWNER)
            ? await getSalonMembers()
            : await getSalonMember();
        return data;
      } catch (error) {
        logError({
          methmodName: "getSalonMembers",
          file: "MainLayout.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: !!user,
    }
  );

  const {
    isFetching: isFetchingAllMembers,
    isLoading: isLoadingAllMembers,
    data: allMembers,
  } = useQuery(
    [LIST_MEMBERS_ALL_REDUCER_NAME],
    async () => {
      try {
        const data = await getAllMembers();
        return data;
      } catch (error) {
        logError({
          methmodName: "getAllMembers",
          file: "MainLayout.jsx",
          error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
        });
      }
    },
    {
      enabled: !!user,
    }
  );

  useEffect(() => {
    const initLanguage = async () => {
      try {
        const localStorageLanguage = window?.localStorage?.getItem("language");
        const token = getHeaders();

        const authorization = !!token
          ? { Authorization: `Bearer ${token}` }
          : {};

        httpClient.headers = {
          ...authorization,
          "content-type": "application/json",
          "Accept-TimeZone": moment.tz.guess(),
          "Accept-Language": localStorageLanguage,
        };

        await i18n.changeLanguage(localStorageLanguage);
        const direction =
          !localStorageLanguage || localStorageLanguage === "en"
            ? "ltr"
            : "rtl";
        document.body.dir = direction;
        theme.direction = direction;
        setLanguage(localStorageLanguage);
        setLocale(localStorageLanguage === "ar" ? ar : yupDefaultLocale);

        const notifcationSound = getNotificationSound();
        setSettings({ notificationSound: Boolean(notifcationSound) });
      } catch (error) {
        logError({
          methodName: "initLanguage",
          file: "MainLayout.jsx",
          error,
        });
      }
    };
    initLanguage();
  }, []);

  useEffect(() => {
    !!memberData?.id &&
      silentLogin({
        apiLevel: "1",
        appType: "CLIENT",
        buildNumber: 1,
        buildVersion: process.env.REACT_APP_VERSION,
        deviceId: `${getBrowserType()}-${memberData.id}`,
        deviceOs: "WEB",
        // deviceToken: deviceToken,
        model: getBrowserType(),
        osVersion: navigator.userAgent,
      })
        .then((data) => {
          const { showWizard, showDowngradeWizard } = data || {};
          setIsWizardShown(!!showWizard);
          setIsDowngradeWizardShown(!!showDowngradeWizard);
        })
        .catch((error) => {
          logError({
            methodName: "silentLogin",
            file: "MainLayout.jsx",
            error,
          });
        });
  }, [memberData]);

  const handleCloseWizard = () => {
    setIsWizardShown(false);
  };
  const handleCloseDowngradeWizard = () => {
    setIsDowngradeWizardShown(false);
  };

  if (
    !isPublicRoute &&
    (isFetchingClient ||
      isLoadingMember ||
      isLoadingClientData ||
      isFetchingMember ||
      isLoadingMembers ||
      isLoadingClientSubscriptionData ||
      isFetchingClientFetching ||
      isLoadingAllMembers ||
      isFetchingAllMembers ||
      !user?.roles)
  ) {
    return <Loader />;
  }

  return (
    <>
      {children}
      {!!isDowngradeWizardShown && (
        <RemoveMembersDialog handleCloseWizard={handleCloseDowngradeWizard} />
      )}
      {!!isWizardShown && <Wizard handleCloseWizard={handleCloseWizard} />}
      <WebSocket />
    </>
  );
}

export default MainLayout;
