import React, { useCallback, createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ListAltIcon from "@mui/icons-material/ListAlt";
import EditIcon from "@mui/icons-material/Edit";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Input from "@mui/material/Input";
import Modal from "@mui/material/Modal";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import groupBy from "lodash/groupBy";
import { useQueryClient } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

import { COUPON_TYPES, TOTAL_PRICE_TYPE } from "../constants";
import { roundNumber } from "../../../common/utils/common";
import ReservationServices from "./Reservation/Services";
import { updateAppointment } from "../api";
import {
  CLIENT_DATA_REDUCER_NAME,
  CURRENCY_SYMBOL,
} from "../../../common/constants";
import { logError } from "../../../common/services/LogError";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const schema = yup
  .object({
    service: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required("form_required"),
          price: yup.number("form_invalid").min(0).required("form_required"),
        })
      )
      .min(1, "form_at_least_1")
      .required("form_required"),
  })
  .required();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width: 200,
  },
}));

function Services(props) {
  const {
    list,
    totalPrice,
    totalPriceType,
    couponType,
    totalMinPrice,
    totalMaxPrice,
    couponFixedValue,
    originalTotalPrice,
    credits,
    coupon,
    appointmentId,
    appointmentDate,
    appointmentStartTime,
    totalAttendees,
    sections,
    isEditExternalServicesDisabled = false,
    handleAddOneServiceCb = () => {},
    handleEditServiceClickedCb = () => {},
    isPackage,
  } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const currencySign =
    queryClient.getQueryData([CLIENT_DATA_REDUCER_NAME])?.currency?.sign ||
    CURRENCY_SYMBOL;

  const appointmentOriginalData = queryClient.getQueryData([appointmentId]);

  const submitRef = createRef();
  const methods = useForm({
    mode: "all",
    defaultValues: {
      service: list,
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = methods;

  const { fields, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "service", // unique name for your Field Array
  });

  const [open, setOpen] = useState(false);
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isSavingService, setIsSavingService] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleClickOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleAgreeConfirmation = () => {
    submitRef.current?.click();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getPriceAfterDiscount = (
    price,
    discount,
    isRangePrice = false,
    minPrice,
    maxPrice,
    prefix = currencySign
  ) => {
    if (discount === 100) {
      return t("free");
    } else if (!price && !isRangePrice) {
      return `${discount}% ${t("free")}`;
    }
    let minPriceAfterDiscount = 0,
      maxPriceAfterDiscount = 0;

    if (isRangePrice) {
      minPriceAfterDiscount =
        Math.round((minPrice - (minPrice * discount) / 100) * 100) / 100;
      maxPriceAfterDiscount =
        Math.round((maxPrice - (maxPrice * discount) / 100) * 100) / 100;
    }
    const priceAfterDiscount =
      Math.round((price - (price * discount) / 100) * 100) / 100;

    return isRangePrice
      ? `${prefix}${minPriceAfterDiscount} ${t(
          "to"
        )} ${prefix}${maxPriceAfterDiscount}`
      : `${prefix}${priceAfterDiscount}`;
  };

  const getStrippedPriceAfterDiscount = (
    price,
    discount,
    isRangePrice = false,
    minPrice,
    maxPrice,
    prefix = currencySign
  ) => {
    if (discount === 100) {
      return 0;
    } else if (!price && !isRangePrice) {
      return 0;
    }
    let minPriceAfterDiscount = 0,
      maxPriceAfterDiscount = 0;

    if (isRangePrice) {
      minPriceAfterDiscount =
        Math.round((minPrice - (minPrice * discount) / 100) * 100) / 100;
      maxPriceAfterDiscount =
        Math.round((maxPrice - (maxPrice * discount) / 100) * 100) / 100;
    }
    const priceAfterDiscount =
      Math.round((price - (price * discount) / 100) * 100) / 100;

    return isRangePrice ? minPriceAfterDiscount : priceAfterDiscount;
  };

  const renderServicePrice = useCallback((service, prefix = currencySign) => {
    if (!!service.price || (!!service.discount && service.discount === 100)) {
      if (
        !!service.discount &&
        service.discount > 0 &&
        service.discount < 100
      ) {
        return (
          <Stack direction="row" gap="12px">
            <Box sx={styles.discountPriceStyles}>
              {prefix}
              {service.price}
            </Box>
            <Box> {getPriceAfterDiscount(service.price, service.discount)}</Box>
          </Stack>
        );
      }
      return (
        <div> {getPriceAfterDiscount(service.price, service.discount)}</div>
      );
    } else if (!!service.minPrice && !!service.maxPrice) {
      if (service.discount !== 0) {
        return (
          <Stack direction="row" gap="12px">
            <Box sx={styles.discountPriceStyles}>{`${prefix}${
              service.minPrice
            } ${t("to")} ${service.maxPrice}`}</Box>
            <Box>
              {getPriceAfterDiscount(
                service.price,
                service.discount,
                true,
                service.minPrice,
                service.maxPrice
              )}
            </Box>
          </Stack>
        );
      }
      return (
        <Box>{`${prefix}${service.minPrice} ${t("to")}  ${prefix}${
          service.maxPrice
        }`}</Box>
      );
    } else {
      if (service.price === 0) {
        return (
          <Stack direction="row" gap="12px">
            <Box>{t("free")}</Box>
          </Stack>
        );
      } else if (service.discount) {
        return (
          <Stack direction="row" gap="12px">
            <Box>{t("private_price")}</Box>
            <Box> {`${service.discount}% ${t("off")}`}</Box>
          </Stack>
        );
      }

      return <div>{t("private_price")}</div>;
    }
  }, []);

  const renderStrippedServicePrice = useCallback(
    (service, prefix = currencySign) => {
      if (!!service.price || (!!service.discount && service.discount === 100)) {
        if (
          !!service.discount &&
          service.discount > 0 &&
          service.discount < 100
        ) {
          return getStrippedPriceAfterDiscount(service.price, service.discount);
        }
        return getStrippedPriceAfterDiscount(service.price, service.discount);
      } else if (!!service.minPrice && !!service.maxPrice) {
        if (service.discount !== 0) {
          return getStrippedPriceAfterDiscount(
            service.price,
            service.discount,
            true,
            service.minPrice,
            service.maxPrice
          );
        }
        return service.minPrice;
      } else {
        if (service.discount) {
          return 0;
        }

        return 0;
      }
    },
    []
  );

  const renderTotalPrice = useCallback(
    (prefix = currencySign) => {
      try {
        if (totalPriceType === TOTAL_PRICE_TYPE.NONE) {
          if (couponType === COUPON_TYPES.DISCOUNT) {
            return (
              <Stack direction="row" gap="12px">
                <Box>{t("amazing_offer")}</Box>
                <Box>
                  {t("with")} {couponFixedValue} {t("off")}
                </Box>
              </Stack>
            );
          }
          return t("amazing_offer");
        } else if (totalPriceType === TOTAL_PRICE_TYPE.FREE) {
          return t("free");
        } else if (totalPriceType === TOTAL_PRICE_TYPE.RANGE) {
          if (totalMaxPrice <= 0 && totalMinPrice <= 0) {
            return t("free");
          }

          return `${prefix}${roundNumber(
            totalMinPrice,
            2
          )} - ${prefix}${roundNumber(totalMaxPrice, 2)}`;
        } else if (totalPriceType === TOTAL_PRICE_TYPE.FIXED) {
          if (totalPrice <= 0) {
            return t("free");
          } else {
            if (originalTotalPrice && originalTotalPrice !== totalPrice) {
              return (
                <Stack direction="row" gap="12px">
                  <Box sx={styles.discountPriceStyles}>
                    {prefix}
                    {originalTotalPrice}
                  </Box>
                  <Box>
                    {prefix}
                    {roundNumber(totalPrice, 2)}
                  </Box>
                </Stack>
              );
            }

            return ` ${prefix}${roundNumber(totalPrice, 2)}`;
          }
        }

        return t("private_price");
      } catch {
        return t("private_price");
      }
    },
    [totalPrice, totalPriceType, couponType]
  );

  const renderCredits = useCallback(
    (prefix = currencySign) => {
      return (
        <Box sx={styles.creditsStyles}>
          {prefix}
          {credits}
        </Box>
      );
    },
    [credits]
  );

  const renderCoupon = useCallback(
    (prefix = currencySign) => {
      return (
        <Box sx={styles.coupounTextStyle}>
          {coupon.type === COUPON_TYPES.DISCOUNT ? (
            <>{`${coupon.value}% ${t("off")}`}</>
          ) : (
            <>
              <Box>
                {prefix}
                {coupon.value}
              </Box>
            </>
          )}
        </Box>
      );
    },
    [coupon]
  );

  const handleAddService = () => {
    handleOpen();
  };

  const handleStep2SubmitCallback = async (data) => {
    try {
      const theAppointmentDate = moment(
        moment(
          `${moment(appointmentDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )} ${moment(appointmentStartTime, "hh:mm A", "en").format("HH:mm")}`
        )
      );

      const payload = {
        services: data.map((service) => {
          let newService = { id: service.id, discount: service?.discount };
          if (!!service.price || service.price === 0) {
            newService.price = service.price;
          } else {
            newService.minPrice = service.minPrice;
            newService.maxPrice = service.maxPrice;
          }
          return newService;
        }),
        id: appointmentId,
        appointmentDate: theAppointmentDate,
        emojis: appointmentOriginalData?.emojis || [],
        note: appointmentOriginalData?.note,
      };

      await updateAppointment(appointmentId, payload);
      handleClose();
      queryClient.invalidateQueries([appointmentId]);
    } catch (error) {
      logError({
        methodName: "handleStep2SubmitCallback",
        file: "containers/Calendar/components/Services.jsx",
        error,
        params: { data },
      });
    }
  };

  const clientDefaultServicesGrouped = groupBy([...list], function (service) {
    return service.serviceId;
  });
  const clientDefaultServices = Object.keys(clientDefaultServicesGrouped).map(
    (key, index) => ({
      id: key,
      name: clientDefaultServicesGrouped[key][0].parentName,
      subServices: clientDefaultServicesGrouped[key],
    })
  );

  const handleEditServices = () => {
    setIsEditModeActive(true);
  };

  const handleCancelEditService = () => {
    reset({ service: list });
    setIsEditModeActive(false);
  };

  const onSubmit = async (data) => {
    try {
      setIsSavingService(true);
      const theAppointmentDate = moment(
        moment(
          `${moment(appointmentDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          )} ${moment(appointmentStartTime, "hh:mm A", "en").format("HH:mm")}`
        )
      );

      const payload = {
        services: data?.service?.map((service) => {
          let newService = {
            id: service?.id,
            discount: 0,
            memberId: service?.memberId,
          };
          if (!!service.price || service.price === 0) {
            newService.price = service?.price;
          } else {
            newService.minPrice = service.minPrice;
            newService.maxPrice = service.maxPrice;
          }
          return newService;
        }),
        id: appointmentId,
        appointmentDate: theAppointmentDate,
        emojis: appointmentOriginalData?.emojis || [],
        note: appointmentOriginalData?.note,
      };
      await updateAppointment(appointmentId, payload);
      queryClient.invalidateQueries([appointmentId]);
    } catch (error) {
      logError({
        methodName: "onSubmit",
        file: "containers/Calendar/components/Services.jsx",
        error,
        params: { data },
      });
    } finally {
      setIsSavingService(false);
    }
  };

  const handleAddOneService = () => {
    handleAddOneServiceCb();
  };

  return (
    <>
      <Box sx={styles.root}>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Typography sx={styles.header}>{t("requested_services")}</Typography>
          {!isEditModeActive && (
            <Box>
              <Button
                color="inherit"
                startIcon={<AddIcon />}
                onClick={handleAddOneService}
                size="small"
                sx={styles.addRemoveServicesButtonStyle}
                disabled={isPackage}
              >
                {t("add_service")}
              </Button>
              {/* <Button
                color="inherit"
                startIcon={<ListAltIcon />}
                onClick={handleAddService}
                size="small"
                sx={styles.addRemoveServicesButtonStyle}
              >
                {t("add_remove_services")}
              </Button> */}
              <Button
                color="inherit"
                startIcon={<EditIcon />}
                onClick={handleEditServices}
                size="small"
                sx={styles.editServicesPrices}
                disabled={isEditExternalServicesDisabled}
              >
                {t("edit_services_price")}
              </Button>
            </Box>
          )}
        </Stack>
        <Box sx={styles.listWrapper}>
          <FormProvider>
            <form>
              {fields.map((service, index) => {
                return (
                  <Box key={service.id} sx={styles.item}>
                    <Box>{service.name}</Box>
                    <Stack flexDirection={"row"} alignItems="center">
                      {isEditModeActive ? (
                        <>
                          <Box>
                            <Input
                              {...register(`service.${index}.price`)}
                              error={!!errors.service?.[index]?.price}
                              placeholder={
                                !!service?.maxPrice && !!service?.minPrice
                                  ? getPriceAfterDiscount(
                                      service.price,
                                      service.discount,
                                      true,
                                      service.minPrice,
                                      service.maxPrice
                                    )
                                  : undefined
                              }
                              type="number"
                              startAdornment={
                                <InputAdornment position="start">
                                  {currencySign}
                                </InputAdornment>
                              }
                            />
                            {!!errors.service?.[index]?.price && (
                              <FormHelperText
                                error
                                sx={styles.invalidHelperTextStyle}
                              >
                                {t("form_invalid")}
                              </FormHelperText>
                            )}
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          {renderServicePrice(service)}
                          <Button
                            sx={styles.changeBtn}
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            onClick={() => {
                              handleEditServiceClickedCb(service);
                            }}
                          >
                            {t("edit")}
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Box>
                );
              })}
              {isEditModeActive && (
                <>
                  {!!errors.service?.message && (
                    <FormHelperText error sx={styles.invalidHelperTextStyle}>
                      {t(errors.service?.message, { name: t("service") })}
                    </FormHelperText>
                  )}
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"flex-end"}
                  >
                    <Button
                      variant="text"
                      color="inherit"
                      onClick={handleCancelEditService}
                      sx={styles.cancelButtonStyle}
                    >
                      {t("cancel")}
                    </Button>
                    <LoadingButton
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                      loading={isSavingService}
                    >
                      {t("save_changes")}
                    </LoadingButton>
                  </Stack>
                </>
              )}
            </form>
          </FormProvider>
        </Box>
        {!!totalAttendees && totalAttendees > 1 && (
          <Box sx={styles.totalPrice}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              {t("attendees")}
              <LightTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">{t("sections")}</Typography>
                    {sections?.map((section) => {
                      const { id, name, count } = section || {};
                      return (
                        <Stack
                          id={id}
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Box>{name}</Box>
                          <strong>{count}</strong>
                        </Stack>
                      );
                    })}
                  </React.Fragment>
                }
                // placement="right"
              >
                <Button variant="text" color="links">
                  {t("show_info")}
                </Button>
              </LightTooltip>
            </Stack>
            <Box>{totalAttendees}</Box>
          </Box>
        )}
        {!!coupon && (
          <Box sx={styles.coupounWrapper}>
            <Box sx={styles.creditsTileStyle}>{`${t("coupon")} [${t(
              "promo_code"
            )}: ${coupon.promoCode}]`}</Box>
            <Box>{renderCoupon()}</Box>
          </Box>
        )}
        {!!credits && (
          <Box sx={styles.totalPrice}>
            <Box sx={styles.creditsTileStyle}>{t("credits")}</Box>
            <Box>{renderCredits()}</Box>
          </Box>
        )}
        <Box sx={styles.totalPrice}>
          <Box>{t("total_price")}</Box>
          <Box>{renderTotalPrice()}</Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormProvider {...methods}>
            <form>
              <ReservationServices
                ref={submitRef}
                onFinishCb={handleStep2SubmitCallback}
                returnFullData
                clientDefaultServices={clientDefaultServices}
              />
              <Button
                variant="contained"
                onClick={handleClickOpenConfirmationDialog}
              >
                {t("done")}
              </Button>
            </form>
          </FormProvider>
          <Dialog
            open={openConfirmationDialog}
            TransitionComponent={Transition}
            onClose={handleCloseConfirmationDialog}
          >
            <DialogTitle>
              {t("add_remove_appointment_services_title")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("add_remove_appointment_services_description")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmationDialog}>
                {t("cancel")}
              </Button>
              <Button onClick={handleAgreeConfirmation}>{t("confirm")}</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
    </>
  );
}

const styles = {
  root: {
    padding: "12px 0",
  },
  header: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "capitalize",
    color: "#858585",
  },
  listWrapper: {
    padding: "12px 0",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    borderBottom: "2px solid #F2F2F2",
    marginBottom: "10px",
  },
  item: {
    background: "#F4F4F4",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    padding: "12px",
    justifyContent: "space-between",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    // textTransform: "capitalize",
    color: "#000000",
    marginBottom: "10px",
  },
  totalPrice: {
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    padding: "12px",
    justifyContent: "space-between",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    // textTransform: "capitalize",
    color: "#000000",
    marginTop: "12px",
  },
  discountPriceStyles: {
    textDecoration: "line-through",
  },
  creditsStyles: {
    fontSize: "16px",
    fontWeight: "300",
    color: "#5CB85C",
  },
  creditsTileStyle: {
    fontWeight: "300",
  },
  coupounWrapper: {
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    padding: "12px",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "19px",
    justifyContent: "space-between",
    color: "#000000",
    marginTop: "12px",
    flex: 1,
  },
  coupounTextStyle: {
    fontWeight: "300",
    fontSize: "16px",
    color: "#D9534F",
  },
  cancelButtonStyle: { marginRight: 2 },
  invalidHelperTextStyle: { maxWidth: "185px" },
  addRemoveServicesButtonStyle: { fontSize: "0.75rem", mr: 1 },
  editServicesPrices: { fontSize: "0.75rem" },
  changeBtn: {
    paddingInlineStart: 0,
    color: "#4046d6",
    fontSize: "14px",
    textDecoration: "underline",
    height: "19px",
    textTransform: "none",
  },
};

export default Services;
