import httpClient from "../../http/HttpClient";

export const fetchAllServices = () => httpClient.get(`/services`).send();

export const fetchAllSections = () => httpClient.get(`/sections`).send();

export const fetchAllMembertypes = () => httpClient.get(`/member/types`).send();

export const fetchAllCities = () => httpClient.get(`/cities`).send();

export const silentLogin = (payload) =>
  httpClient.post(`/client/silent`).withData(payload).send();

export const contactUs = (payload) =>
  httpClient.post(`/contact-us`).withData(payload).send();
