import React, { memo, useMemo, useCallback } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import SelectField from "../../../common/Form/Field/SelectField";
import { useFormContext } from "react-hook-form";
import { APPOINTMENT_STATUSES_VALUES } from "../constants";
import moment from "moment";
import DateTimePopup from "./DateTimePopup";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useLanguageContext } from "../../../common/Language/useLanguageContext";
import EmojiPicker from "../../../common/Form/Field/EmojiPicker";

const PREFIX = "AppointmentSlideOut";

const classes = {
  servicesWrapper: `${PREFIX}-servicesWrapper`,
  totalPrice: `${PREFIX}-totalPrice`,
  statusSelect: `${PREFIX}-statusSelect`,
  pending: `${PREFIX}-pending`,
  approved: `${PREFIX}-approved`,
  rejected: `${PREFIX}-rejected`,
  rescheduled: `${PREFIX}-rescheduled`,
  item: `${PREFIX}-item`,
  dateTimeItem: `${PREFIX}-dateTimeItem`,
  rightItem: `${PREFIX}-rightItem`,
};

function AppointmentDetails(props) {
  const { data, canEditAppointment } = props;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down(700));

  const { t } = useTranslation();
  const methods = useFormContext();
  const { language } = useLanguageContext();

  const { watch, setValue } = methods;

  const status = watch("status");

  const appointmentDay = watch("selected_day");
  const startDate = watch("startDate");
  const startTime = watch("from");
  const endTime = watch("to");

  const APPOINTMENT_STATUSES = useMemo(() => {
    let options = [
      { label: t("approved"), value: "APPROVED" },
      { label: t("rejected"), value: "REJECTED" },
      // to revert to previous behavior just comment this line and UNCOMMENT shouldRenderLabel line 129
      { label: t("rescheduled"), value: "RESCHEDULED", disabled: true },
    ];

    if (data.status === APPOINTMENT_STATUSES_VALUES.PENDING) {
      options = [...options, { label: t("pending"), value: "PENDING" }];
    }
    return options;
  }, []);

  const appointmentTimeDefaultValues = useMemo(
    () => ({
      selected_day: moment(data?.startTime).toDate(),
      from: moment(data?.startTime).format("hh:mm A"),
      to: moment(data?.endTime).format("hh:mm A"),
      duration: moment(data?.endTime).diff(moment(data?.startTime), "minutes"),
      note: "",
    }),
    []
  );

  const formattedApTime = useMemo(() => {
    return `${moment(startDate, "YYYY-MM-DD")
      .locale(language)
      .format("ddd, MMM DD")} ${t("from")} ${moment(startTime, "hh:mm A")
      .locale(language)
      .format("hh:mm A")} ${t("to")} ${moment(endTime, "hh:mm A")
      .locale(language)
      .format("hh:mm A")}`;
  }, [startTime, endTime, appointmentDay, language]);

  const createdAtTime = useMemo(() => {
    return `${moment(data.created)
      .locale(language)
      .format("YYYY-MM-DD hh:mm A")}`;
  }, [language]);

  const onSubmitAppointmentTimeForm = useCallback((values) => {
    setValue("from", values.from, { shouldDirty: true, shouldValidate: true });
    setValue("to", values.to, { shouldDirty: true, shouldValidate: true });
    setValue(
      "startDate",
      moment(values.selected_day, "ddd, MMM DD").format("YYYY-MM-DD")
    );
    setValue(
      "selected_day",
      moment(values.selected_day, "ddd, MMM DD").format("ddd, MMM DD"),
      { shouldDirty: true, shouldValidate: true }
    );
  }, []);

  const completedAndCanceledAppointments = useMemo(() => {
    return {
      [APPOINTMENT_STATUSES_VALUES.COMPLETED]: {
        label: t("completed"),
        color: theme.colors.green.main,
      },
      [APPOINTMENT_STATUSES_VALUES.CANCELED]: {
        label: t("rejected"),
        color: theme.colors.red.main,
      },
      [APPOINTMENT_STATUSES_VALUES.RESCHEDULED]: {
        label: t("rescheduled"),
        color: theme.colors.orange.main,
      },
    };
  }, [APPOINTMENT_STATUSES_VALUES]);

  const shouldRenderLabel = useMemo(
    () =>
      [
        APPOINTMENT_STATUSES_VALUES.COMPLETED,
        // APPOINTMENT_STATUSES_VALUES.RESCHEDULED,
        APPOINTMENT_STATUSES_VALUES.CANCELED,
      ].includes(data?.status),
    [data]
  );

  const clientTypeNameUtil = useCallback(
    (string) => {
      if (string === "CLIENT") {
        return t("admin");
      } else if (string === "REGULAR") {
        return t("regular_appointment");
      } else if (string === "OFFER") {
        return t("offer");
      }
      return string;
    },
    [t]
  );

  return (
    <AppointmentDetailsComponent className={classes.appointmentDetails}>
      <Flex>
        <div className={classes.item}>
          <div className={classes.item}>
            {shouldRenderLabel ? (
              <Label
                color={completedAndCanceledAppointments[data.status].color}
              >
                {completedAndCanceledAppointments[data.status].label}
              </Label>
            ) : (
              <SelectField
                name="status"
                defaultValue={data?.status}
                options={APPOINTMENT_STATUSES}
                className={clsx(
                  classes.statusSelect,
                  `${PREFIX}-${status?.toLowerCase()}`
                )}
              />
            )}
          </div>
          <GreyTypography>{t("appointment_status")}</GreyTypography>
        </div>
        <div className={classes.rightItem}>
          <DarkTypography>
            {clientTypeNameUtil(data?.type)?.toLowerCase()}
          </DarkTypography>
          <GreyTypography> {t("appointment_type")}</GreyTypography>
        </div>
      </Flex>
      <Flex>
        <div className={classes.item}>
          <div className={classes.dateTimeItem}>
            <DarkTypography>{formattedApTime}</DarkTypography>
            {canEditAppointment && (
              <DateTimePopup
                defaultValues={appointmentTimeDefaultValues}
                onSubmit={onSubmitAppointmentTimeForm}
              />
            )}
          </div>
          <GreyTypography> {t("appointment_time_date")}</GreyTypography>
        </div>
        <div className={classes.rightItem}>
          <DarkTypography>
            <EmojiPicker name="emojis" />
          </DarkTypography>
          <GreyTypography>{t("emoji_picker")}</GreyTypography>
        </div>
      </Flex>
      <div className={classes.rightItem}>
        <DarkTypography>{createdAtTime}</DarkTypography>

        <GreyTypography> {t("created_at")}</GreyTypography>
      </div>
    </AppointmentDetailsComponent>
  );
}

const Label = styled("div")(({ theme, color }) => ({
  height: "35px",
  width: "122px",
  backgroundColor: color,
  color: "#fff",
  borderRadius: 4,
  fontWeight: 700,
  fontSize: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const DarkTypography = styled("div")(({ theme }) => ({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "19px",
  textTransform: "capitalize",
  color: "#000000",
}));

const GreyTypography = styled("div")(({ theme }) => ({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "19px",
  textTransform: "capitalize",
  color: "#858585",
}));

const Flex = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 24,
    justifyContent: "start",
    alignItems: "start",
  },
}));

const AppointmentDetailsStyled = styled("div")(({ theme }) => ({
  marginTop: 16,
  paddingBottom: 16,
  borderBottom: "2px solid #F2F2F2",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  [`& .${classes.servicesWrapper}`]: {
    marginTop: 16,
    borderBottom: "2px solid #F2F2F2",
  },
  [`& .${classes.totalPrice}`]: {
    justifyContent: "space-between",
  },
  [`& .${classes.statusSelect}`]: {
    height: "35px",
    width: "122px",
    borderRadius: 4,
    fontWeight: 700,
    fontSize: 16,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  [`& .${classes.pending}`]: {
    backgroundColor: "rgba(240, 173, 78, 0.2)",
    color: "#F0AD4E",
    "& .MuiSvgIcon-root ": {
      fill: theme.palette.primary.secondary,
    },
  },
  [`& .${classes.approved}`]: {
    backgroundColor: "#5CB85C",
    color: "#fff",
    "& .MuiSvgIcon-root ": {
      fill: "#fff",
    },
  },
  [`& .${classes.rescheduled}`]: {
    backgroundColor: "#F0AD4E",
    color: "#fff",
    "& .MuiSvgIcon-root ": {
      fill: "#fff",
    },
  },
  [`& .${classes.rejected}`]: {
    backgroundColor: "#D9534F",
    color: "#fff",
    "& .MuiSvgIcon-root ": {
      fill: "#fff",
    },
  },
  [`& .${classes.item}`]: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  [`& .${classes.rightItem}`]: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    minWidth: "134px",
  },
  [`& .${classes.dateTimeItem}`]: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2px",
  },
}));

const AppointmentDetailsComponent = ({ ...restProps }) => {
  return <AppointmentDetailsStyled {...restProps} />;
};

export default memo(AppointmentDetails);
