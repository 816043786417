import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

const serviceEvenBackgroundColor = "#F5F4F5",
  serviceOddBackgroundColor = "#FFFFFF";

const styles = {
  serviceEvenStyle: {
    backgroundColor: serviceEvenBackgroundColor,
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: 2,
  },
  serviceOddStyle: {
    backgroundColor: serviceOddBackgroundColor,
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: 2,
  },
};
function EditableServicesListSekelton(props) {
  const { index } = props;
  const { serviceEvenStyle, serviceOddStyle } = styles;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      marginTop={2}
      sx={index % 2 === 0 ? serviceEvenStyle : serviceOddStyle}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Skeleton height={40} width={200} />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Skeleton height={40} width={120}></Skeleton>
      </Stack>
    </Stack>
  );
}

export default EditableServicesListSekelton;
