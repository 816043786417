import { formatAgenda } from "../../common/utils/agenda";
import httpClient from "../../http/HttpClient";
import {
  groupAgendaResponse,
  convertWorkingHoursFromUTC,
  convertAvailabilityFromUTC,
} from "./utils";

export const fetchAgenda = (payload) =>
  httpClient
    .post(`client/appointments/calendar/agenda`)
    .withData(payload)
    .withResponseTransformer((agenda) =>
      groupAgendaResponse(payload.calendarType, formatAgenda(agenda))
    )
    .send();

export const fetchAppointment = (appointmentId) =>
  httpClient.get(`client/appointment/${appointmentId}`).send();

export const updateAppointment = (appointmentId, payload) =>
  httpClient
    .put(`client/appointment/${appointmentId}/edit`)
    .withData(payload)
    .send();

export const updateAppointmentOfferOrPackage = (appointmentId, payload) =>
  httpClient
    .put(`client/appointment/${appointmentId}/external/edit`)
    .withData(payload)
    .send();

export const approveAppointment = (appointmentId) =>
  httpClient.post(`/client/appointment/${appointmentId}/approve`).send();

export const rejectAppointment = (appointmentId) =>
  httpClient.post(`client/appointment/${appointmentId}/reject`).send();

export const cancelAppointment = (appointmentId) =>
  httpClient.post(`client/appointment/${appointmentId}/canceled`).send();

export const deleteAppointment = (appointmentId) =>
  httpClient.delete(`client/appointment/${appointmentId}/delete`).send();

export const completeAppointment = (appointmentId) =>
  httpClient.post(`client/appointment/${appointmentId}/completed`).send();

export const fetchCustomers = (payload) =>
  httpClient.get(`/client/customers`).withParams(payload).send();

export const fetchUserCalendarOverview = (payload) =>
  httpClient
    .post(`/client/calendar/members/availability`)
    .withData(payload)
    .withResponseTransformer((data) =>
      convertAvailabilityFromUTC(data, payload)
    )
    .send();

export const fetchUserCalendarAvailablityOverview = (date) =>
  httpClient.get(`/client/calendar/availability-overview/${date}`).send();

export const addAppointment = (payload) =>
  httpClient.post(`/client/appointment/book`).withData(payload).send();

export const closeDayHour = (payload) =>
  httpClient.post(`client/calendar/slot`).withData(payload).send();

export const fetchSlot = (slotId) =>
  httpClient.get(`/client/calendar/slot/${slotId}`).send();

export const deleteSlot = (slotId) =>
  httpClient.delete(`/client/calendar/slot/${slotId}`).send();

export const getMembersFromServices = (payload) =>
  httpClient.post(`/client/services/members`).withData(payload).send();

export const getMembersWorkingHoursUTC = (payload) =>
  httpClient
    .post(`client/member/shifts/calendar/workingHours`)
    .withData(payload)
    .withResponseTransformer((data) =>
      convertWorkingHoursFromUTC(data, payload)
    )
    .send();

export const getAppointmentFinancialTransactions = (payload) =>
  httpClient
    .post(`client/dashboard/financial/transactions`)
    .withData(payload)
    .send();

export const getCalendarMembers = () =>
  httpClient.get(`/client/calendar/members`).send();

export const addAppointmentService = (payload) =>
  httpClient.post(`/client/appointment/service/add`).withData(payload).send();

export const updateAppointmentService = (payload) =>
  httpClient.post(`/client/appointment/service/edit`).withData(payload).send();

export const deleteAppointmentService = (payload) =>
  httpClient
    .post(`/client/appointment/service/delete`)
    .withData(payload)
    .send();

export const AssignWalkInToAppointment = (payload) =>
  httpClient.post(`/client/appointment/walkin/assign`).withData(payload).send();
