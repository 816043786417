/*eslint no-useless-constructor: off */

import HttpMethod from "./HttpMethods";

class HttpClient extends HttpMethod {
  constructor() {
    super();
  }

  post(url) {
    return new HttpMethod()
      .withSchemaValidation(this.schema)
      .withStore(this.store)
      .withBaseURL(this.baseURL)
      .withHeaders(this.headers)
      .withCache(this.cacheTTL)
      .withOptions(this.options)
      .withMock(this.mock)
      .withFullResponse(this.fullResponse)
      .withRequestTransformer(this.requestTransformer)
      .withResponseTransformer(this.responseTransformer)
      .withData(this.data)
      .withParams(this.params)
      .withBeforeHook(this.beforeHook)
      .withClientErrorHandler(this.onClientError)
      .withMethod("POST")
      .withURL(url);
  }

  get(url) {
    return new HttpMethod()
      .withSchemaValidation(this.schema)
      .withStore(this.store)
      .withBaseURL(this.baseURL)
      .withHeaders(this.headers)
      .withCache(this.cacheTTL)
      .withOptions(this.options)
      .withMock(this.mock)
      .withFullResponse(this.fullResponse)
      .withRequestTransformer(this.requestTransformer)
      .withResponseTransformer(this.responseTransformer)
      .withData(this.data)
      .withParams(this.params)
      .withBeforeHook(this.beforeHook)
      .withClientErrorHandler(this.onClientError)
      .withMethod("GET")
      .withURL(url);
  }

  put(url) {
    return new HttpMethod()
      .withSchemaValidation(this.schema)
      .withStore(this.store)
      .withBaseURL(this.baseURL)
      .withHeaders(this.headers)
      .withCache(this.cacheTTL)
      .withOptions(this.options)
      .withMock(this.mock)
      .withFullResponse(this.fullResponse)
      .withRequestTransformer(this.requestTransformer)
      .withResponseTransformer(this.responseTransformer)
      .withData(this.data)
      .withParams(this.params)
      .withBeforeHook(this.beforeHook)
      .withClientErrorHandler(this.onClientError)
      .withMethod("PUT")
      .withURL(url);
  }

  patch(url) {
    return new HttpMethod()
      .withSchemaValidation(this.schema)
      .withStore(this.store)
      .withBaseURL(this.baseURL)
      .withHeaders(this.headers)
      .withCache(this.cacheTTL)
      .withOptions(this.options)
      .withMock(this.mock)
      .withFullResponse(this.fullResponse)
      .withRequestTransformer(this.requestTransformer)
      .withResponseTransformer(this.responseTransformer)
      .withData(this.data)
      .withParams(this.params)
      .withBeforeHook(this.beforeHook)
      .withClientErrorHandler(this.onClientError)
      .withMethod("PATCH")
      .withURL(url);
  }

  delete(url) {
    return new HttpMethod()
      .withSchemaValidation(this.schema)
      .withStore(this.store)
      .withBaseURL(this.baseURL)
      .withHeaders(this.headers)
      .withCache(this.cacheTTL)
      .withOptions(this.options)
      .withMock(this.mock)
      .withFullResponse(this.fullResponse)
      .withRequestTransformer(this.requestTransformer)
      .withResponseTransformer(this.responseTransformer)
      .withData(this.data)
      .withParams(this.params)
      .withBeforeHook(this.beforeHook)
      .withClientErrorHandler(this.onClientError)
      .withMethod("DELETE")
      .withURL(url);
  }
}

export default HttpClient;
