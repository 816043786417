import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Controller, useFormContext } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

const styles = {
  autocompleteStyle: { marginTop: "8px" },
};
function defaultRenderTags(tags, value, setValue, name) {
  return value?.map((tag) => (
    <StyledTag
      key={tag.id}
      label={tag.label}
      onDelete={() => {
        setValue(
          name,
          value.filter((val) => val.id !== tag.id)
        );
      }}
    />
  ));
}

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <Stack direction={"row"} alignItems={"center"} {...other}>
      <Typography>{label}</Typography>
      <CloseIcon onClick={onDelete} />
    </Stack>
  );
}
Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};
const StyledTag = styled(Tag)(
  ({ theme }) => `
      display: flex;
      align-items: center;
      height: 24px;
      margin: 2px;
      line-height: 22px;
      background-color: ${
        theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
      };
      border: 1px solid ${
        theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"
      };
      border-radius: 2px;
      box-sizing: content-box;
      padding: 0 4px 0 10px;
      outline: 0;
      overflow: hidden;
    
      &:focus {
        border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
        background-color: ${
          theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
        };
      }
    
      & span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    
      & svg {
        font-size: 12px;
        cursor: pointer;
        padding: 4px;
      }
    `
);

function AutocompleteComponent(props) {
  const handleGroupBy = (option) => option.parent;
  const handleGetOptionLabel = (option) => option.label || "";
  const { autocompleteStyle } = styles;

  const {
    name,
    limitTags = 3,
    defaultValue = [],
    options = [],
    isMultiple = false,
    getOptionLabel = handleGetOptionLabel,
    renderOption,
    label = "",
    renderTags = defaultRenderTags,
    disableClearable = false,
    style = { autocompleteStyle },
    ...restOfProps
  } = props;

  const methods = useFormContext();
  const {
    control,
    setValue,
    formState: { errors },
  } = methods ?? {};

  const renderInput = (params) => (
    <TextField {...params} error={!!errors[name]?.message} label={label} />
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        const { onChange, value } = field;

        return (
          <>
            <FormControl
              margin="none"
              fullWidth
              error={!!errors[name]?.message}
            >
              <Autocomplete
                options={options}
                getOptionDisabled={(option) => !!option?.disabled}
                groupBy={handleGroupBy}
                getOptionLabel={getOptionLabel}
                fullWidth
                multiple={isMultiple}
                sx={style}
                renderInput={renderInput}
                disableClearable={disableClearable}
                limitTags={limitTags}
                value={value}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => {
                  onChange(value);
                }}
                renderTags={(tags) => renderTags(tags, value, setValue, name)}
                renderOption={renderOption}
                {...restOfProps}
              />
              {!!errors[name]?.message && (
                <FormHelperText>{errors[name]?.message}</FormHelperText>
              )}
            </FormControl>
          </>
        );
      }}
    />
  );
}

export default AutocompleteComponent;
