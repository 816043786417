import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import accessRights from "./access";
import { ROLES } from "../../routes/constants";

// define abilities
export default function defineAbilityFor(user) {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  if (
    !!user &&
    Array.isArray(user?.roles) &&
    !!user.roles?.includes(ROLES.OWNER)
  ) {
    can(accessRights.admin.action, user?.scopes);
  } else {
    // accessRights.member.action must be changed from backend to match ["read","update",,"delete","all"]
    can(accessRights.member.action, user?.scopes);
  }
  //uncomment if you dont want to delete any products
  //cannot("delete", "Products", { published: true });
  return build();
}
