import moment from "moment";
import groupBy from "lodash/groupBy";
import momentTimezone from "moment-timezone";

import { ENG_DAY_HOUR, START_DAY_HOUR, VIEW_TYPES_VALUES } from "./constants";
import { logError } from "../../common/services/LogError";

export const mapToZIndex = (number) => {
  // Calculate the max range for zIndex
  const range = 1199;

  // Calculate the scale to map the input range (1 to 4000) to the output range (1 to 1199)
  const scale = Math.ceil(4000 / range);

  // Map the number to the corresponding index
  const index = Math.ceil(number / scale);

  return index;
};

export const calculateWorkingHours = (workingHours, salonStartAndEntData) => {
  try {
    const {
      overAllEndWorkingHour,
      overAllStartWorkingHour,
      members: memberWorkingHours,
    } = workingHours || {
      overAllStartWorkingHour: { hour: START_DAY_HOUR, minute: 0 },
      overAllEndWorkingHour: { hour: ENG_DAY_HOUR, minute: 0 },
      members: {},
    };

    const OVERALL_START_WORKING_HOUR_CONST = !!overAllStartWorkingHour
      ? overAllStartWorkingHour?.hour +
        (overAllStartWorkingHour?.minute === 0 ? 0 : 0.5)
      : START_DAY_HOUR;

    const START_DAY_HOUR_CONST =
      salonStartAndEntData?.type === "Scheduled"
        ? salonStartAndEntData?.startTime?.hour -
          (salonStartAndEntData?.startTime?.minute === 0 ? 0.5 : 0.5)
        : START_DAY_HOUR;

    const END_DAY_HOUR_CONST =
      salonStartAndEntData?.type === "Scheduled"
        ? salonStartAndEntData?.endTime?.hour +
          (salonStartAndEntData?.endTime?.minute === 0
            ? 0.5
            : salonStartAndEntData?.endTime?.minute === 45
            ? 1.5
            : 1)
        : ENG_DAY_HOUR + 1;

    const OVERALL_END_WORKING_HOUR_CONST = !!overAllEndWorkingHour
      ? overAllEndWorkingHour?.hour +
        (overAllEndWorkingHour?.minute === 0
          ? 0.5
          : overAllEndWorkingHour?.minute === 45
          ? 1.5
          : 1)
      : ENG_DAY_HOUR;

    const START_DAY_HOUR_CALCULATED = Math.min(
      START_DAY_HOUR_CONST,
      OVERALL_START_WORKING_HOUR_CONST
    );
    const END_DAY_HOUR_CALCULATED = Math.max(
      END_DAY_HOUR_CONST,
      OVERALL_END_WORKING_HOUR_CONST
    );
    return {
      overallStartHour: START_DAY_HOUR_CALCULATED,
      overallEndHour: END_DAY_HOUR_CALCULATED,
    };
  } catch (error) {
    return {
      overallStartHour: START_DAY_HOUR,
      overallEndHour: ENG_DAY_HOUR,
    };
  }
};

export const calculateMemberWorkingHours = (
  workingHours,
  memberId,
  salonStartAndEntData
) => {
  try {
    const memberWorkingHours = workingHours?.members?.find(
      (member) => member.memberId === memberId
    );

    const startHour = {
      hour:
        !!memberWorkingHours && !!memberWorkingHours?.calenderStartWorkingHour
          ? memberWorkingHours?.calenderStartWorkingHour?.hour
          : salonStartAndEntData?.type === "Scheduled"
          ? salonStartAndEntData?.startTime?.hour
          : START_DAY_HOUR,
      minute:
        !!memberWorkingHours && !!memberWorkingHours?.calenderStartWorkingHour
          ? memberWorkingHours?.calenderStartWorkingHour?.minute
          : salonStartAndEntData?.type === "Scheduled"
          ? salonStartAndEntData?.startTime?.minute
          : 0,
    };
    const endHour = {
      hour:
        !!memberWorkingHours && !!memberWorkingHours?.calenderEndWorkingHour
          ? memberWorkingHours?.calenderEndWorkingHour?.hour
          : salonStartAndEntData?.type === "Scheduled"
          ? salonStartAndEntData?.endTime?.hour
          : ENG_DAY_HOUR,
      minute:
        !!memberWorkingHours && !!memberWorkingHours?.calenderEndWorkingHour
          ? memberWorkingHours?.calenderEndWorkingHour?.minute
          : salonStartAndEntData?.type === "Scheduled"
          ? salonStartAndEntData?.endTime?.minute
          : 0,
    };

    return { startHour, endHour };
  } catch (error) {
    return {
      startHour: START_DAY_HOUR,
      endHour: ENG_DAY_HOUR,
    };
  }
};

export const calculateOffBetweenShifts = (shifts, selectedDate) => {
  try {
    const offShifts = [];
    if (!shifts || !Array.isArray(shifts) || shifts.length < 2) {
      return [];
    }
    // sorting the shifts since you might add one shift at 5pm then the other at 10am
    const sortedShifts = [...shifts].sort((a, b) => {
      const timeA = moment().set({
        hour: a.startTime.hour,
        minute: a.startTime.minute,
      });
      const timeB = moment().set({
        hour: b.startTime.hour,
        minute: b.startTime.minute,
      });
      return timeA.diff(timeB);
    });

    for (let i = 0; i < sortedShifts.length - 1; i++) {
      const currentShiftEndTime = moment()
        .hour(sortedShifts[i].endTime.hour)
        .minute(sortedShifts[i].endTime.minute);
      const nextShiftStartTime = moment()
        .hour(sortedShifts[i + 1].startTime.hour)
        .minute(sortedShifts[i + 1].startTime.minute);

      if (nextShiftStartTime.isAfter(currentShiftEndTime)) {
        const offPeriodStart = moment(selectedDate)
          .set({
            hour: sortedShifts[i].endTime.hour,
            minute: sortedShifts[i].endTime.minute,
          })
          .format("HH:mm");

        const offPeriodEnd = moment(selectedDate)
          .set({
            hour: sortedShifts[i + 1].startTime.hour,
            minute: sortedShifts[i + 1].startTime.minute,
          })
          .format("HH:mm");

        offShifts.push({ offPeriodStart, offPeriodEnd });
      }
    }
    return offShifts;
  } catch (error) {
    return [];
  }
};

export const checkIfWithinOffShifts = (
  startDate,
  endDate,
  offPeriods,
  selectedDate
) => {
  try {
    let isWithinOffPeriod = false;

    for (const offPeriod of offPeriods) {
      const offPeriodStart = moment(selectedDate).set({
        hour: moment(offPeriod.offPeriodStart, "HH:mm").hour(),
        minute: moment(offPeriod.offPeriodStart, "HH:mm").minute(),
        seconds: 0,
        milliseconds: 0,
      });

      const offPeriodEnd = moment(selectedDate).set({
        hour: moment(offPeriod.offPeriodEnd, "HH:mm").hour(),
        minute: moment(offPeriod.offPeriodEnd, "HH:mm").minute(),
        seconds: 0,
        milliseconds: 0,
      });
      //  null, "[]" for inclusive checking
      if (
        startDate.isBetween(offPeriodStart, offPeriodEnd, null, "[]") &&
        endDate.isBetween(offPeriodStart, offPeriodEnd, null, "[]")
      ) {
        isWithinOffPeriod = true;
        break;
      }
    }
    return isWithinOffPeriod;
  } catch (error) {
    return false;
  }
};

export const groupAgendaResponse = (selectedViewType, appointments) => {
  try {
    let readyAppointments = [];
    const groupedDatesThatMatch = groupBy(appointments, function (appointment) {
      return moment(appointment.appointmentDate).format("YYYY-MM-DD");
    });

    const arrayOfDates = Object.keys(groupedDatesThatMatch);
    for (let index = 0; index < arrayOfDates.length; index++) {
      const date = arrayOfDates[index];

      if (
        groupedDatesThatMatch[date].length > 2 &&
        selectedViewType === VIEW_TYPES_VALUES.MONTHLY // Max 2 Items
      ) {
        groupedDatesThatMatch[date] = groupedDatesThatMatch[date]
          .sort((a, b) => {
            if (moment(a.appointmentDate).isBefore(moment(b.appointmentDate))) {
              return -1;
            }
            if (moment(a.appointmentDate).isAfter(moment(b.appointmentDate))) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })
          .sort((a, b) => {
            if (a.status === "CLOSED") {
              return -1;
            }
            if (b.status === "CLOSED") {
              return 1;
            }
            // a must be equal to b
            return 0;
          });

        readyAppointments = [
          ...readyAppointments,
          groupedDatesThatMatch[date][0],
          groupedDatesThatMatch[date][1],
          {
            ...groupedDatesThatMatch[date][2],
            otherDayAppointments: groupedDatesThatMatch[date].slice(2),
            type: "tooltip",
          },
        ];
      } else {
        readyAppointments = [
          ...readyAppointments,
          ...groupedDatesThatMatch[date],
        ];
      }
    }

    return readyAppointments;
  } catch (e) {
    logError({
      methodName: "getAppointments",
      file: "containers/Calendar/Container.jsx",
      error: e,
    });
    return appointments;
  }
};

export const convertWorkingHoursToUTC = (data, payload) => {
  try {
    const { overAllEndWorkingHour, overAllStartWorkingHour } = data;

    const startTime = moment.tz(
      payload.startDate +
        " " +
        `${overAllStartWorkingHour.hour}:${overAllStartWorkingHour.minute}`,
      "YYYY-MM-DD HH:mm",
      momentTimezone.tz.guess()
    );
    const utcStartTime = startTime.utc();

    const endTime = moment.tz(
      payload.startDate +
        " " +
        `${overAllEndWorkingHour.hour}:${overAllEndWorkingHour.minute}`,
      "YYYY-MM-DD HH:mm",
      momentTimezone.tz.guess()
    );
    const utcEndTime = endTime.utc();

    return {
      ...data,
      overAllEndWorkingHour: {
        hour: utcEndTime.get("hour"),
        minute: utcEndTime.get("minute"),
      },
      overAllStartWorkingHour: {
        hour: utcStartTime.get("hour"),
        minute: utcStartTime.get("minute"),
      },
    };
  } catch (error) {
    return data;
  }
};

export const convertWorkingHoursFromUTC = (data, payload) => {
  try {
    const { overAllEndWorkingHour, overAllStartWorkingHour, members } = data;
    const startDate = moment(payload.startDate)
      .locale("en")
      .format("YYYY-MM-DD");

    const startTimeUTC = moment.utc(
      startDate +
        " " +
        `${overAllStartWorkingHour?.hour}:${overAllStartWorkingHour?.minute}`,
      "YYYY-MM-DD HH:mm"
    );
    const startTimeTimezoned = startTimeUTC
      .clone()
      .tz(momentTimezone.tz.guess());

    const endTimeUTC = moment.utc(
      startDate +
        " " +
        `${overAllEndWorkingHour?.hour}:${overAllEndWorkingHour?.minute}`,
      "YYYY-MM-DD HH:mm"
    );

    const endTimeTimezoned = endTimeUTC.clone().tz(momentTimezone.tz.guess());

    const memberFromUTc = members?.map((member) => {
      const {
        calenderEndWorkingHour,
        calenderStartWorkingHour,
        days,
        ...restOfMemberProps
      } = member;

      const calenderStartWorkingHourUTC = moment.utc(
        startDate +
          " " +
          `${calenderStartWorkingHour?.hour}:${calenderStartWorkingHour?.minute}`,
        "YYYY-MM-DD HH:mm"
      );
      const calenderStartWorkingHourTimeZoned = calenderStartWorkingHourUTC
        .clone()
        .tz(momentTimezone.tz.guess());

      const calenderEndWorkingHourUTC = moment.utc(
        startDate +
          " " +
          `${calenderEndWorkingHour?.hour}:${calenderEndWorkingHour?.minute}`,
        "YYYY-MM-DD HH:mm"
      );
      const calenderEndWorkingHourTimeZoned = calenderEndWorkingHourUTC
        .clone()
        .tz(momentTimezone.tz.guess());

      const daysUTC = days?.map((day) => {
        const { shifts, ...restOfDay } = day;
        const shiftsModifiedUTC = shifts?.map((shift) => {
          const { startTime, endTime, ...restOfShift } = shift;

          const dayStartTimeUTC = moment.utc(
            startDate + " " + `${startTime?.hour}:${startTime?.minute}`,
            "YYYY-MM-DD HH:mm"
          );

          const dayStartTimeTimezoned = dayStartTimeUTC
            .clone()
            .tz(momentTimezone.tz.guess());

          const dayEndTimeUTC = moment.utc(
            startDate + " " + `${endTime?.hour}:${endTime?.minute}`,
            "YYYY-MM-DD HH:mm"
          );
          const dayEndTimeTimezoned = dayEndTimeUTC
            .clone()
            .tz(momentTimezone.tz.guess());

          return {
            ...restOfShift,
            startTime: {
              hour: dayStartTimeTimezoned.get("hours"),
              minute: dayStartTimeTimezoned.get("minutes"),
            },
            endTime: {
              hour: dayEndTimeTimezoned.get("hours"),
              minute: dayEndTimeTimezoned.get("minutes"),
            },
          };
        });
        return { ...restOfDay, shifts: shiftsModifiedUTC };
      });

      return {
        ...restOfMemberProps,
        // calenderStartWorkingHour: {
        //   hour: calenderStartWorkingHourTimeZoned.get("hours"),
        //   minute: calenderStartWorkingHourTimeZoned.get("minutes"),
        // },
        // calenderEndWorkingHour: {
        //   hour: calenderEndWorkingHourTimeZoned.get("hours"),
        //   minute: calenderEndWorkingHourTimeZoned.get("minutes"),
        // },
        // above is handled in container
        calenderEndWorkingHour,
        calenderStartWorkingHour,
        days: daysUTC,
      };
    });

    return {
      members: memberFromUTc,
      overAllEndWorkingHour: {
        hour: endTimeTimezoned.get("hours"),
        minute: endTimeTimezoned.get("minutes"),
      },
      overAllStartWorkingHour: {
        hour: startTimeTimezoned.get("hours"),
        minute: startTimeTimezoned.get("minutes"),
      },
    };
  } catch (error) {
    return data;
  }
};

const convertTimezone = (startingSlots, date) => {
  return startingSlots.map((time) => {
    return momentTimezone
      .utc(date + " " + time, "YYYY-MM-DD HH:mm")
      .tz(momentTimezone.tz.guess())
      .locale("en")
      .format("HH:mm");
  });
};

export const convertAvailabilityFromUTC = (data) => {
  try {
    const newData = Object.fromEntries(
      Object.entries(data).map(([dateKey, dayData]) => {
        return [
          dateKey,
          {
            ...dayData,
            startingSlots: convertTimezone(dayData.startingSlots, dateKey),
          },
        ];
      })
    );

    return newData;
  } catch (error) {
    return data;
  }
};
