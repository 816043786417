import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/Star";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { logError } from "../../../services/LogError";
import {
  MEMBERS_SCOPE_MAPPER,
  MEMBER_SCOPE_ARRAY,
} from "../../../../containers/EditMembers/constants";
import InviteMember from "./StepThree/InviteMember";
import { ROLES } from "../../../../routes/constants";
import BackgroundLetterAvatar from "../../BackgroundLetterAvatar/BackgroundLetterAvatar";
import MoreMenu from "./StepThree/MoreMenu";
import MemberTableSkeleton from "./StepThree/MemberTableSkeleton";
import {
  CLIENT_SUBSCRIPTION_DATA,
  LIST_MEMBERS_REDUCER_NAME,
} from "../../../constants";
import { getUpgradeToEliteSubscription } from "../../../api/clientAPI";

const checkIfHasFullAccess = (scopes) => {
  if (scopes.length !== MEMBER_SCOPE_ARRAY.length) {
    return false;
  }

  return MEMBER_SCOPE_ARRAY.every((memberScope) => {
    return scopes.map((scope) => scope.value).includes(memberScope);
  });
};

function WizardSalonMembers() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const queryClient = useQueryClient();

  const members = queryClient.getQueryData([LIST_MEMBERS_REDUCER_NAME]);
  const subscriptionData = queryClient.getQueryData([CLIENT_SUBSCRIPTION_DATA]);

  const { maxPlanMembers } = subscriptionData || {};

  const handleUpgradeToElite = async () => {
    try {
      const data = await getUpgradeToEliteSubscription();
      const { sessionId, url } = data || {};

      window.location.assign(url);
    } catch (error) {
      logError({
        methmodName: "getUpgradeToEliteSubscription",
        file: "containers/WizardSalonMembers/handleUpdateDowngradeSubscription.jsx",
        error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
      });
    }
  };

  return (
    <Box>
      <Grid container spacing={4} paddingX={matchesSmUp ? 4 : 1} paddingTop={2}>
        <Grid item xs={12}>
          {members.length < maxPlanMembers ? (
            <InviteMember />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="h5" marginBottom={1.5}>
                  {t("expand_salon_team")}
                </Typography>
                <Typography color="text.secondary">
                  {t("expand_salon_team_paragraph")}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  startIcon={<StarIcon />}
                  onClick={handleUpgradeToElite}
                >
                  {t("upgrade_to_elite")}
                </Button>
              </CardActions>
            </Card>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider flexItem />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={700}>
            {t("staff_accounts")}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("wizard_staff_name")}</TableCell>
                  <TableCell>{t("email")}</TableCell>
                  <TableCell>{t("role")}</TableCell>
                  <TableCell>{t("permissions")}</TableCell>
                  <TableCell padding="checkbox">{t("is_active")}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members?.map((member) => {
                  const {
                    displayName,
                    imageUrl,
                    email,
                    scopes,
                    userId,
                    roles,
                    inviteStatus,
                  } = member || {};
                  const isOwner = roles.includes(ROLES.OWNER);

                  return (
                    <TableRow
                      key={userId}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          paddingRight={2}
                        >
                          {!!imageUrl && imageUrl !== "" ? (
                            <Avatar alt="imageUrl" src={imageUrl} />
                          ) : (
                            <BackgroundLetterAvatar name={displayName} />
                          )}
                          <Box pl={2}>{displayName}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>
                        <Chip
                          label={isOwner ? t("owner") : t("member")}
                          variant="outlined"
                          color={"primary"}
                          sx={{ fontWeight: "600" }}
                        />
                      </TableCell>

                      <TableCell>
                        {checkIfHasFullAccess(scopes)
                          ? t("full_access")
                          : scopes
                              ?.filter((s, index) => index < 7)
                              ?.map((scope) =>
                                t(MEMBERS_SCOPE_MAPPER[scope.value])
                              )
                              ?.join(", ")}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={
                            inviteStatus === "PENDING"
                              ? t("pending")
                              : t("active")
                          }
                          color={
                            inviteStatus === "PENDING" ? "warning" : "success"
                          }
                        />
                      </TableCell>
                      <TableCell align="right">
                        <MoreMenu {...member} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WizardSalonMembers;
