export const WHATS_APP_PROMOTIONS_REDUCER_NAME = "WHATSAPP_PROMOTIONS";
export const WHATS_APP_SESSION_REDUCER_NAME = "WHATSAPP_SESSION";
export const WHATS_APP_QR_SCAN_REDUCER_NAME = "WHATSAPP_QR_SCAN";
export const WHATS_APP_CAMPAIGN_STATISTICS_REDUCER_NAME =
  "WHATS_APP_CAMPAIGN_STATISTICS";

export const WHATS_APP_SESSION_STATUSES = {
  SCAN_QR_CODE: "SCAN_QR_CODE",
  WORKING: "WORKING",
  FAILED: "FAILED",
  STOPPED: "STOPPED",
  STARTING: "STARTING",
};
