import { io } from "socket.io-client";

const URL = process.env.REACT_APP_API_URL;

export const socket = io(URL, {
  //   autoConnect: true,
  cors: {
    origin: process.env.REACT_APP_API_URL,
  },
});
