import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StarIcon from "@mui/icons-material/Star";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import TodayIcon from "@mui/icons-material/Today";
import PaymentIcon from "@mui/icons-material/Payment";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Notification from "./components/Notification";
import UpcomingAppointments from "./components/UpcomingAppointments";
import CompletedNotification from "./components/CompletedAppointments";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const UP_COMING_APPOINTMENTS_REDUCER_NAME = "UP_COMING_APPOINTMENTS";
export const COMPLETED_APPOINTMENTS_REDUCER_NAME =
  "COMPLETED_APPOINTMENTS_REDUCER_NAME";
export const NOTIFICATION = "NOTIFICATION";

export const TABS_VALUES = {
  NOTIFICATION: "notification",
  PENDING_APPOINTMENTS: "pending_appointments",
  COMPLETED_APPOINTMENTS: "completed_appointments",
};

export const TABS_COMPONENTS = {
  [TABS_VALUES.NOTIFICATION]: Notification,
  [TABS_VALUES.PENDING_APPOINTMENTS]: UpcomingAppointments,
  [TABS_VALUES.COMPLETED_APPOINTMENTS]: CompletedNotification,
};

export const NOTIFICATION_ACTIONS = {
  REMINDER_APPOINTMENTS_UNCOMPETED: "REMINDER_APPOINTMENTS_UNCOMPETED",
  REMINDER_APPOINTMENTS_PENDING: "REMINDER_APPOINTMENTS_PENDING",
  REMINDER_APPOINTMENTS_TODAY: "REMINDER_APPOINTMENTS_TODAY",
  RATING: "RATING",
  INVOICE: "",
  CLIENT_ONLINE_PAYMENT_FOR_APPOINTMENT:
    "CLIENT_ONLINE_PAYMENT_FOR_APPOINTMENT",
  CLIENT_ONLINE_PAYMENT_FOR_TRANSACTION:
    "CLIENT_ONLINE_PAYMENT_FOR_TRANSACTION",
  // from here
  CLIENT_USER_ONLINE_BOOKING: "CLIENT_USER_ONLINE_BOOKING",
  CLIENT_USER_DELETE_ONLINE_BOOKING: "CLIENT_USER_DELETE_ONLINE_BOOKING",
  CLIENT_USER_UPDATE_ONLINE_BOOKING: "CLIENT_USER_UPDATE_ONLINE_BOOKING",
  CLIENT_USER_APPROVE_RESCHEDULE_ONLINE_BOOKING:
    "CLIENT_USER_APPROVE_RESCHEDULE_ONLINE_BOOKING",
  CLIENT_USER_REJECT_RESCHEDULE_ONLINE_BOOKING:
    "CLIENT_USER_REJECT_RESCHEDULE_ONLINE_BOOKING",
  CLIENT_WHATSAPP_APPOINTMENT_REMINDER: "CLIENT_WHATSAPP_APPOINTMENT_REMINDER",
};

export const NOTIFICATION_TYPES = {
  REMINDER_APPOINTMENTS_UNCOMPETED: {
    icon: NotificationsPausedIcon,
    color: "#D9534F",
  },
  REMINDER_APPOINTMENTS_PENDING: {
    icon: HourglassBottomIcon,
    color: "#F0AD4E",
  },
  REMINDER_APPOINTMENTS_TODAY: { icon: TodayIcon, color: "#0288d1" },
  INVOICE: { icon: AttachMoneyIcon, color: "#0000FF" },
  RATING: { icon: StarIcon, color: "#FFCE31" },
  CLIENT_ONLINE_PAYMENT_FOR_APPOINTMENT: {
    icon: PaymentIcon,
    color: "#5CB85C",
  },
  CLIENT_ONLINE_PAYMENT_FOR_TRANSACTION: {
    icon: MonetizationOnIcon,
    color: "#5CB85C",
  },
  CLIENT_USER_ONLINE_BOOKING: {
    icon: EventIcon,
    color: "#6E4B87",
  },
  CLIENT_USER_DELETE_ONLINE_BOOKING: {
    icon: EventBusyIcon,
    color: "#D9534F",
  },
  CLIENT_USER_UPDATE_ONLINE_BOOKING: {
    icon: EditCalendarIcon,
    color: "#F0AD4E",
  },
  CLIENT_USER_APPROVE_RESCHEDULE_ONLINE_BOOKING: {
    icon: EventAvailableIcon,
    color: "#5CB85C",
  },
  CLIENT_USER_REJECT_RESCHEDULE_ONLINE_BOOKING: {
    icon: EventBusyIcon,
    color: "#D9534F", //"#0000FF", //#6E4B87 ,//#0288d1
  },
  CLIENT_WHATSAPP_APPOINTMENT_REMINDER: {
    icon: WhatsAppIcon,
    color: "#5CB85C",
  },
};

export const LIST_API_LIMIT = 10;
