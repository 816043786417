import React from "react";
import { SnackbarProvider as MuiSnackbarProvider } from "material-ui-snackbar-provider";
import SnackbarComponent from "./Snackbar";

const SnackbarDefaultProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

const SnackbarProvider = (props) => {
  const { SnackbarProps: passedSnackbarProps, ...rest } = props;
  const SnackbarProps = { ...passedSnackbarProps, ...SnackbarDefaultProps };

  return React.createElement(MuiSnackbarProvider, {
    SnackbarComponent,
    SnackbarProps,
    ...rest,
  });
};

SnackbarProvider.propTypes = {
  ...MuiSnackbarProvider.propTypes,
};

SnackbarProvider.defaultProps = {
  SnackbarProps: SnackbarDefaultProps,
};

export default SnackbarProvider;
