import React, { useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { useFormContext, Controller } from "react-hook-form";

import { ReactComponent as GalleryAdd } from "../../../../../assets/svg/gallery-add.svg";
import { ReactComponent as UserCircleAdd } from "../../../../../assets/svg/user-cirlce-add.svg";

const styles = {
  wrapperStyle: {
    backgroundColor: "#6E4B8733",
    height: 200,
    position: "relative",
    marginBottom: "50px",
    objectFit: "contain",
    width: "100%",
  },
  profileImageWrapperStyle: {
    position: "absolute",
    left: "10%",
    bottom: "-50px",
    width: 100,
    height: 100,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e1dbe6",
    border: "3px solid #FDFAFF",
  },
  profileImageErrorWrapperStyle: {
    position: "absolute",
    left: "10%",
    bottom: "-50px",
    width: 100,
    height: 100,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e1dbe6",
    border: "1px solid #C3281C",
  },
  hidden: {
    display: "none",
  },
  profileImageStyle: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: "#e1dbe6",
    border: "3px solid #FDFAFF",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidthWithError: {
    width: "100%",
    height: "100%",
    border: "1px solid #C3281C",
    borderRadius: 0,
  },
};

function checkIfUrlContansHttpOrHttps(str) {
  return /\b(http|https)/.test(str);
}

function ProfileUploadSection(props) {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext();

  const [profileImage, setProfileImage] = useState(
    defaultValues.profileImage ?? null
  );
  const [coverImage, setCoverImage] = useState(
    defaultValues.coverImage ?? null
  );

  const coverInputRef = useRef();
  const profileInputRef = useRef();

  const {
    wrapperStyle,
    profileImageWrapperStyle,
    profileImageErrorWrapperStyle,
    hidden,
    profileImageStyle,
    fullWidth,
    fullWidthWithError,
  } = styles;

  const handleCoverImageClicked = () => {
    coverInputRef.current.click();
  };
  const handleProfileImageClicked = () => {
    profileInputRef.current.click();
  };

  return (
    <Stack justifyContent={"center"} alignItems={"center"} sx={wrapperStyle}>
      <Controller
        name="coverImage"
        control={control}
        defaultValue={0}
        render={({ field: { value, onChange, ...field }, fieldState }) => {
          return (
            <IconButton
              onClick={handleCoverImageClicked}
              disableRipple
              sx={!!fieldState?.error ? fullWidthWithError : fullWidth}
            >
              {!!coverImage ? (
                <img
                  style={wrapperStyle}
                  src={
                    !!coverImage && checkIfUrlContansHttpOrHttps(coverImage)
                      ? coverImage
                      : URL.createObjectURL(coverImage)
                  }
                  alt="cover"
                />
              ) : (
                <GalleryAdd />
              )}
              <input
                {...field}
                value={value?.fileName}
                style={hidden}
                onChange={(event) => {
                  onChange(event.target.files[0]);
                  setCoverImage(event.target.files[0]);
                }}
                ref={coverInputRef}
                type="file"
                accept="image/*,video/*"
                id="picture"
              />
            </IconButton>
          );
        }}
      />
      <Controller
        name="profileImage"
        control={control}
        defaultValue={0}
        render={({ field: { value, onChange, ...field }, fieldState }) => {
          return (
            <IconButton
              sx={
                !!fieldState?.error
                  ? profileImageErrorWrapperStyle
                  : profileImageWrapperStyle
              }
              disableRipple
              onClick={handleProfileImageClicked}
            >
              {!!profileImage ? (
                <img
                  style={profileImageStyle}
                  src={
                    !!profileImage && checkIfUrlContansHttpOrHttps(profileImage)
                      ? profileImage
                      : URL.createObjectURL(profileImage)
                  }
                  alt="profile"
                />
              ) : (
                <UserCircleAdd />
              )}
              <input
                {...field}
                value={value?.fileName}
                style={hidden}
                onChange={(event) => {
                  onChange(event.target.files[0]);
                  setProfileImage(event.target.files[0]);
                }}
                ref={profileInputRef}
                type="file"
                accept="image/*,video/*"
                id="profilePicture"
              />
            </IconButton>
          );
        }}
      />
    </Stack>
  );
}

export default ProfileUploadSection;
