import { AbilityContext } from "./Can";
import defineAbilityFor from "./Permissions";
import { useAuthContext } from "../Auth/useAuthContext";

export default function AccessProvider(props) {
  const { user } = useAuthContext();

  return (
    <AbilityContext.Provider value={defineAbilityFor(user)}>
      {props.children}
    </AbilityContext.Provider>
  );
}
