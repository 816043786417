export default {
  green: {
    light: "#29da90",
    main: "#5CB85C",
    dark: "#0e985e",
  },
  denim: {
    main: "#0E7696",
  },
  orange: {
    light: "#ffb74d",
    main: "#FFA722",
    dark: "#f57c00",
  },
  grey: {
    light: "#F7F7F7",
    main: "#F5F5F5",
    dark: "#DDE2E5",
    alabaster: "#F9F9F9",
    nevada: "#6D7278",
    silverSand: "#C6CACC",
    fossile:"#687373",
    anchor:"#858585",
  },
  blueGrey: {
    light: "#E9EFF2",
    main: "#ACB5BD",
    dark: "#ACB5BD",
    zumthor: "#ECF6FF",
    catskillWhite: "#F3F6F9",
  },
  blue: {
    light: "#5e9aad",
    main: "#0E7696",
    dark: "#084c61",
    pictonBlue: "#3C92E1",
    cornflower: "#F6F6F6",
    cloudBurst: "#213A54",
    aquaHaze: "#EDF2F5",
  },
  purple: {
    portGore: "#1B1D4D",
  },
  red: {
    light: "#e57373",
    main: "#D50001",
    dark: "#d32f2f",
    burntSienna: "#EB5757",
  },
  white: {
    main: "#FFF",
    catskillWhite: "#E9EFF4",
    whiteLilac: "#F7F7FC",
  },
  black: {
    light: "#2C3238",
    main: "#212429",
    dark: "#212429",
    solid: "#000000",
  },
  yellow: {
    light: "#E3B709",
    main: "#E3B709",
    dark: "#E3B709",
  },
  cranberry: {
    main: "#DF5E82",
  },
  pickledBluewood: {
    main: "#2C3551",
  },
  lavenderBlush: {
    main: "#FFE9ED",
  },
  hotPink: {
    main: "#FF5DA1",
  },
  medium: {
    dark: "#495057",
  },
  metal: {
    dark: "#495057",
  },
  silver: {
    main: "#BDBDBD",
  },
  blueViolet: {
    main: "#454AB8",
    dark: "#373BA4",
  },
};
