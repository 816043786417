import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";

import { intersectionScopes } from "../../routes/utils";
import { useAuthContext } from "../Auth/useAuthContext";
import { Typography } from "@mui/material";

const StyledButton = styled(Button)(({ tabValue, theme }) => {
  return {
    fontSize: "16px",
    "@media (min-width: 1200px) and (max-width: 1350px)": {
      fontSize: "14px",
    },
    color: !!tabValue ? theme.palette.primary.main : "inherit",
    fontWeight: !!tabValue ? "700" : "400",
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "transparent",
    },
  };
});

const StyledMenuItem = styled(MenuItem)(({ value, theme }) => {
  return {
    backgroundColor: value ? "rgb(110,75,135, 0.2)" : "inherit",
  };
});

const styles = {
  iconStyle: { width: 25, height: 25 },
  accordionSummary: {
    padding: 0,
    ".MuiAccordionSummary-content": { alignItems: "center" },
  },
  accordionDetails: { padding: 0 },
  popper: { zIndex: 999 },
  listItemTextStyle: { color: "rgb(110, 75, 135)" },
  menuListStyle: { padding: 0 },
};
function MenuTab(props) {
  const { label, menuItems, icon, handleDrawerClose = () => {} } = props;

  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(false);
  const anchorRef = useRef(null);

  const { user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLinkClicked = (event, value) => {
    !!isLargeScreen ? handleClose(event) : handleDrawerClose();
    navigate(value);
  };

  useEffect(() => {
    const menuTabValue = menuItems.find(
      (link) => location.pathname === link.value
    )?.value;

    setTabValue(menuTabValue);
  }, [location]);

  return (
    <Box display={"flex"}>
      {isLargeScreen ? (
        <>
          <StyledButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            endIcon={<KeyboardArrowDownIcon />}
            startIcon={icon}
            color="inherit"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
            tabValue={tabValue}
          >
            {label}
          </StyledButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={styles.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {menuItems
                        ?.filter((link) =>
                          intersectionScopes(link.scopes, user.scopes)
                        )
                        ?.map((item) => {
                          const { title, value, icon } = item;
                          const Icon = icon;
                          return (
                            <MenuItem
                              key={title}
                              onClick={(event) =>
                                handleLinkClicked(event, value)
                              }
                            >
                              <ListItemIcon>
                                <Icon style={styles.iconStyle} />
                              </ListItemIcon>
                              <ListItemText>{title}</ListItemText>
                            </MenuItem>
                          );
                        })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      ) : (
        <Box width={"100%"}>
          <Accordion elevation={0} disableGutters defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={styles.accordionSummary}
            >
              <Box paddingInlineEnd={1}>{icon}</Box>
              <Typography color={"primary"}>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={styles.accordionDetails}>
              <MenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={handleListKeyDown}
                sx={styles.menuListStyle}
              >
                {menuItems
                  ?.filter((link) =>
                    intersectionScopes(link.scopes, user.scopes)
                  )
                  ?.map((item) => {
                    const { title, value, icon } = item;
                    const Icon = icon;
                    return (
                      <StyledMenuItem
                        key={title}
                        value={tabValue === value}
                        onClick={(event) => handleLinkClicked(event, value)}
                      >
                        <ListItemIcon>
                          <Icon style={styles.iconStyle} />
                        </ListItemIcon>
                        <ListItemText sx={styles.listItemTextStyle}>
                          {title}
                        </ListItemText>
                      </StyledMenuItem>
                    );
                  })}
              </MenuList>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Box>
  );
}

export default MenuTab;
