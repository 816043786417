import React, { useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Storage } from "aws-amplify";
import uuid from "react-uuid";

import { ReactComponent as GalleryAdd } from "../../../../assets/svg/gallery-add.svg";
import {
  GALLERY_REDUCER_NAME,
  VIDEO,
  IMAGE,
} from "../../../../containers/EditSalon/constants";
import {
  deleteGalleryPost,
  getGalleryPaginated,
  uploadGalleryPost,
} from "../../../../containers/EditSalon/api";
import { logError } from "../../../services/LogError";
import useCustomSnackbar from "../../Snackbar/useSnackbar";
import { CLIENT_DATA_REDUCER_NAME } from "../../../constants";
import { SetS3Config } from "../../../services/awsService";
import FileUploaderModal from "../../../../containers/EditSalon/components/FileUploaderModal";

const styles = {
  wrapperStyle: {
    backgroundColor: "#6E4B8733",
    height: 180,
    position: "relative",
    marginBottom: "50px",
    objectFit: "contain",
    width: "100%",
  },
  hidden: {
    display: "none",
  },
  fullWidth: {
    width: "100%",
  },
  uploaderWrapperStyle: {
    borderRadius: "8px",
    border: "3px dashed #6E4B87",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageStyle: { objectFit: "contain" },
  imageListStyle: { overflow: "hidden" },
  imageListContainerStyle: { width: "100%", overflow: "hidden" },
  deleteImageIconStyle: {
    position: "absolute",
    top: 5,
    right: 5,
    backgroundColor: "#EFEFEF",
    ":hover": {
      backgroundColor: "#EFEFEF",
    },
  },
};

function WizardGallery() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const snackbar = useCustomSnackbar();

  const coverInputRef = useRef();
  const [coverImage, setCoverImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);

  // settings
  const bucketSetting = queryClient.getQueryData([
    CLIENT_DATA_REDUCER_NAME,
  ]).settings;
  const { imagesGalleryBucketName, videosGalleryBucketName, bucketName } =
    bucketSetting;

  const { isFetching, data: gallery = { content: [], totalElements: 0 } } =
    useQuery(
      [GALLERY_REDUCER_NAME],
      async () => {
        try {
          const data = await getGalleryPaginated({
            page: 1,
            limit: 8,
          });
          return data;
        } catch (error) {
          logError({
            methmodName: "getGalleryPaginated",
            file: "containers/Wizard/component/WizardGallery.jsx",
            error: ` path ${error?.response?.data?.path} with error ${error?.response?.data?.message}`,
          });
        }
      },
      {
        enabled: true,
      }
    );

  const handleCoverImageClicked = () => {
    coverInputRef.current.click();
  };
  const handleUploadImage = (event) => {
    const file = event?.target?.files[0];
    const { name, type } = file;
    const fileType = type.split("/")[0];

    setIsProgressModalOpen(true);

    SetS3Config(bucketName?.replace("/", ""), "public");

    const s3ImagesPromises = [uploadS3(file, name, type)];
    // const sourceMedia = !id ? mediaFile && mediaFile.path : mediaFile && mediaFile.url;
    // const s3ImagesPromises =
    //   !id && type === GALLERY
    //     ? mediaFiles.map(({ path, filename, mime }) => {
    //         return uploadS3(path, filename, mime);
    //       })
    //     : [uploadS3(mediaFile.path, mediaFile.filename, mediaFile.mime)];
    Promise.all(s3ImagesPromises)
      .then((result) => {
        const s3MediaFiles = result.map((file) => {
          return {
            id: file.key,
            type: fileType === "video" ? VIDEO : IMAGE,
          };
        });
        let postGalleryData = {
          caption: "", //values.caption,
          mediaFiles: s3MediaFiles,
          type: fileType === "video" ? VIDEO : IMAGE,
        };
        return uploadGalleryPost(postGalleryData);
      })
      .then((data) => {
        // dispatch redux add
        let modifiedData = data;
        if (type === VIDEO) {
          modifiedData.mediaFile.thumbnail =
            "https://via.placeholder.com/150/000000/000000?text=error";
        } else {
          modifiedData.mediaFile.thumbnail = data.mediaFile.url;
        }
      })
      .catch((err) => {
        snackbar.showError(
          t("failed_to_upload_to_gallery_paraghraph"),
          t("failed_to_upload_to_gallery")
        );
      })
      .finally(() => {
        queryClient.invalidateQueries([GALLERY_REDUCER_NAME]);
        setIsProgressModalOpen(false);
      });
  };
  const uploadS3 = (file, fileName, fileType) => {
    try {
      let UUID_filename = fileName;
      UUID_filename = `${uuid()}.${fileName.split(".").pop()}`;

      let galleryBucketName =
        fileType.split("/")[0] === "video"
          ? videosGalleryBucketName
          : imagesGalleryBucketName;
      const customPrefix = {
        public: `${galleryBucketName}/`,
        protected: `${galleryBucketName}/`,
        private: `${galleryBucketName}/`,
      };
      let totalLoaded = 0,
        isPrevLoaded = false;

      return Storage.put(UUID_filename, file, {
        acl: "public-read",
        ACL: "public-read",
        contentType: fileType,
        customPrefix,
        level: "public",
        progressCallback: ({ loaded, total }) => {
          setProgressValue((loaded / total) * 100);
          // if (type === GALLERY) {
          //   return;
          // }
          // if (total === totalLoaded) {
          //   isPrevLoaded = true;
          // }
          // totalLoaded = isPrevLoaded ? total + loaded : loaded;
          // setProgress(
          //   `${(roundNumber(totalLoaded / (total * 2), 10) * 100).toFixed(0)}%`
          // );
        },
      });
    } catch (error) {
      logError({
        methodName: "uploadS3",
        file: "common/Wizard/WizardGallery.jsx",
        error,
        params: { fileName, fileType },
      });
    }
  };

  const handleCloseLoading = () => {
    setIsProgressModalOpen(false);
  };

  const handleConfirmDeleteImage = async (item) => {
    try {
      setIsLoading(true);

      const { aws_id, id } = item;

      await Storage.remove(aws_id);
      await deleteGalleryPost(id);
      queryClient.invalidateQueries([GALLERY_REDUCER_NAME]);
    } catch (error) {
      logError({
        methodName: "handleConfirmDeleteImage",
        file: "/common/wizard/WizardGallery.jsx",
        error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    wrapperStyle,
    hidden,
    fullWidth,
    uploaderWrapperStyle,
    imageStyle,
    imageListStyle,
    imageListContainerStyle,
    deleteImageIconStyle,
  } = styles;

  const { totalElements = 0 } = gallery;
  const content =
    gallery?.content?.map((item) => ({
      url: item?.mediaFile?.url,
      id: item.id,
      aws_id: item?.mediaFile?.id,
      type: item?.mediaFile?.type,
      thumbnail: item?.mediaFile?.thumbnail,
    })) ?? [];

  return (
    <Grid container spacing={4} paddingX={matchesSmUp ? 4 : 1} paddingTop={2}>
      <Grid item xs={12}>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={"#6E4B8733"}
          height={250}
        >
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={uploaderWrapperStyle}
            width={"95%"}
            height={"90%"}
            onClick={handleCoverImageClicked}
          >
            <IconButton disableRipple sx={fullWidth}>
              <GalleryAdd />
              <input
                style={hidden}
                onChange={handleUploadImage}
                ref={coverInputRef}
                type="file"
                accept="image/*,video/*"
                id="picture"
              />
            </IconButton>
            <Typography>{t("salon_gallery_paragraph")}</Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <ImageList sx={imageListContainerStyle} cols={4} rowHeight={200}>
          {content?.map((item, index) => {
            const { id, url, type } = item;
            return (
              <ImageListItem key={id} sx={imageListStyle}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleConfirmDeleteImage(item)}
                  sx={deleteImageIconStyle}
                >
                  <DeleteIcon color="error" />
                </IconButton>
                {type === VIDEO ? (
                  <video src={url} width="100%" height="200" controls></video>
                ) : (
                  <img
                    src={`${url}`}
                    srcSet={`${url}`}
                    alt={"item.title"}
                    loading="lazy"
                    style={imageStyle}
                  />
                )}
              </ImageListItem>
            );
          })}
        </ImageList>
      </Grid>
      <Grid item xs={12}>
        {!!isLoading && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <FileUploaderModal
          isOpen={isProgressModalOpen}
          handleClose={handleCloseLoading}
          progressValue={progressValue}
        />
      </Grid>
    </Grid>
  );
}

export default WizardGallery;
