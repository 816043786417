import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery } from "@mui/material";

import Service from "../Service/Service";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  borderWidth: 0.5,
  borderColor: "#C1C1C1",
  borderStyle: "solid",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "25ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const styles = {
  titleTextStyle: { fontSize: 18, color: "#687373", fontWeight: "700" },
  subtitleTextStyle: { fontSize: 14, color: "#858585", fontWeight: "400" },
  displayNoneStyle: { display: "none" },
  servicesWrapperStyle: {
    overflow: "scroll",
    maxHeight: "45vh",
    // height: window.innerHeight * 0.4,
  },
  noResultTextStyle: {
    fontSize: 18,
    color: "#687373",
    fontWeight: "700",
    marginTop: 2,
  },
  selectedServicesWrapperStyle: { marginTop: "10px", marginBottom: "10px" },
  selectServicesTextStyle: {
    fontWeight: "400",
    color: "#767676",
  },
  selectedServicesNumberTextStyle: {
    fontWeight: "700",
    fontSize: 16,
    color: "#362543",
  },
  closeIconBtnStyle: { position: "absolute", right: 0 },
  categoryServicesSelectedTextStyle: {
    marginTop: "10px",
    marginBottom: "10px",
  },
};

function ServicesSelectorForm(props) {
  const {
    services = [],
    clientServices = [],
    onFinishCb = () => {},
    submitRef,
    returnFullData = false,
  } = props;

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [fullServicesSearchTerm, setFullServicesSearchTerm] = useState("");

  let formattedClientServices = {};
  for (let index = 0; index < clientServices.length; index++) {
    const service = clientServices[index];
    for (let j = 0; j < service.subServices?.length; j++) {
      const subService = service.subServices[j];
      const formattedSuberviceId = subService?.id?.replaceAll(".", "_");
      formattedClientServices[formattedSuberviceId] = true;
    }
  }

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: formattedClientServices,
  });
  const { t } = useTranslation();

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  let watchAllFieldsFiltered = Object.fromEntries(
    Object.entries(watchAllFields).filter(([_, v]) => !!v)
  );

  const onSubmit = (data) => {
    let dataFiltered = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => !!v)
    );
    const dataKeys = [];
    let dataToSent = Object.keys(dataFiltered).map((service) => {
      dataKeys.push(service?.replaceAll("_", "."));
      return { subServiceId: service?.replaceAll("_", ".") };
    });
    const fullDataToSend = [];
    services.map((parentService) => {
      parentService?.subServices?.map((ser) => {
        if (dataKeys.includes(ser.id)) {
          fullDataToSend.push(ser);
        }
      });
    });

    let dataWithOriginalPrices = [];
    let originalService = undefined;
    const sentData = returnFullData ? fullDataToSend : dataToSent;

    sentData?.forEach((service) => {
      originalService = service;
      clientServices?.forEach((clientService) => {
        clientService?.subServices.forEach((subService) => {
          if (subService.id === service?.id) {
            originalService = subService;
          }
        });
      });

      dataWithOriginalPrices.push(originalService);
    });
    onFinishCb(dataWithOriginalPrices);
  };

  const handleSelectAllServiceChildren = (e, subServices) => {
    const newVal = e.target.checked;
    subServices?.map((subService) => {
      const { name: subServiceName, id: subServiceId } = subService;
      const idFormatted = subServiceId?.replaceAll(".", "_");
      setValue(idFormatted, newVal, {
        shouldValidate: true,
        shouldDirty: true,
      });
    });
  };

  const handleFullServicesSearch = (e) => {
    const keyword = e.target.value;
    setFullServicesSearchTerm(keyword);
  };

  const handleClearSearch = () => {
    setFullServicesSearchTerm("");
  };

  const fullServicesResults = [];
  for (let i = 0; i < services?.length; i++) {
    const service = services[i];
    let filteredService = {
      completed: service.completed,
      id: service.id,
      name: service.name,
      subServices: [],
    };
    const serviceSubServicesLength = service?.subServices?.length;
    for (let index = 0; index < serviceSubServicesLength; index++) {
      const subService = service.subServices[index];

      if (
        subService.name
          .toLowerCase()
          .includes(fullServicesSearchTerm.toLowerCase())
      ) {
        filteredService.subServices.push(subService);
      }
    }
    if (filteredService.subServices.length > 0) {
      fullServicesResults.push(filteredService);
    }
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)(e);
  };

  const {
    titleTextStyle,
    subtitleTextStyle,
    displayNoneStyle,
    servicesWrapperStyle,
    noResultTextStyle,
    selectedServicesWrapperStyle,
    selectServicesTextStyle,
    selectedServicesNumberTextStyle,
    closeIconBtnStyle,
    categoryServicesSelectedTextStyle,
  } = styles;

  return (
    <Box>
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        spacing={isSmallScreen ? 0 : 2}
        justifyContent="space-between"
        alignItems={isSmallScreen ? "flex-start" : "center"}
        marginTop={isSmallScreen ? "16px" : "34px"}
        marginBottom={"11px"}
      >
        <Box>
          <Typography variant="h6" component="h2" sx={titleTextStyle}>
            {t("services")}
          </Typography>
          {!isSmallScreen && (
            <Typography variant="h6" component="h2" sx={subtitleTextStyle}>
              {t("services_picker_paragraph")}
            </Typography>
          )}
        </Box>
        <Search onChange={handleFullServicesSearch}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={`${t("search")}...`}
            value={fullServicesSearchTerm}
          />
          {fullServicesSearchTerm?.length > 0 && (
            <IconButton onClick={handleClearSearch} sx={closeIconBtnStyle}>
              <CloseIcon />
            </IconButton>
          )}
        </Search>
      </Stack>

      <Divider />
      <form>
        <Box sx={servicesWrapperStyle}>
          {fullServicesResults && fullServicesResults.length > 0 ? (
            fullServicesResults?.map((service) => {
              const { name, id, subServices } = service;
              const parentIdFormatted = id?.replaceAll(".", "_");

              return (
                <div key={id}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <Typography
                      fontSize={isSmallScreen ? "0.8rem" : "1rem"}
                      sx={categoryServicesSelectedTextStyle}
                    >
                      {`${name} (${
                        Object.keys(watchAllFieldsFiltered).filter((field) =>
                          field.includes(parentIdFormatted)
                        ).length
                      } ${t("selected")})`}
                    </Typography>
                    <Stack direction="row" alignItems={"center"}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                handleSelectAllServiceChildren(e, subServices)
                              }
                            />
                          }
                          label={
                            <Typography
                              fontSize={isSmallScreen ? "0.8rem" : "1rem"}
                              sx={categoryServicesSelectedTextStyle}
                            >
                              {t("select_all")}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Stack>
                  </Stack>

                  <div>
                    {subServices?.map((subService, index) => {
                      const { name: subServiceName, id: subServiceId } =
                        subService;
                      const idFormatted = subServiceId?.replaceAll(".", "_");

                      return (
                        <Service
                          index={index}
                          name={subServiceName}
                          id={idFormatted}
                          enableAddRemove
                          key={subServiceId}
                          control={control}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })
          ) : (
            <Typography variant="h6" component="h2" sx={noResultTextStyle}>
              {t("no_results_found")}
            </Typography>
          )}
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
          sx={selectedServicesWrapperStyle}
        >
          <Typography
            variant="h6"
            component="h2"
            color="primary"
            sx={selectServicesTextStyle}
            fontSize={isSmallScreen ? "0.8rem" : "1rem"}
          >
            {t("selected_services")}
          </Typography>
          <Typography
            variant="h6"
            component="h2"
            color="primary"
            sx={selectedServicesNumberTextStyle}
          >
            {Object.keys(watchAllFieldsFiltered).length}
          </Typography>
        </Stack>
        <button
          ref={submitRef}
          onClick={handleFormSubmission}
          style={displayNoneStyle}
        />
      </form>
    </Box>
  );
}

export default ServicesSelectorForm;
