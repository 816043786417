import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CssBaseline from "@mui/material/CssBaseline";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { Provider as HttpProvider } from "use-http";
import "react-dates/initialize";

import theme from "./assets/theme/default";
import rtlTheme from "./assets/theme/arabicTheme";
import { clientErrorHandler } from "./http/HttpClient";
import SnackbarProvider from "./common/components/Snackbar/SnackbarProvider";
import reportWebVitals from "./reportWebVitals";
import enTranslation from "./translation/en";
import arTranslation from "./translation/ar";
import App from "./App";
import "./index.css";
import "react-dates/lib/css/_datepicker.css";
import LanguageProvider from "./common/Language/LanguageProvider";
import SettingsProvider from "./common/Settings/SettingsProvider";
import { useLanguageContext } from "./common/Language/useLanguageContext";
import * as Sentry from "@sentry/react";

// sentry init
Sentry.init({
  dsn: "https://e57fe54a449ea4119ed1b14834aa9fd7@o469707.ingest.sentry.io/4505656988139520",
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

function RTLChecker(props) {
  const { language } = useLanguageContext();

  return language === "ar" ? (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={rtlTheme}>{props.children}</ThemeProvider>
    </CacheProvider>
  ) : (
    props.children
  );
}

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources: {
    en: {
      translation: enTranslation,
    },
    ar: {
      translation: arTranslation,
    },
  },

  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

const snackbarProps = { autoHideDuration: 4000, maxSnack: 3 };

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
});

root.render(
  <React.StrictMode>
    <HttpProvider
      options={{
        interceptors: {
          response: ({ response }) => {
            if ((response?.data?.errors || []).length > 0) {
              clientErrorHandler({ response });
            }

            return response;
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <LanguageProvider>
          <ThemeProvider theme={theme}>
            <RTLChecker>
              <CssBaseline />
              <SnackbarProvider SnackbarProps={snackbarProps}>
                <SettingsProvider>
                  <App />
                </SettingsProvider>
              </SnackbarProvider>
            </RTLChecker>
          </ThemeProvider>
        </LanguageProvider>
      </QueryClientProvider>
    </HttpProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
