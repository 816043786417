import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import Alert from "@mui/material/Alert";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { APPOINTMENT_STATUSES_VALUES } from "../../Calendar/constants";

function AppointmentActions(props) {
  const {
    onSubmit,
    onIgnore,
    isCompleted,
    isCanceling = false,
    appointmentData,
  } = props;
  const { t } = useTranslation();
  const methods = useFormContext();

  const {
    watch,
    formState: { isValidating, isSubmitting },
  } = methods;

  const status = watch("status");
  const startDate = watch("startDate");
  const from = watch("from");
  const appointmentDate = moment(
    moment(
      `${moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD")} ${moment(
        from,
        "hh:mm A"
      ).format("HH:mm")}`
    )
  );

  const isDisabledInThePastAndComplete = useMemo(() => {
    return !!(
      moment(appointmentDate).isBefore(moment()) &&
      (status === APPOINTMENT_STATUSES_VALUES.PENDING ||
        status === APPOINTMENT_STATUSES_VALUES.REJECTED ||
        status === APPOINTMENT_STATUSES_VALUES.RESCHEDULED ||
        (appointmentData?.totalPriceType === "RANGES" &&
          status !== APPOINTMENT_STATUSES_VALUES.COMPLETED))
    );
  }, [appointmentDate, status, appointmentData?.totalPriceType]);

  return (
    <Box sx={styles.actionsWrapper}>
      {isDisabledInThePastAndComplete && (
        <Box mb={1}>
          <Alert severity="warning" sx={styles.alertStyle}>
            {status !== APPOINTMENT_STATUSES_VALUES.APPROVED
              ? t("uncompleted_appointment_warning_status")
              : t("uncompleted_appointment_warning")}
          </Alert>
        </Box>
      )}
      <Box sx={styles.innerWrapper}>
        <LoadingButton
          loading={isCanceling}
          sx={styles.cancelBtn}
          onClick={onIgnore}
        >
          {isCompleted ? t("didnt_show") : t("reject")}
        </LoadingButton>
        <LoadingButton
          loading={!isCanceling && (isSubmitting || isValidating)}
          sx={styles.saveBtn}
          onClick={onSubmit}
          startIcon={
            isDisabledInThePastAndComplete ? <WarningAmberIcon /> : undefined
          }
          disabled={isDisabledInThePastAndComplete}
        >
          {isCompleted ? t("complete") : t("approve")}
        </LoadingButton>
      </Box>
    </Box>
  );
}

const styles = {
  actionsWrapper: {
    marginTop: "12px",
    gap: "16px",
    padding: "12px 28px",
    position: "absolute",
    bottom: "0",
    width: "545px",
    background: "#fff",
    width: "100%",
  },
  innerWrapper: { display: "flex", gap: "16px" },
  alertStyle: { "&.MuiAlert-standard": { width: "100%" } },
  cancelBtn: {
    width: "100%",
    height: "51px",
    borderRadius: "6px",
    border: "1px solid #D9534F",
    color: "#D9534F",
    minWidth: "50%",
  },
  saveBtn: {
    width: "100%",
    height: "51px",
    background: "#5CB85C",
    borderRadius: "6px",
    color: "#fff",
    "&: hover": {
      background: "#5CB85C",
      opacity: 0.9,
    },

    "&:disabled": {
      backgroundColor: "#DDE2E5",
      color: "#ACB5BD !important",
      border: "none",
    },
    "& .MuiLoadingButton-loadingIndicator": {
      left: "30px",
    },
  },
};

export default AppointmentActions;
